import { RowSelectionState, createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useMemo, useState } from "react";
import BaseTable from "../../components/BaseTable";
import { useCreateAddonMutation, useCreateAddonRouteMutation, useDeleteAddonMutation, useDeleteAddonRouteMutation, useGetAddonsQuery, useLazyGetAddonRoutesQuery, useUpdateAddonMutation, useUpdateAddonRouteMutation } from "../../services/API";
import { Addon, AddonRoute } from "../../services/types";
import { Transition, Dialog } from "@headlessui/react";
import Button from "../../components/Button";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";

export default function AddonPage(){
    const {data: addons, error: addonsError, isLoading: addonsLoading} = useGetAddonsQuery();
    const [loadRoute, {data: routeData, error: routeError, isLoading: routeLoading}] = useLazyGetAddonRoutesQuery();
    const [createRoute, {}] = useCreateAddonRouteMutation();
    const [updateRoute, {}] = useUpdateAddonRouteMutation();
    const [deleteRoute, {}] = useDeleteAddonRouteMutation();
    const [createAddon, {}] = useCreateAddonMutation();
    const [updateAddon, {}] = useUpdateAddonMutation();
    const [deleteAddon, {}] = useDeleteAddonMutation();
	const [selectedRow, setSelectedRow] = useState<number>(-1);
    const [selectedRowRoute, setSelectedRowRoute] = useState<number>(-1);
	const [selectState, setSelectState] = useState<RowSelectionState>({});
    const columnHelper = createColumnHelper<Addon>();
    const customColumns = useMemo(
        ()=>[
            {
				column: columnHelper.accessor("proxy", {
					header: "Use Proxy",
					size: 200,
					cell: (props) => {
						return (
							<div className= "flex items-center">
                                <div className={`py-2 px-4 ${props.getValue() ? "bg-green-500" : "bg-red-500"} text-white rounded-full`}>
                                    {props.getValue() ? "Yes" : "No"}
                                </div>
                            </div>
						);
					},
				}),
				position: 4,
			},
        ],[])
    
    const columnHelperRoute = createColumnHelper<AddonRoute>();
    const customColumnsRoute = useMemo(
        ()=>[
            {
                column: columnHelperRoute.accessor("is_spacer", {
                    header: "Spacer",
                    size: 100,
                    cell: (props) => {
                        return (
                            <div className= "flex items-center">
                                <div className={`py-2 px-4 ${props.getValue() ? "bg-green-500" : "bg-red-500"} text-white rounded-full`}>
                                    {props.getValue() ? "Yes" : "No"}
                                </div>
                            </div>
                        );
                    },
                }),
                position: 3,
            },
        ],[])
    useEffect(()=>{
        if(selectedRow > -1){
            loadRoute(addons![selectedRow].id!);
        }
    },[selectedRow])

    useEffect(()=>{
        if(routeData){
            console.log(routeData);
        }
    },[routeData])

    const [isAddOrEditAddon, setIsAddOrEditAddon] = useState<boolean>(false);
    const cancelAddOrEditAddon = React.useRef(null);
    const [isDeleteingAddon, setIsDeletingAddon] = useState<boolean>(false);
    const cancelDeletingAddon = React.useRef(null);

    const [isAddOrEditRoute, setIsAddOrEditRoute] = useState<boolean>(false);
    const cancelAddOrEditRoute = React.useRef(null);
    const [isDeleteingRoute, setIsDeletingRoute] = useState<boolean>(false);
    const cancelDeletingRoute = React.useRef(null);


    const [actionType, setActionType] = useState<string>("Add");
    const [sequence, setSequence] = useState<number>(0);
    const [name, setName] = useState<string>("");
    const [host, setHost] = useState<string>("");
    const [route, setRoute] = useState<string>("");
    const [isSpacer, setIsSpacer] = useState<boolean>(false);
    const [proxy, setProxy] = useState<boolean>(false);

    return (
        <div className="flex flex-col w-full mx-auto h-full">
                {addonsLoading ? (
                    <div className="items-center justify-center rounded flex h-full">
						<div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 self-center" />
					</div>
                ):(
                    <>
                        <BaseTable
                        data={addons!}
                        columns={["name","host"]}
                        customColumns={customColumns}
						withDetail = {selectedRow > -1}
                        detailWidth="w-1/2"
						onRowSelect={setSelectedRow}
						onRowSelectChange={setSelectState}
						useSearch={true}
                        useActions={true}
                        useEdit={true}
                        useDelete={true}
                        addFunction={()=>{
                            setActionType("Add");
                            setIsAddOrEditAddon(true);
                            setName("");
                            setHost("");
                            setProxy(false);
                        }}
                        editFunction={(addonID)=>{
                            setActionType("Edit");
                            setIsAddOrEditAddon(true);
                            let addon = addons!.find((addon)=>addon.id === addonID);
                            if(addon){
                                setName(addon.name);
                                setHost(addon.host);
                                setProxy(addon.proxy);
                            }
                        }}
                        deleteFunction={(addonID)=>{
                            setIsDeletingAddon(true);
                        }}
                        
                       >
                            {!routeData ? (
                                <div className="items-center justify-center rounded flex h-full">
                                    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 self-center" />
                                </div>
                            ):(
                                <BaseTable
                                data={routeData!}
                                columns={["Seq:50:sequence","name"]}
                                customColumns={customColumnsRoute}
                                withDetail = {false}
                                useSearch={false}
                                hideNumber={true}
                                useActions={true}
                                useEdit={true}
                                useDelete={true}
                                onRowSelect={setSelectedRowRoute}
                                addFunction={()=>{
                                    setActionType("Add");
                                    setIsAddOrEditRoute(true);
                                    setName("");
                                    setRoute("");
                                    setIsSpacer(false);
                                    setSequence(0);
                                }}
                                editFunction={(routeID)=>{
                                    setActionType("Edit");
                                    setIsAddOrEditRoute(true);
                                    let route = routeData!.find((route)=>route.id === routeID);
                                    if(route){
                                        setSequence(route.sequence);
                                        setName(route.name);
                                        setRoute(route.route);
                                        setIsSpacer(route.is_spacer);
                                    }
                                }}
                                deleteFunction={(routeID)=>{
                                    setIsDeletingRoute(true);
                                }}
                               >
                                </BaseTable>
                            )}
                       </BaseTable>
                    </>
                )}
            <Transition.Root show={isAddOrEditAddon} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={cancelAddOrEditAddon}
					onClose={() => {}}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className="fixed inset-0 bg-black/50" aria-hidden="true" />

					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							{/* This element is to trick the browser into centering the modal contents. */}
							<span
								className="hidden sm:inline-block sm:align-middle sm:h-screen"
								aria-hidden="true"
							>
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className=" inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
									<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
										<div className="sm:flex sm:items-start">
											<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
												<Dialog.Title
													as="h1"
													className="leading-6 text-textmenu text-black text-center font-bold font-montserrat"
												>
													{actionType} Addon
												</Dialog.Title>
												<Dialog.Title
													as="h3"
													className="text-texttitle leading-4 text-xl font-bold text-black mt-6 font-montserrat"
												>
												</Dialog.Title>
												<form
													onSubmit={(e) => {
														// handleSavingAgent(e);
													}}
												>
													<div className="mt-2">
														<div className="flex-col items-center space-y-1">
                                                            	
                                                            <div className="flex-1">
                                                                <div className="text-textnormal leading-5 font-medium text-gray-900 flex-1 mt-3">
                                                                    Name
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    required
                                                                    className="bg-bgfield border-none outline-none text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 "
                                                                    value={name}
                                                                    onChange={(e) => setName(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="flex-1">
                                                                <div className="text-textnormal leading-5 font-medium text-gray-900 flex-1 mt-3">
                                                                    Host
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    required
                                                                    className="bg-bgfield border-none outline-none text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 "
                                                                    value={host}
                                                                    onChange={(e) => setHost(e.target.value)}
                                                                />
                                                            </div>						
                                                            <div className="flex flex-row space-x-2 pt-3">
                                                                <label className="text-textnormal leading-5 font-medium text-gray-900 flex-initial w-1/6">
                                                                   Proxied?
                                                                </label>
                                                                <div className="flex-1">
                                                                    <label className="relative inline-flex items-center mb-5 cursor-pointer">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="sr-only peer"
                                                                            checked={proxy}
                                                                            onChange={(e) => setProxy(e.target.checked)}
                                                                        />
                                                                        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-ungutext"></div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="flex justify-center space-x-6 pt-3">
                                                                    <Button
                                                                        type="button"
                                                                        className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-lg text-textnormal px-5 py-2.5"
                                                                        onClick={() => setIsAddOrEditAddon(false)}
                                                                        ref={cancelAddOrEditAddon}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                    <Button
                                                                        type="button"
                                                                        className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-lg text-textnormal px-5 py-2.5"
                                                                        onClick={async (e) => {
                                                                            e.preventDefault();
                                                                            if (actionType === "Add") {
                                                                                await createAddon({
                                                                                    name: name,
                                                                                    host: host,
                                                                                    proxy: proxy
                                                                                });
                                                                                setIsAddOrEditAddon(false);
                                                                                Swal.fire({
                                                                                    toast: true,
                                                                                    icon: "success",
                                                                                    title: "Addon Added",
                                                                                    showConfirmButton: false,
                                                                                    position: "top-right",
                                                                                    timer: 3000,
                                                                                });
                                                                            }else if(actionType === "Edit"){
                                                                                await updateAddon({
                                                                                    id : addons![selectedRow].id!,
                                                                                    name: name,
                                                                                    host: host,
                                                                                    proxy: proxy
                                                                                });
                                                                                setIsAddOrEditAddon(false);
                                                                                Swal.fire({
                                                                                    toast: true,
                                                                                    icon: "success",
                                                                                    title: "Addon Updated",
                                                                                    showConfirmButton: false,
                                                                                    position: "top-right",
                                                                                    timer: 3000, 
                                                                                });
                                                                            }
                                                                        }}
                                                                    >
                                                                        Save
                                                                    </Button>
                                                            </div>
                                                        </div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
            <Transition.Root appear show={isDeleteingAddon} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-50"
					initialFocus={cancelDeletingAddon}
					onClose={() => setIsDeletingAddon(false)}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl px-4 pt-5 pb-4 sm:p-6 sm:pb-4 sm:flex sm:items-start sm:max-w-auto bg-white text-left align-middle shadow-xl transition-all">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
										<div className="flex justify-center">
											<svg
												width="123"
												height="123"
												viewBox="0 0 123 123"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M66.625 66.625H56.375V35.875H66.625M66.625 87.125H56.375V76.875H66.625M61.5 10.25C54.7698 10.25 48.1054 11.5756 41.8875 14.1512C35.6695 16.7267 30.0198 20.5018 25.2608 25.2608C15.6495 34.872 10.25 47.9077 10.25 61.5C10.25 75.0923 15.6495 88.128 25.2608 97.7392C30.0198 102.498 35.6695 106.273 41.8875 108.849C48.1054 111.424 54.7698 112.75 61.5 112.75C75.0923 112.75 88.128 107.35 97.7392 97.7392C107.35 88.128 112.75 75.0923 112.75 61.5C112.75 54.7698 111.424 48.1054 108.849 41.8875C106.273 35.6695 102.498 30.0198 97.7392 25.2608C92.9802 20.5018 87.3305 16.7267 81.1125 14.1512C74.8946 11.5756 68.2302 10.25 61.5 10.25Z"
													fill="#472183"
												/>
											</svg>
										</div>

										<Dialog.Title
											as="h1"
											className="leading-6 text-5xl text-black text-center font-bold font-montserrat mt-4"
										>
											DELETE
										</Dialog.Title>

										<Dialog.Title
											as="h3"
											className="leading-6 text-xl text-gray-900 text-center font-montserrat mt-4"
										>
											Do you really want to delete this data ?
										</Dialog.Title>
										<form
											onSubmit={ async (e) => {
												e.preventDefault();
                                                deleteAddon(addons![selectedRow].id!);
                                                setIsDeletingAddon(false);
                                                Swal.fire({
                                                    toast: true,
                                                    icon: "success",
                                                    title: "Addon Deleted",
                                                    showConfirmButton: false,
                                                    position: "top-right",
                                                    timer: 3000,
                                                });
											}}
										>
											<div className="flex justify-center space-x-6 pt-3">
												<Button
													type="button"
													className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-3xl text-sm px-8 py-2.5 shadow"
													onClick={() => setIsDeletingRoute(false)}
													ref={cancelDeletingRoute}
												>
													Cancel
												</Button>
												<Button
													type="submit"
													className="text-white bg-ungutext border border-ungutext font-medium rounded-3xl text-sm px-8 py-2.5 shadow-2xl"
												>
													Delete
												</Button>
											</div>
										</form>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
        	<Transition.Root show={isAddOrEditRoute} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={cancelAddOrEditRoute}
					onClose={() => {}}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className="fixed inset-0 bg-black/50" aria-hidden="true" />

					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							{/* This element is to trick the browser into centering the modal contents. */}
							<span
								className="hidden sm:inline-block sm:align-middle sm:h-screen"
								aria-hidden="true"
							>
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className=" inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
									<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
										<div className="sm:flex sm:items-start">
											<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
												<Dialog.Title
													as="h1"
													className="leading-6 text-textmenu text-black text-center font-bold font-montserrat"
												>
													{actionType} Route
												</Dialog.Title>
												<Dialog.Title
													as="h3"
													className="text-texttitle leading-4 text-xl font-bold text-black mt-6 font-montserrat"
												>
												</Dialog.Title>
												<form
													onSubmit={(e) => {
														// handleSavingAgent(e);
													}}
												>
													<div className="mt-2">
														<div className="flex-col items-center space-y-1">
                                                            <div className="flex flex-row space-x-2 pt-3">
                                                                <label className="text-textnormal leading-5 font-medium text-gray-900 flex-initial w-1/6">
                                                                   Is Spacer
                                                                </label>
                                                                <div className="flex-1">
                                                                    <label className="relative inline-flex items-center mb-5 cursor-pointer">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="sr-only peer"
                                                                            checked={isSpacer}
                                                                            onChange={(e) => setIsSpacer(e.target.checked)}
                                                                        />
                                                                        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-ungutext"></div>
                                                                    </label>
                                                                </div>
                                                            </div>	
                                                            {!isSpacer && (
                                                                <>
                                                                    <div className="flex-1">
                                                                        <div className="text-textnormal leading-5 font-medium text-gray-900 flex-1 mt-3">
                                                                            Name
                                                                        </div>
                                                                        <input
                                                                            type="text"
                                                                            required
                                                                            className="bg-bgfield border-none outline-none text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 "
                                                                            value={name}
                                                                            onChange={(e) => setName(e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <div className="text-textnormal leading-5 font-medium text-gray-900 flex-1 mt-3">
                                                                            Route
                                                                        </div>
                                                                        <input
                                                                            type="text"
                                                                            required
                                                                            className="bg-bgfield border-none outline-none text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 "
                                                                            value={route}
                                                                            onChange={(e) => setRoute(e.target.value)}
                                                                        />
                                                                    </div>
                                                                    
                                                                </>
                                                            )}
                                                            <div className="flex-1">
                                                                <div className="text-textnormal leading-5 font-medium text-gray-900 flex-1 mt-3">
                                                                    Sequence
                                                                </div>
                                                                <input
                                                                    type="number"
                                                                    required
                                                                    className="bg-bgfield border-none outline-none text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 "
                                                                    value={sequence}
                                                                    onChange={(e) => setSequence(parseInt(e.target.value))}
                                                                />				
                                                            </div>							
                                                            <div className="flex justify-center space-x-6 pt-3">
                                                                    <Button
                                                                        type="button"
                                                                        className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-lg text-textnormal px-5 py-2.5"
                                                                        onClick={() => setIsAddOrEditRoute(false)}
                                                                        ref={cancelAddOrEditRoute}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                    <Button
                                                                        type="button"
                                                                        className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-lg text-textnormal px-5 py-2.5"
                                                                        onClick={async (e) => {
                                                                            e.preventDefault();
                                                                            if (actionType === "Add") {
                                                                                await createRoute({
                                                                                    addon_id: addons![selectedRow].id!,
                                                                                    sequence: sequence,
                                                                                    name: name,
                                                                                    route: route,
                                                                                    is_spacer: isSpacer
                                                                                });
                                                                                setIsAddOrEditRoute(false);
                                                                                Swal.fire({
                                                                                    toast: true,
                                                                                    icon: "success",
                                                                                    title: "Route Added",
                                                                                    showConfirmButton: false,
                                                                                    position: "top-right",
                                                                                    timer: 3000,
                                                                                });
                                                                            }else if(actionType === "Edit"){
                                                                                await updateRoute({
                                                                                    id : routeData![selectedRowRoute].id!,
                                                                                    addon_id: addons![selectedRow].id!,
                                                                                    sequence: sequence,
                                                                                    name: name,
                                                                                    route: route,
                                                                                    is_spacer: isSpacer
                                                                                });
                                                                                setIsAddOrEditRoute(false);
                                                                                Swal.fire({
                                                                                    toast: true,
                                                                                    icon: "success",
                                                                                    title: "Route Updated",
                                                                                    showConfirmButton: false,
                                                                                    position: "top-right",
                                                                                    timer: 3000, 
                                                                                });
                                                                            }
                                                                        }}
                                                                    >
                                                                        Save
                                                                    </Button>
                                                            </div>
                                                        </div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
            <Transition.Root appear show={isDeleteingRoute} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-50"
					initialFocus={cancelDeletingRoute}
					onClose={() => setIsDeletingRoute(false)}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl px-4 pt-5 pb-4 sm:p-6 sm:pb-4 sm:flex sm:items-start sm:max-w-auto bg-white text-left align-middle shadow-xl transition-all">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
										<div className="flex justify-center">
											<svg
												width="123"
												height="123"
												viewBox="0 0 123 123"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M66.625 66.625H56.375V35.875H66.625M66.625 87.125H56.375V76.875H66.625M61.5 10.25C54.7698 10.25 48.1054 11.5756 41.8875 14.1512C35.6695 16.7267 30.0198 20.5018 25.2608 25.2608C15.6495 34.872 10.25 47.9077 10.25 61.5C10.25 75.0923 15.6495 88.128 25.2608 97.7392C30.0198 102.498 35.6695 106.273 41.8875 108.849C48.1054 111.424 54.7698 112.75 61.5 112.75C75.0923 112.75 88.128 107.35 97.7392 97.7392C107.35 88.128 112.75 75.0923 112.75 61.5C112.75 54.7698 111.424 48.1054 108.849 41.8875C106.273 35.6695 102.498 30.0198 97.7392 25.2608C92.9802 20.5018 87.3305 16.7267 81.1125 14.1512C74.8946 11.5756 68.2302 10.25 61.5 10.25Z"
													fill="#472183"
												/>
											</svg>
										</div>

										<Dialog.Title
											as="h1"
											className="leading-6 text-5xl text-black text-center font-bold font-montserrat mt-4"
										>
											DELETE
										</Dialog.Title>

										<Dialog.Title
											as="h3"
											className="leading-6 text-xl text-gray-900 text-center font-montserrat mt-4"
										>
											Do you really want to delete this data ?
										</Dialog.Title>
										<form
											onSubmit={ async (e) => {
												e.preventDefault();
                                                deleteRoute({
                                                    addon_id: addons![selectedRow].id!,
                                                    id: routeData![selectedRowRoute].id!
                                                });
                                                setIsDeletingRoute(false);
                                                Swal.fire({
                                                    toast: true,
                                                    icon: "success",
                                                    title: "Route Deleted",
                                                    showConfirmButton: false,
                                                    position: "top-right",
                                                    timer: 3000,
                                                });
											}}
										>
											<div className="flex justify-center space-x-6 pt-3">
												<Button
													type="button"
													className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-3xl text-sm px-8 py-2.5 shadow"
													onClick={() => setIsDeletingRoute(false)}
													ref={cancelDeletingRoute}
												>
													Cancel
												</Button>
												<Button
													type="submit"
													className="text-white bg-ungutext border border-ungutext font-medium rounded-3xl text-sm px-8 py-2.5 shadow-2xl"
												>
													Delete
												</Button>
											</div>
										</form>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
        </div>
    )
}