import React from "react";
import { Client, Issue, Ticket, User } from "../services/types";
import { useUpdateIssueMutation } from "../services/API";
import { omit } from "../utils/util";

interface issueCardProps {
	useOpen?: () => void;

	issues: Issue[];
	selectedIssue?: Issue;
	loadIssue: (id: number) => any;
	isOpen: boolean;
	onIssueSelect?: (id: number) => void;
	onSetNote?: (value: string) => void;
	onSetLog?: (value: string) => void;
}

export default function IssueCard(props: issueCardProps) {
	const [
		updateIssue,
		{ error: updateIssueError, isLoading: updateIssueIsLoading },
	] = useUpdateIssueMutation();

	const generateLogo = (client: Client) => {
		if (client.logo_url?.length! > 0)
			return process.env.REACT_APP_API_URL + "/files/" + client.logo_url;
		let abbreviation = "";
		client.name.split(" ").forEach((word: string) => {
			if (word.indexOf("PT.") > -1) return;
			abbreviation += word[0];
		});
		abbreviation = abbreviation.toUpperCase();
		return (
			"https://ui-avatars.com/api/?name=" +
			abbreviation +
			"&background=472183&color=ffffff&length=" +
			abbreviation.length
		);
	};

	return (
		<>
			{props.issues.length > 0 ? (
				props.issues.map((issue, index) => (
					<div
						className={
							(issue.status === "open" ? "cursor-default" : "cursor-pointer") +
							" flex-initial w-full flex flex-col rounded-xl shadow-xl bg-white"
						}
						onClick={async () => {
							if (props.onIssueSelect) props.onIssueSelect(index);
							await props.loadIssue(issue.id!).unwrap();
							if (props.isOpen === false) {
								props.useOpen!();
							}
						}}
					>
						{issue.priority_level === 5 && (
							<div className="bg-[#570606] flex-initial h-[30px] w-full rounded-t-xl"></div>
						)}
						{issue.priority_level === 4 && (
							<div className="bg-[#B40606] flex-initial h-[30px] w-full rounded-t-xl"></div>
						)}
						{issue.priority_level === 3 && (
							<div className="bg-[#E2B203] flex-initial h-[30px] w-full rounded-t-xl"></div>
						)}
						{issue.priority_level === 2 && (
							<div className="bg-[#475706] flex-initial h-[30px] w-full rounded-t-xl"></div>
						)}
						{issue.priority_level === 1 && (
							<div className="bg-[#94C748] flex-initial h-[30px] w-full rounded-t-xl"></div>
						)}

						{/* Test */}
						{issue.priority_level === 0 && (
							<div className="bg-gray-400 flex-initial h-[30px] w-full rounded-t-xl"></div>
						)}
						{/*  */}

						<div className="flex-initial h-[6px] flex flex-row space-x-[2px] px-[1%] mt-[1px]">
							{/* {issue.status === "open" && (
								<>
									<div className="flex-1 bg-[#6D33C7] rounded-full"></div>
									<div className="flex-1 bg-[#fff] rounded-full"></div>
									<div className="flex-1 bg-[#fff] rounded-full"></div>
									<div className="flex-1 bg-[#fff] rounded-full"></div>
								</>
							)}
							{issue.status === "process" && (
								<>
									<div className="flex-1 bg-[#6D33C7] rounded-full"></div>
									<div className="flex-1 bg-[#EC9B21] rounded-full"></div>
									<div className="flex-1 bg-[#fff] rounded-full"></div>
									<div className="flex-1 bg-[#fff] rounded-full"></div>
								</>
							)}
							{issue.status === "pending" && (
								<>
									<div className="flex-1 bg-[#6D33C7] rounded-full"></div>
									<div className="flex-1 bg-[#EC9B21] rounded-full"></div>
									<div className="flex-1 bg-[#74CBB9] rounded-full"></div>
									<div className="flex-1 bg-[#fff] rounded-full"></div>
								</>
							)}
							{issue.status === "closed" && (
								<>
									<div className="flex-1 bg-[#6D33C7] rounded-full"></div>
									<div className="flex-1 bg-[#EC9B21] rounded-full"></div>
									<div className="flex-1 bg-[#74CBB9] rounded-full"></div>
									<div className="flex-1 bg-[#3795BD] rounded-full"></div>
								</>
							)} */}

							{/* <div className="flex-1 bg-[#6D33C7] rounded-full"></div>
							<div className="flex-1 bg-[#EC9B21] rounded-full"></div>
							<div className="flex-1 bg-[#3795BD] rounded-full"></div> */}
						</div>
						<div className="flex-1 flex flex-row items-center px-[2%] pt-[2%]">
							<img
								className="flex-initial h-[40px] w-[40px]"
								src={generateLogo(issue.ticket?.client!)}
							/>
							<div className="flex-1 text-[16px] ml-[2%] font-semibold">
								{issue.ticket?.client.name!}
							</div>
							<div className="flex-initial items-end text-ungutext font-medium text-[14px] capitalize">
								{issue.ticket?.level!} #{issue.ticket?.id!}
							</div>
						</div>
						<div className="flex-1 flex-row flex h-max px-[2%]">
							<div className="flex-initial h-[40px] w-[40px]"></div>
							<div className="flex-1 ml-[2%] text-[14px] text-ellipsis overflow-hidden line-clamp-2">
								{issue.ticket?.title!}
							</div>
						</div>
						<div className="flex-1 flex-row flex justify-end space-x-[1%] px-[2%] py-[2%]">
							{/* {issue.status === "open" && (
								<button
									type="button"
									className="flex flex-initial border border-[#6D33C7] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.status}
								</button>
							)} */}
							{issue.status === "process" && (
								<button
									type="button"
									className="flex flex-initial border border-[#EC9B21] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.status}
								</button>
							)}
							{issue.status === "pending" && (
								<button
									type="button"
									className="flex flex-initial border border-[#74CBB9] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.status}
								</button>
							)}
							{issue.status === "closed" && (
								<button
									type="button"
									className="flex flex-initial border border-[#3795BD] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.status}
								</button>
							)}
							{issue.ticket?.category!.toLowerCase() === "question" && (
								<button
									type="button"
									className="flex flex-initial border border-[#04B700] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.ticket?.category!}
								</button>
							)}
							{issue.ticket?.category!.toLowerCase() === "incident" && (
								<button
									type="button"
									className="flex flex-initial border border-[#B40606] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.ticket?.category!}
								</button>
							)}
							{issue.ticket?.category!.toLowerCase() === "problem" && (
								<button
									type="button"
									className="flex flex-initial border border-[#94C748] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.ticket?.category!}
								</button>
							)}
							{issue.ticket?.category!.toLowerCase() === "feature_request" && (
								<button
									type="button"
									className="flex flex-initial bg-white border border-black rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.ticket?.category!}
								</button>
							)}

							{issue.ticket?.type! === "server" && (
								<button
									type="button"
									className="flex-initial border border-[#570606] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.ticket?.type!}
								</button>
							)}
							{issue.ticket?.type! === "data" && (
								<button
									type="button"
									className="flex-initial border border-[#B40606] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.ticket?.type!}
								</button>
							)}
							{issue.ticket?.type! === "laporan" && (
								<button
									type="button"
									className="flex-initial border border-[#E2B203] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.ticket?.type!}
								</button>
							)}
							{issue.ticket?.type! === "user_role" && (
								<button
									type="button"
									className="flex-initial border border-[#94C748] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.ticket?.type!}
								</button>
							)}
							{issue.ticket?.type! === "print_out" && (
								<button
									type="button"
									className="flex-initial border border-[#475706] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.ticket?.type!}
								</button>
							)}
							{issue.ticket?.type! === "process" && (
								<button
									type="button"
									className="flex-initial border border-[#E2B203] rounded-lg text-[14px] py-[0.5%] px-[2%] cursor-default capitalize"
								>
									{issue.ticket?.type!}
								</button>
							)}

							{issue.status === "open" && (
								<button
									type="button"
									className="flex-initial border bg-white border-ungutext rounded-lg text-[14px] py-[0.5%] px-[2%] hover:text-white hover:bg-ungutext"
									onClick={async () => {
										let i = {
											...issue,
											status: "process",
										};
										i = omit(i, ["assigned_to", "channel", "ticket"]);
										await updateIssue(i).unwrap();
										await props.loadIssue(issue.id!).unwrap();
										// await props.onSetLog(issue?.log! !== undefined ? issue?.log! : "");
										// await props.onSetNote(issue?.note! !== undefined ? issue?.note! : "");
										props.useOpen!();
									}}
								>
									Open
								</button>
							)}
						</div>
					</div>
				))
			) : (
				<div className="text-center py-2">No Data</div>
			)}
		</>
	);
}
