import moment from "moment";
//@ts-ignore
import localeID from "moment/locale/id";
import { useGetIssuesQuery, useGetTicketsQuery, useLazyGetMessagesQuery, useUpdateTicketMutation } from "../../services/API";
import useAuth from "../../auth/useAuth";
import { Client, Ticket } from "../../services/types";
import BaseTable from "../../components/BaseTable";
import BubbleChat from "../../components/BubbleChat";
import { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { RowSelectionState, createColumnHelper } from "@tanstack/react-table";
import { omit } from "../../utils/util";
moment.updateLocale("id", localeID);

interface loaderImg {
	url: string;
	state: boolean;
}

export default function AllTicketPage(){
    const auth = useAuth();

	const {
		data: tickets,
		error: ticketsError,
		isLoading: ticketsLoading,
		isFetching: ticketsFetching,
		currentData: currentTickets,
	} = useGetTicketsQuery(auth?.user?.id!);
	const {
		data: issues,
		error: issuesError,
		isLoading: issuesLoading,
	} = useGetIssuesQuery(auth?.user?.id!);

	const [
		loadMessages,
		{
			data: messages,
			error: messagesError,
			isLoading: messagesLoading,
			isFetching: messagesFetching,
		},
	] = useLazyGetMessagesQuery();
	const [imgLoad, setImgLoad] = useState<loaderImg[]>([]);
	const [imgUrl, setImgUrl] = useState<string>("");
	const [isImageOpen, setIsImageOpen] = useState(false);
	const cancelImageOpenRef = useRef(null);
	const messagesRef = useRef<HTMLDivElement>(null);
	const [allImageLoaded, setAllImageLoaded] = useState(false);
	const [selectedRow, setSelectedRow] = useState<number>(-1);
	const [selectState, setSelectState] = useState<RowSelectionState>({});
	const [
		updateTicket,
		{ error: updateTicketError, isLoading: updateTicketLoading },
	] = useUpdateTicketMutation();

    const generateLogo = (client: Client) => {
		if (client.logo_url?.length! > 0)
			return process.env.REACT_APP_API_URL + "/files/" + client.logo_url;
		let abbreviation = "";
		client.name.split(" ").forEach((word: string) => {
			if (word.indexOf("PT.") > -1) return;
			abbreviation += word[0];
		});
		abbreviation = abbreviation.toUpperCase();
		return (
			"https://ui-avatars.com/api/?name=" +
			abbreviation +
			"&background=472183&color=ffffff&length=" +
			abbreviation.length
		);
	};

	useLayoutEffect(() => {
		if (!allImageLoaded && messages) {
			messages?.map((message) => {
				if (message.image !== null) {
					setImgLoad((s) => [...s, { url: message.image, state: false }]);
				}
			});
		}
		if (
			messagesRef.current
		) {
			document
				.getElementById("messageContainer")
				?.setAttribute("style", "scroll-behavior: instant");
			messagesRef.current.scrollTo({
				top: messagesRef.current.scrollHeight,
			});
		}
	}, [
		messages,
		ticketsLoading,
		ticketsFetching,
		messagesRef.current,
		messagesRef,
	]);

	useEffect(() => {
		if(selectedRow > -1){
			loadMessages(tickets![selectedRow].channel);
		}
	},[selectedRow])

	const columnHelper = createColumnHelper<Ticket>();
	const customColumns = useMemo(
        ()=>[
            {
				column: columnHelper.accessor("client.name", {
					header: "Client",
					size: Infinity,
					cell: (props) => {
						return (
							<div className="flex items-center">
								<img
									src={generateLogo(props.row.original.client!)}
									className="w-8 h-8 mr-2"
								/>
								<div className="text-ellipsis">{props.getValue()}</div>
							</div>
						);
					},
				}),
				position: 2,
			},
			{
				column: columnHelper.accessor("title", {
					header: "Title",
					size: 200,
					cell: (props) => {
						let title = props.getValue();
						if (title.length > 20) {
							title = title.substring(0, 20) + "...";
						}
						return (
							<div>{title}</div>
						);
					},
				}),
				position: 3,
			},
			{
                column: columnHelper.accessor("status", {
                    header: "Status",
                    size: 200,
                    cell: (props) => {
                        return (
                            <select className="w-full text-black" defaultValue={props.getValue()} onChange={async(e)=>{
                                let t = {
									...props.row.original,
									status: e.target.value,
								};
								t = omit(t, ["client", "user", "channel", "priority"]);
                                await updateTicket(t);
                            }}>
                                <option value="open">Open</option>
                                <option value="process">Process</option>
                                <option value="hold">Hold</option>
                                <option value="pending">Pending</option>
                                <option value="closed">Close</option>
                            </select>
                        )
                    }
                }),
                position: 7
            }
		],[])

    return (
        <>
            <div className="flex flex-col w-full mx-auto h-full">
                {ticketsLoading ? (
                    <div className="items-center justify-center rounded flex h-full">
						<div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900 self-center" />
					</div>
                ):(
                    <>
                       <BaseTable
                        data={tickets!}
                        columns={["level","Agent:100:response_by.name"]}
						customColumns={customColumns}
						withDetail = {selectedRow > -1}
						onRowSelect={setSelectedRow}
						onRowSelectChange={setSelectState}
						useSearch={true}
                       >
						<div className="flex flex-col w-full h-full space-y-8 bg-gray-200">
							<div className="bg-[#0F0420] flex-1 flex flex-col items-center space-y-2 p-2 w-[100%]">
								<div className="text-white text-[18px] py-2 font-[700]">
									<div>TICKET INFO</div>
								</div>
								<hr className="border border-white w-full" />
								{tickets![selectedRow] && (
									<table className="table text-white w-full">
										<tr>
											<td>Type</td>
											<td>:</td>
											<td className="capitalize">{(tickets![selectedRow].type == "") ? "Not Set" : tickets![selectedRow].type}</td>
											<td>Category</td>
											<td>:</td>
											<td className="capitalize">{(tickets![selectedRow].category == "") ? "Not Set" : tickets![selectedRow].category}</td>
										</tr>
										<tr>
											<td>Urgency</td>
											<td>:</td>
											<td className="capitalize">{(tickets![selectedRow].urgency == "") ? "Not Set" : tickets![selectedRow].urgency}</td>
											<td>Impact</td>
											<td>:</td>
											<td className="capitalize">{(tickets![selectedRow].impact == "") ? "Not Set" : tickets![selectedRow].impact}</td>
										</tr>
										<tr>
											<td>Priority</td>
											<td>:</td>
											<td colSpan={4} className="capitalize">{(tickets![selectedRow].priority == "") ? "Not Set" : tickets![selectedRow].priority}</td>
										</tr>
										<tr>
											<td>Issue</td>
											<td>:</td>
											<td colSpan={4} className="capitalize">{(tickets![selectedRow].issue_detail == "") ? "Not Set" : tickets![selectedRow].issue_detail}</td>
										</tr>
									</table>
								)}
							</div>
							<div className="bg-[#0F0420] h-3/5 flex flex-col items-center space-y-2 p-2 w-[100%]">
								<div className="text-white text-[18px] py-2 font-[700]">
									<div>MESSAGE</div>
								</div>
								<hr className="border border-white w-full" />
								<div id="messageContainer"
										ref={messagesRef}
										className="space-y-2 bg-white h-full w-full flex-1 flex flex-col overflow-y-auto pb-4 scroll-smooth scrollbar-thumb-rounded-full scrollbar-thin scrollbar-thumb-[#0F0420] scrollbar-track-transparent"
									>
										{messages?.map((message, index) => {
											if(!message || !messages) return;
											return (
												<>
													{index === 0 && (
														<div className="flex items-center mx-1 space-x-2 sticky top-0 bg-[#EFE6FE]">
															<hr className="flex-1 border-[#0F0420]" />
															<div className="text-[14px]">
																{moment(message.created_at).format(
																	"dddd, DD-MM-YYYY"
																)}
															</div>
															<hr className="flex-1 border-[#0F0420]" />
														</div>
													)}
													{messages[index - 1] &&
														moment(messages[index - 1].created_at).format(
															"DD/MM/YYYY"
														) !==
															moment(message.created_at).format("DD/MM/YYYY") && (
															<div className="flex items-center mx-1 space-x-2 sticky top-0 bg-[#EFE6FE]">
																<hr className="flex-1 border-[#0F0420]" />
																<div className="text-[14px]">
																	{moment(message.created_at).format(
																		"dddd, DD-MM-YYYY"
																	)}
																</div>
																<hr className="flex-1 border-[#0F0420]" />
															</div>
														)}
													<BubbleChat
														message={message}
														setImgLoad={setImgLoad}
														setImgUrl={setImgUrl}
														setImgOpen={setIsImageOpen}
													></BubbleChat>
												</>
											);
										})}
								</div>
							</div>
						</div>
                       </BaseTable>
                    </>
                )}
            </div>
			<Transition.Root appear show={isImageOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-50"
					initialFocus={cancelImageOpenRef}
					onClose={() => setIsImageOpen(false)}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0">
						<div className="flex h-full w-full items-center justify-center p-8 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="h-auto w-auto max-h-full max-w-full transform rounded-2xl text-left align-middle shadow-xl transition-all flex justify-center relative">
									<img className="" src={imgUrl} alt="" />
									<div
										className="absolute right-0 top-0 bg-ungutext rounded-full h-[26px] w-[26px] flex justify-center items-center cursor-pointer"
										onClick={() => {
											setIsImageOpen(false);
											setImgUrl("");
										}}
									>
										<svg
											width="20"
											height="20"
											viewBox="0 0 27 25"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M26.0308 0.813762C25.8464 0.643416 25.6274 0.50827 25.3863 0.41606C25.1452 0.32385 24.8867 0.276386 24.6257 0.276386C24.3646 0.276386 24.1061 0.32385 23.865 0.41606C23.6239 0.50827 23.4049 0.643416 23.2205 0.813762L13.4739 9.78092L3.72736 0.795386C3.54283 0.625264 3.32376 0.490315 3.08266 0.398246C2.84156 0.306176 2.58315 0.258789 2.32218 0.258789C2.06122 0.258789 1.80281 0.306176 1.56171 0.398246C1.3206 0.490315 1.10154 0.625264 0.917004 0.795386C0.732474 0.965508 0.586096 1.16747 0.486229 1.38975C0.386361 1.61202 0.334961 1.85026 0.334961 2.09085C0.334961 2.33144 0.386361 2.56967 0.486229 2.79194C0.586096 3.01422 0.732474 3.21618 0.917004 3.38631L10.6636 12.3718L0.917004 21.3574C0.732474 21.5275 0.586096 21.7295 0.486229 21.9517C0.386361 22.174 0.334961 22.4122 0.334961 22.6528C0.334961 22.8934 0.386361 23.1317 0.486229 23.3539C0.586096 23.5762 0.732474 23.7782 0.917004 23.9483C1.10154 24.1184 1.3206 24.2534 1.56171 24.3454C1.80281 24.4375 2.06122 24.4849 2.32218 24.4849C2.58315 24.4849 2.84156 24.4375 3.08266 24.3454C3.32376 24.2534 3.54283 24.1184 3.72736 23.9483L13.4739 14.9628L23.2205 23.9483C23.405 24.1184 23.6241 24.2534 23.8652 24.3454C24.1063 24.4375 24.3647 24.4849 24.6257 24.4849C24.8866 24.4849 25.145 24.4375 25.3861 24.3454C25.6272 24.2534 25.8463 24.1184 26.0308 23.9483C26.2154 23.7782 26.3617 23.5762 26.4616 23.3539C26.5615 23.1317 26.6129 22.8934 26.6129 22.6528C26.6129 22.4122 26.5615 22.174 26.4616 21.9517C26.3617 21.7295 26.2154 21.5275 26.0308 21.3574L16.2843 12.3718L26.0308 3.38631C26.7882 2.68805 26.7882 1.51202 26.0308 0.813762Z"
												fill="#fff"
											/>
										</svg>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
        </>
    )
}