import { Navigate,  useLocation} from "react-router-dom";
import {FC, useEffect, useState} from "react";
import {useAppSelector} from "../hooks";
import Menu from "../components/Menu";

interface PrivateRouteProps {
    children: JSX.Element;
    role: number;
}
const PrivateRoute:FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
    const authData = useAppSelector(state => state.auth)
    const location = useLocation()
    if(authData.user === null){
        return <Navigate to="/login" state={location}/>
    }
    if(authData.user.role_id >= props.role){
        return <Menu>{props.children}</Menu>
    }
    return <Navigate to="/login" state={location}/>
}
export default PrivateRoute;
