import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import "./types";
import {
	Application,
	Client,
	User,
	Credential,
	Role,
	Configuration,
	Statistic,
	Ticket,
	Message,
	Channel,
	Agent,
	Issue,
	KnowledgeBase,
	Addon,
	AddonRoute,
} from "./types";
import { omit } from "../utils/util";
import store from "../store";

export const API = createApi({
	reducerPath: "API",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_URL, //"https://integra.exzork.me/api/",
		credentials: "include",
	}),
	tagTypes: [
		"Users",
		"Clients",
		"Credentials",
		"Roles",
		"Applications",
		"ClientUsers",
		"Configuration",
		"Tickets",
		"Messages",
		"Agents",
		"Statistic",
		"Issues",
		"KnowledgeBase",
		"Addons",
		"AddonRoute"
	],
	endpoints: (builder) => ({
		authCheck: builder.query({
			query: () => `auth/check`,
		}),
		getRoles: builder.query<Role[], void>({
			query: () => `roles`,
		}),
		getUsers: builder.query<User[], void>({
			query: () => `users`,
			transformResponse: (res: User[]) =>
				res
					.filter((user) => user.role_id > 1)
					.sort((a, b) =>
						a?.CreatedAt?.getTime()! > b?.CreatedAt?.getTime()! ? 1 : -1
					),
			providesTags: (result) =>
				result
					? [
							...result.map(({ id }) => ({ type: "Users", id } as const)),
							{
								type: "Users",
								id: "LIST",
							},
					  ]
					: [{ type: "Users", id: "LIST" }],
		}),
		getUser: builder.query<User, number>({
			query: (id) => `users/${id}`,
			providesTags: (result) =>
				result ? [{ type: "Users", id: result.id }] : [],
		}),
		createUser: builder.mutation<User, User>({
			query: (user) => ({
				url: `users`,
				method: "POST",
				body: user,
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "Users", id: "LIST" }] : [],
		}),
		updateUser: builder.mutation<User, User>({
			query: (user) => ({
				url: `users/${user.id}`,
				method: "PUT",
				body: omit(user, ["id"]),
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "Users", id: result.id }] : [],
		}),
		deleteUser: builder.mutation<User, number>({
			query: (id) => ({
				url: `users/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, _, query) => [{ type: "Users", id: query }],
		}),
		getClients: builder.query<Client[], void>({
			query: () => `clients`,
			transformResponse: (res: Client[]) =>
				res.sort((a, b) =>
					a?.CreatedAt?.getTime()! > b?.CreatedAt?.getTime()! ? 1 : -1
				),
			providesTags: (result) =>
				result
					? [
							...result.map(
								({ id }) =>
									({
										type: "Clients",
										id,
									} as const)
							),
							{ type: "Clients", id: "LIST" },
					  ]
					: [{ type: "Clients", id: "LIST" }],
		}),
		getClient: builder.query<Client, number>({
			query: (id) => `clients/${id}`,
			providesTags: (result) =>
				result ? [{ type: "Clients", id: result.id }] : [],
		}),
		createClient: builder.mutation<Client, Client>({
			query: (client) => ({
				url: `clients`,
				method: "POST",
				body: client,
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "Clients", id: "LIST" }] : [],
		}),
		updateClient: builder.mutation<Client, Client>({
			query: (client) => ({
				url: `clients/${client.id}`,
				method: "PUT",
				body: omit(client, ["id"]),
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "Clients", id: result.id }] : [],
		}),
		deleteClient: builder.mutation<Client, number>({
			query: (id) => ({
				url: `clients/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, _, id) => [{ type: "Clients", id: id }],
		}),
		getApplications: builder.query<Application[], number>({
			query: (id) => `clients/${id}/applications`,
			providesTags: (result, error, id) =>
				result
					? [
							...result.map(
								({ id }) =>
									({
										type: "Applications",
										id,
									} as const)
							),
							{ type: "Applications", id: "LIST" },
					  ]
					: [{ type: "Applications", id: "LIST" }],
		}),
		getApplication: builder.query<Application, { id: number; appid: number }>({
			query: (params) => `clients/${params.id}/applications/${params.appid}`,
			providesTags: (result) =>
				result ? [{ type: "Applications", id: result.id }] : [],
		}),
		createApplication: builder.mutation<
			Application,
			{ id: number; app: Application }
		>({
			query: (params) => ({
				url: `clients/${params.id}/applications`,
				method: "POST",
				body: params.app,
			}),
			invalidatesTags: (result) =>
				result
					? [
							{ type: "Applications", id: "LIST" },
							{
								type: "Clients",
								id: result.client_id,
							},
					  ]
					: [],
		}),
		updateApplication: builder.mutation<
			Application,
			{ id: number; app: Application }
		>({
			query: (params) => ({
				url: `clients/${params.id}/applications/${params.app.id}`,
				method: "PUT",
				body: params.app,
			}),
			invalidatesTags: (result) =>
				result
					? [
							{ type: "Applications", id: result.id },
							{ type: "Configuration", id: result.id },
					  ]
					: [],
		}),
		deleteApplication: builder.mutation<
			Application,
			{ id: number; appid: number }
		>({
			query: (params) => ({
				url: `clients/${params.id}/applications/${params.appid}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, _, query) => [
				{ type: "Applications", id: query.appid },
			],
		}),
		actionApplication: builder.mutation<
			Application,
			{ id: number; appid: number; name: string; action: string }
		>({
			query: (params) => ({
				url: `clients/${params.id}/applications/${params.appid}/action?status=${params.action}&name=${params.name}`,
				method: "POST",
			}),
		}),
		getApplicationsStatistic: builder.query<
			Statistic,
			{ id: number; appid: number }
		>({
			query: (params) =>
				`clients/${params.id}/applications/${params.appid}/statistic`,
			transformResponse: (res: any) => {
				return res;
			},
			async onCacheEntryAdded(
				params,
				{ cacheDataLoaded, cacheEntryRemoved, updateCachedData }
			) {
				let pusherChannel = window.pusher?.subscribe(
					"private-statistic_" + params.appid
				);
				pusherChannel?.bind("update", (data: Statistic) => {
					updateCachedData((draft) => {
						Object.assign(draft, data);
					});
				});
			},
		}),
		getApplicationConfiguration: builder.query<
			Configuration,
			{ id: number; appid: number }
		>({
			query: (params) =>
				`clients/${params.id}/applications/${params.appid}/configurations`,
			providesTags: (result) =>
				result ? [{ type: "Configuration", id: result.application_id }] : [],
		}),
		createApplicationConfiguration: builder.mutation<
			Configuration,
			{ id: number; appid: number; config: Configuration }
		>({
			query: (params) => ({
				url: `clients/${params.id}/applications/${params.appid}/configurations`,
				method: "POST",
				body: params.config,
			}),
			invalidatesTags: (result) =>
				result
					? [
							{
								type: "Configuration",
								id: result.application_id,
							},
							{ type: "Applications", id: result.application_id },
					  ]
					: [],
		}),
		updateApplicationConfiguration: builder.mutation<
			Configuration,
			{ id: number; appid: number; config: Configuration }
		>({
			query: (params) => ({
				url: `clients/${params.id}/applications/${params.appid}/configurations`,
				method: "PUT",
				body: params.config,
			}),
			invalidatesTags: (result) =>
				result
					? [
							{
								type: "Configuration",
								id: result.application_id,
							},
							{ type: "Applications", id: result.application_id },
					  ]
					: [],
		}),
		deleteApplicationConfiguration: builder.mutation<
			Configuration,
			{ id: number; appid: number }
		>({
			query: (params) => ({
				url: `clients/${params.id}/applications/${params.appid}/configurations`,
				method: "DELETE",
			}),
			invalidatesTags: (result, _, query) => [
				{ type: "Configuration", id: query.appid },
				{
					type: "Applications",
					id: query.appid,
				},
			],
		}),
		getClientUsers: builder.query<User[], number>({
			query: (id) => `clients/${id}/users`,
			transformResponse: (res: User[]) =>
				res
					.filter((user) => user.role_id === 1)
					.sort((a, b) =>
						a?.CreatedAt?.getTime()! > b?.CreatedAt?.getTime()! ? 1 : -1
					),
			providesTags: (result, error, id) =>
				result
					? [
							...result.map(
								({ id }) =>
									({
										type: "Users",
										id,
									} as const)
							),
							{ type: "Users", id: "LIST" },
					  ]
					: [{ type: "Users", id: "LIST" }],
		}),
		getClientUser: builder.query<User, { id: number; userid: number }>({
			query: (params) => `clients/${params.id}/users/${params.userid}`,
			providesTags: (result) =>
				result ? [{ type: "Users", id: result.id }] : [],
		}),
		createClientUser: builder.mutation<User, { id: number; user: User }>({
			query: (params) => ({
				url: `clients/${params.id}/users`,
				method: "POST",
				body: params.user,
			}),
			invalidatesTags: (result) =>
				result
					? [
							{ type: "Users", id: "LIST" },
							{ type: "Clients", id: result.client_id },
					  ]
					: [],
		}),
		updateClientUser: builder.mutation<User, { id: number; user: User }>({
			query: (params) => ({
				url: `clients/${params.id}/users/${params.user.id}`,
				method: "PUT",
				body: params.user,
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "Users", id: result.id }] : [],
		}),
		deleteClientUser: builder.mutation<User, { id: number; userid: number }>({
			query: (params) => ({
				url: `clients/${params.id}/users/${params.userid}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, _, query) => [
				{ type: "Users", id: query.userid },
				{ type: "Clients", id: query.id },
			],
		}),
		getClientUserCredentials: builder.query<
			Credential[],
			{ id: number; userid: number }
		>({
			query: (params) =>
				`clients/${params.id}/users/${params.userid}/credentials`,
			providesTags: (result, error, params) =>
				result
					? [
							...result.map(
								({ id }) =>
									({
										type: "Credentials",
										id,
									} as const)
							),
							{ type: "Credentials", id: "LIST" },
					  ]
					: [{ type: "Credentials", id: "LIST" }],
		}),
		getClientUserCredential: builder.query<
			Credential,
			{ id: number; userid: number; credid: number }
		>({
			query: (params) =>
				`clients/${params.id}/users/${params.userid}/credentials/${params.credid}`,
			providesTags: (result) =>
				result ? [{ type: "Credentials", id: result.id }] : [],
		}),
		createClientUserCredential: builder.mutation<
			Credential,
			{ id: number; userid: number; cred: Credential }
		>({
			query: (params) => ({
				url: `clients/${params.id}/users/${params.userid}/credentials`,
				method: "POST",
				body: params.cred,
			}),
			invalidatesTags: (result) =>
				result
					? [
							{ type: "Credentials", id: "LIST" },
							{
								type: "Users",
								id: result.user_id,
							},
					  ]
					: [],
		}),
		updateClientUserCredential: builder.mutation<
			Credential,
			{ id: number; userid: number; cred: Credential }
		>({
			query: (params) => ({
				url: `clients/${params.id}/users/${params.userid}/credentials/${params.cred.id}`,
				method: "PUT",
				body: params.cred,
			}),
			invalidatesTags: (result) =>
				result
					? [
							{ type: "Credentials", id: result.id },
							{
								type: "Users",
								id: result.user_id,
							},
					  ]
					: [],
		}),
		deleteClientUserCredential: builder.mutation<
			Credential,
			{ id: number; userid: number; credid: number }
		>({
			query: (params) => ({
				url: `clients/${params.id}/users/${params.userid}/credentials/${params.credid}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, _, query) => [
				{ type: "Credentials", id: query.credid },
				{
					type: "Users",
					id: query.userid,
				},
			],
		}),
		getTickets: builder.query<Ticket[], number>({
			query: () => `tickets`,
			transformResponse: (res: Ticket[]) =>
				res.sort((a, b) => {
					if (a.status === b.status) {
						return a.created_at > b.created_at ? -1 : 1;
					}
					return -1 * a.status.localeCompare(b.status);
				}),
			providesTags: (result, error) =>
				result
					? [
							...result.map(
								({ id }) =>
									({
										type: "Tickets",
										id,
									} as const)
							),
							{ type: "Tickets", id: "LIST" },
					  ]
					: [{ type: "Tickets", id: "LIST" }],
			async onCacheEntryAdded(
				arg,
				{ cacheDataLoaded, cacheEntryRemoved, updateCachedData }
			) {
				let channel = window.pusher?.subscribe(`private-tickets_${arg}`);
				channel?.bind("ticket-created", (data: Ticket) => {
					updateCachedData((draft) => {
						draft.splice(0, 0, data);
						draft.sort((a, b) => {
							if (a.status === b.status) {
								return a.created_at > b.created_at ? -1 : 1;
							}
							return -1 * a.status.localeCompare(b.status);
						})
					});
				});
				channel?.bind("ticket-updated", (data: Ticket) => {
					updateCachedData((draft) => {
						const index = draft.findIndex((ticket) => ticket.id === data.id);
						if (index !== -1) {
							draft[index] = data;
							draft.sort((a, b) => {
								if (a.status === b.status) {
									return a.created_at > b.created_at ? -1 : 1;
								}
								return -1 * a.status.localeCompare(b.status);
							})
						}
					});
				});
				channel?.bind("message-created", (data: Message) => {
					updateCachedData((draft) => {
						const index = draft.findIndex(
							(ticket) => ticket.channel_id === data.channel_id
						);
						if (index !== -1) {
							draft[index].messages.push(data);
						}
						const audio = new Audio("/notif_sound.mp3");
						audio.play();
					});
				});
			},
		}),
		getTicket: builder.query<Ticket, number>({
			query: (id) => `tickets/${id}`,
			providesTags: (result) =>
				result ? [{ type: "Tickets", id: result.id }] : [],
				async onCacheEntryAdded(
                    arg,
                    { cacheDataLoaded, cacheEntryRemoved, updateCachedData }
                ) {
                    let channel = window.pusher?.subscribe(`private-tickets_${arg}`);
					console.log("asdasdasd")
                    channel?.bind("ticket-updated", (data: Ticket) => {
                        updateCachedData((draft) => {
							console.log(draft);
							console.log(data);
                            // const index = draft.findIndex((ticket) => ticket.id === data.id);
                            if (draft.id !== data.id) {
                                draft = data;
                            }
                        });
                    });
                },
			keepUnusedDataFor: Infinity,
		}),
		updateTicket: builder.mutation<Ticket, Ticket>({
			query: (ticket) => ({
				url: `tickets/${ticket.id}`,
				method: "PUT",
				body: ticket,
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "Tickets", id: result.id }] : [],
		}),
		getMessages: builder.query<Message[], Channel>({
			query: (channel) => `channels/${channel?.id}/messages`,
			transformResponse: (res: Message[]) => {
				res.sort((a, b) => {
					return a.created_at! > b.created_at! ? 1 : -1;
				});
				return res;
			},
			providesTags: (result, error, arg) =>
				result
					? [
							...result.map(
								({ id }) =>
									({
										type: "Messages",
										id,
									} as const)
							),
							{ type: "Messages", id: "LIST" },
					  ]
					: [{ type: "Messages", id: "LIST" }],
			async onCacheEntryAdded(
				channel,
				{ cacheDataLoaded, cacheEntryRemoved, updateCachedData }
			) {
				let pusherChannel = window.pusher?.subscribe(channel.name);
				pusherChannel?.bind("message-created", (data: Message) => {
					updateCachedData((draft) => {
						const m = draft.find((message) => message.id === data.id);
						if (!m) {
							draft.push(data);
						}
					});
				});
			},
		}),
		createMessage: builder.mutation<Message, { id: number; message: Message }>({
			query: (params) => ({
				url: `channels/${params.id}/messages`,
				method: "POST",
				body: params.message,
			}),
			invalidatesTags: (result) =>
				result
					? [
							{ type: "Messages", id: result.id },
							{ type: "Messages", id: "LIST" },
					  ]
					: [],
		}),
		updateMessage: builder.mutation<Message, Message>({
			query: (message) => ({
				url: `channels/${message.channel_id}/messages/${message.id}`,
				method: "PUT",
				body: message,
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "Messages", id: result.id }] : [],
		}),
		getSupportRoles: builder.query<Role[], void>({
			query: () => `support-roles`,
			transformResponse: (res: Role[]) =>
				res.sort((a, b) => a.name.localeCompare(b.name)),
		}),
		getAgents: builder.query<Agent[], void>({
			query: () => `agents`,
			transformResponse: (res: Agent[]) =>
				res.sort((a, b) => {
					if (a.client_id === b.client_id) {
						return a.valid_from! > b.valid_from! ? -1 : 1;
					}
					return a.client_id - b.client_id;
				}),
			providesTags: (result, error) =>
				result
					? [
							...result.map(({ id }) => ({ type: "Agents", id } as const)),
							{ type: "Agents", id: "LIST" },
					  ]
					: [{ type: "Agents", id: "LIST" }],
		}),
		createAgent: builder.mutation<Agent, Agent>({
			query: (agent) => ({
				url: `agents`,
				method: "POST",
				body: agent,
			}),
			invalidatesTags: (result) =>
				result
					? [
							{ type: "Agents", id: result.id },
							{ type: "Agents", id: "LIST" },
					  ]
					: [],
		}),
		updateAgent: builder.mutation<Agent, Agent>({
			query: (agent) => ({
				url: `agents/${agent.id}`,
				method: "PUT",
				body: agent,
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "Agents", id: result.id }] : [],
		}),
		deleteAgent: builder.mutation<Agent, number>({
			query: (id) => ({
				url: `agents/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, _, query) => [{ type: "Agents", id: query }],
		}),
		uploadFile: builder.mutation<string, File>({
			query: (file) => ({
				url: `files`,
				method: "POST",
				body: createMultipartFormData({ file }),
			}),
			transformResponse: (res: any) => {
				return res.file;
			},
		}),
		createIssue: builder.mutation<Issue, Issue>({
			query: (issue) => ({
				url: `issues`,
				method: "POST",
				body: issue,
			}),
			invalidatesTags: (result) =>
				result
					? [
							{ type: "Issues", id: result.id },
							{ type: "Issues", id: "LIST" },
					  ]
					: [{ type: "Issues", id: "LIST" }],
		}),
		updateIssue: builder.mutation<Issue, Issue>({
			query: (issue) => ({
				url: `issues/${issue.id}`,
				method: "PUT",
				body: issue,
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "Issues", id: result.id }] : [],
		}),
		getIssues: builder.query<Issue[], number>({
			query: () => `issues`,
			transformResponse: (res: Issue[]) =>
				res.sort((a, b) => {
					// if (a.id === b.id) {
					// 	return a.priority_level! > b.priority_level! ? -1 : 1;
					// }
					// return b.id! - a.id!;
					if (a.priority_level === b.priority_level){
						return a?.created_at! > b?.created_at! ? -1 : 1
					}
					return a.priority_level! > b.priority_level! ? -1 : 1
				}),
			providesTags: (result, error) =>
				result
					? [
							...result.map(({ id }) => ({ type: "Issues", id } as const)),
							{ type: "Issues", id: "LIST" },
					  ]
					: [{ type: "Issues", id: "LIST" }],
					async onCacheEntryAdded(
						arg,
						{ cacheDataLoaded, cacheEntryRemoved, updateCachedData }
					) {
						let channel = window.pusher?.subscribe(`private-issues_${arg}`);
						channel?.bind("issue-created", (data: Issue) => {
							updateCachedData((draft) => {
								console.log(data)
								draft.splice(0, 0, data);
							});
							const audio = new Audio("/notif_sound.mp3");
							audio.play();
						});
						channel?.bind("issue-updated", (data: Issue) => {
							updateCachedData((draft) => {
								console.log(data)
								const index = draft.findIndex((issue) => issue.id === data.id);
								if (index !== -1) {
									console.log(data)
									draft[index] = data;
								}
							});
						});
					},
		}),
		getIssue: builder.query<Issue, number>({
			query: (id) => `issues/${id}`,
			providesTags: (result) =>
				result ? [{ type: "Issues", id: result.id }] : [],
			keepUnusedDataFor: Infinity,
		}),
		getIssueTicket: builder.query<Issue, number>({
			query: (id) => `issues?ticketID=${id}`,
			providesTags: (result) =>
				result ? [{ type: "Issues", id: result.id }] : [],
			keepUnusedDataFor: Infinity,
		}),
		getKnowledgeBases: builder.query<KnowledgeBase[], void>({
			query: () => `knowledgebase`,
			transformResponse: (res: KnowledgeBase[]) =>
				res.sort((a, b) => {
					if (a.id === b.id) {
						return a.created_at! > b.created_at! ? -1 : 1;
					}
					return a.id! - b.id!;
				}),
			providesTags: (result, error) =>
				result
					? [
							...result.map(
								({ id }) => ({ type: "KnowledgeBase", id } as const)
							),
							{ type: "KnowledgeBase", id: "LIST" },
					  ]
					: [{ type: "KnowledgeBase", id: "LIST" }],
		}),
		createKnowledgeBase: builder.mutation<
			KnowledgeBase,
			{ issue_type: string; topic: string; solution: string }
		>({
			query: (knowledgebase) => ({
				url: `knowledgebase`,
				method: "POST",
				body: knowledgebase,
			}),
			invalidatesTags: (result) =>
				result
					? [
							{ type: "KnowledgeBase", id: result.id },
							{ type: "KnowledgeBase", id: "LIST" },
					  ]
					: [{ type: "KnowledgeBase", id: "LIST" }],
		}),
		updateKnowledgeBase: builder.mutation<KnowledgeBase, KnowledgeBase>({
			query: (knowledgebase) => ({
				url: `knowledgebase/${knowledgebase.id}`,
				method: "PUT",
				body: knowledgebase,
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "KnowledgeBase", id: result.id }] : [],
		}),
		deleteKnowledgeBase: builder.mutation<KnowledgeBase, number>({
			query: (id) => ({
				url: `knowledgebase/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, _, query) => [
				{ type: "KnowledgeBase", id: query },
			],
		}),
		getAddons: builder.query<Addon[], void>({
			query: () => `addons`,
			providesTags: (result, error) =>result
			? [
					...result.map(
						({ id }) => ({ type: "Addons", id } as const)
					),
					{ type: "Addons", id: "LIST" },
			  ]
			: [{ type: "Addons", id: "LIST" }],
		}),
		createAddon: builder.mutation<Addon, Addon>({
			query: (addon) => ({
				url: `addons`,
				method: "POST",
				body: addon,
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "Addons", id: result.id }] : [],
		}),
		updateAddon: builder.mutation<Addon, Addon>({
			query: (addon) => ({
				url: `addons/${addon.id}`,
				method: "PUT",
				body: addon,
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "Addons", id: result.id }] : [],
		}),
		deleteAddon: builder.mutation<void, number>({
			query: (id) => ({
				url: `addons/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, _, query) => [
				{ type: "Addons", id: query },
			],
		}),
		getAddonRoutes: builder.query<AddonRoute[], number>({
			query: (id) => `addons/${id}/routes`,
			transformResponse: (res: AddonRoute[]) =>
				res.sort((a, b) => {
					return a.sequence! > b.sequence! ? 1 : -1;
				}),
			providesTags: (result, error) =>result
			? [
					...result.map(
						({ id }) => ({ type: "AddonRoute", id } as const)
					),
					{ type: "AddonRoute", id: "LIST" },
			  ]
			: [{ type: "AddonRoute", id: "LIST" }],
		}),
		createAddonRoute: builder.mutation<AddonRoute, AddonRoute>({
			query: (addonRoute) => ({
				url: `addons/${addonRoute.addon_id}/routes`,
				method: "POST",
				body: addonRoute,
			}),
			invalidatesTags: (result) => 
				result ? [{ type: "AddonRoute", id: "LIST" }] : [],
		}),
		updateAddonRoute: builder.mutation<AddonRoute, AddonRoute>({
			query: (addonRoute) => ({
				url: `addons/${addonRoute.addon_id}/routes/${addonRoute.id}`,
				method: "PUT",
				body: addonRoute,
			}),
			invalidatesTags: (result) =>
				result ? [{ type: "AddonRoute", id: result.id }] : [],
		}),
		deleteAddonRoute: builder.mutation<void, {addon_id : number, id: number}>({
			query: (params) => ({
				url: `addons/${params.addon_id}/routes/${params.id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, _, query) => [
				{ type: "AddonRoute", id: query.id },
			],
		}),
	}),
});

function createMultipartFormData(data: any) {
	const formData = new FormData();
	for (const key of Object.keys(data)) {
		formData.append(key, data[key]);
	}
	return formData;
}

export const {
	useGetRolesQuery,
	useGetSupportRolesQuery,
	useGetUsersQuery,
	useGetUserQuery,
	useCreateUserMutation,
	useUpdateUserMutation,
	useDeleteUserMutation,
	useGetClientsQuery,
	useGetClientQuery,
	useCreateClientMutation,
	useUpdateClientMutation,
	useDeleteClientMutation,
	useGetApplicationsQuery,
	useGetApplicationQuery,
	useLazyGetApplicationQuery,
	useLazyGetApplicationConfigurationQuery,
	useCreateApplicationConfigurationMutation,
	useUpdateApplicationConfigurationMutation,
	useDeleteApplicationConfigurationMutation,
	useCreateApplicationMutation,
	useUpdateApplicationMutation,
	useDeleteApplicationMutation,
	useActionApplicationMutation,
	useLazyGetApplicationsStatisticQuery,
	useGetApplicationsStatisticQuery,
	useGetClientUsersQuery,
	useGetClientUserQuery,
	useCreateClientUserMutation,
	useUpdateClientUserMutation,
	useDeleteClientUserMutation,
	useLazyGetClientUserCredentialsQuery,
	useGetClientUserCredentialsQuery,
	useGetClientUserCredentialQuery,
	useCreateClientUserCredentialMutation,
	useUpdateClientUserCredentialMutation,
	useDeleteClientUserCredentialMutation,
	useGetTicketsQuery,
	useLazyGetTicketQuery,
	useUpdateTicketMutation,
	useLazyGetMessagesQuery,
	useCreateMessageMutation,
	useGetAgentsQuery,
	useCreateAgentMutation,
	useUpdateAgentMutation,
	useUploadFileMutation,
	useDeleteAgentMutation,
	useGetIssuesQuery,
	useLazyGetIssueQuery,
	useUpdateIssueMutation,
	useCreateIssueMutation,
	useGetKnowledgeBasesQuery,
	useCreateKnowledgeBaseMutation,
	useUpdateKnowledgeBaseMutation,
	useDeleteKnowledgeBaseMutation,
	useUpdateMessageMutation,
	useLazyGetUserQuery,
	useGetAddonsQuery,
	useCreateAddonMutation,
	useUpdateAddonMutation,
	useDeleteAddonMutation,
	useLazyGetAddonRoutesQuery,
	useCreateAddonRouteMutation,
	useUpdateAddonRouteMutation,
	useDeleteAddonRouteMutation,
} = API;
