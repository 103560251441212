import Header from "../../components/Header";
import React, {useEffect, useState} from "react";
import Api from "../../axios/Api";
import Swal from "sweetalert2";
import {useNavigate} from "react-router";
import Button from "../../components/Button";

export default function Dashboard() {
    const [clients, setClients] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const navigate = useNavigate();
    const [clientLoading, setClientLoading] = useState<boolean>(true);
    const [userLoading, setUserLoading] = useState<boolean>(true);

    useEffect(() => {
        loadClients();
        loadUsers();
    }, [])
    const loadClients = async () => {
        setClients([])
        try {
            const res = await Api.getClient().get("/api/clients");
            if (res.status === 200 && res.data.length > 0) {
                setClients(res.data)
                setClientLoading(false)
            }
        } catch (e: any) {
            console.log(e)
        }
    }

    const loadUsers = async () => {
        setUsers([])
        try {
            const res = await Api.getClient().get("/api/users");
            if (res.status === 200 && res.data.length > 0) {
                setUsers(res.data)
                setUserLoading(false)
            }
        } catch (e: any) {
            console.log(e)
        }
    }

    if (clientLoading || userLoading){
        return (
            <div className="flex justify-center items-center h-[90%]">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"/>
            </div>
        )
    }

    return (
        <>
            <div className="flex flex-1 w-full mx-auto">
                <div className="flex flex-col flex-1">
                    <div className="p-4 m-4 rounded bg-white flex flex-col">
                        <div className="text-2xl mx-auto">Client</div>
                        <div className="flex flex-1 flex-col mt-4">
                            {clients.length > 0 ? clients.slice(0, 5).map((client, index) => (
                                <div key={index} className="flex flex-1">
                                    <div className="text-lg">{client.name}</div>
                                    <Button
                                        onClick={() => navigate(`/blackops/clients/${client.id}`)}
                                        className="ml-auto box-border p-2 transition-all transition cursor-pointer">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor"
                                             className="w-6 h-6 text-[#472183] hover:text-[#5040a0]">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"/>
                                        </svg>
                                    </Button>
                                </div>
                            )) : <div className="text-xl mx-auto">No Client</div>}
                            <a className="ml-auto cursor-pointer mt-2" onClick={() => navigate("/blackops/clients")}>See
                                all...</a>
                        </div>
                    </div>
                    <div className="p-4 m-4 rounded bg-white flex flex-col">
                        <div className="text-2xl mx-auto">User</div>
                        <div className="flex flex-1 flex-col mt-4">
                            {users.length > 0 ? users.filter((user)=>user.role_id != 1).slice(0, 5).map((user, index) => {
                                return (
                                    <div key={index} className="flex flex-1">
                                        <div className="text-lg">{user.name}</div>
                                        <Button onClick={() => navigate(`/blackops/users/${user.id}`)}
                                                className="ml-auto box-border p-2 transition-all transition cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth={1.5} stroke="currentColor"
                                                 className="w-6 h-6 text-[#472183] hover:text-[#5040a0]">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"/>
                                            </svg>
                                        </Button>
                                    </div>
                                )
                            }) : <div className="text-xl mx-auto">No User</div>}
                            <a className="ml-auto cursor-pointer mt-2" onClick={() => navigate("/blackops/users")}>See all...</a>
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <div className="p-12 m-4 bg-white">On Development</div>
                </div>
            </div>
        </>
    )
}