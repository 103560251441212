import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import { RegistrationState } from "@pusher/push-notifications-web";
import Swal from "sweetalert2";
import { loadState, saveState } from "../storage";
import useAuth from "../auth/useAuth";
import { beamsTokenProvider } from "../utils/util";
import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { getFirebaseToken } from "../services/firebase";
import { useLazyGetUserQuery, useUpdateMessageMutation, useUpdateUserMutation } from "../services/API";
export default function Setting() {
	// const [loading, setLoading] = useState(true);
	const [isNotification, setIsNotification] = useState(true);
	const [token, setToken] = useState("")
	const [
		updateUser
	] = useUpdateUserMutation();
	const [
		loadUser
	] = useLazyGetUserQuery();
	const auth = useAuth();

	useEffect(() => {
		if ("Notification" in window){
			let permission = Notification.requestPermission().then((p) => {
				if (p === "granted"){
					getFirebaseToken()
					.then((firebaseToken) => {
					//   console.log('Firebase token: ', firebaseToken);
					  if (firebaseToken) {
						  setToken(firebaseToken)
						  loadUser(auth?.user?.id!).unwrap().then((user) => {
							  if (user){
								  if (user?.tokens?.includes(firebaseToken)){
									  setIsNotification(true)
								  } else {
									  setIsNotification(false)
								  }
							  }	
						  })
					  //   setShowNotificationBanner(false);
					  }
					})
					.catch((err) => console.error('An error occured while retrieving firebase token. ', err))				}
			})
		}
	}, [])

	// useEffect(() => {
	// 	setLoading(true);
	// 	if(Notification.permission === "granted"){
	// 		window.pusherBeams?.start().then(() => {
	// 			window.pusherBeams?.getRegistrationState().then((state: RegistrationState) => {
	// 				setIsNotification(RegistrationState.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS === state);
	// 				setLoading(false);
	// 			});
	// 		});
	// 	}else{
	// 		setLoading(false);
	// 	}
	// }, []);

	const clearNotification =async () => {
		if(Notification.permission === "granted"){			
			await window.pusherBeams?.clearAllState()
			await window.pusherBeams?.stop()
		}
	}

	const handleGetFirebaseToken = () => {
		getFirebaseToken()
		  .then((firebaseToken) => {
			// console.log('Firebase token: ', firebaseToken);
			if (firebaseToken) {
				setToken(firebaseToken)
				loadUser(auth?.user?.id!).unwrap().then((user) => {
					if (user){
						if (!user?.tokens?.includes(firebaseToken)){
							updateUser({
								...user,
								tokens: [...user.tokens!,
								firebaseToken!]
							})
						}
					}	
				})
			//   setShowNotificationBanner(false);
			}
		  })
		  .catch((err) => console.error('An error occured while retrieving firebase token. ', err))
	  }

	  const handleRemoveFirebaseToken = () => {
		getFirebaseToken()
		  .then((firebaseToken) => {
			console.log('Firebase token: ', firebaseToken);
			if (firebaseToken) {
				setToken(firebaseToken)
				loadUser(auth?.user?.id!).unwrap().then((user) => {
					if (user){
						updateUser({
							...user,
							tokens: [...user.tokens!.filter((t) => t !== firebaseToken)]
						})
					}
				})
			//   setShowNotificationBanner(false);
			}
		  })
		  .catch((err) => console.error('An error occured while retrieving firebase token. ', err))
	  }


	useEffect(() => {
		if (isNotification){
			if ("Notification" in window){
				let permission = Notification.requestPermission().then((p) => {
					if (p === "granted"){
						handleGetFirebaseToken();
					}
				})
			}
		} else {
			handleRemoveFirebaseToken();
		}
	}, [isNotification]);

	return (
		<div className="flex flex-col p-12 items-center ">
			<div className="flex flex-1 w-[50%] justify-center items-center py-4 bg-white p-4 rounded-xl">
				<div className="text-texttitle font-medium flex-1">Notification</div>
				{(
					<label className="relative inline-flex items-center cursor-pointer">
						<input
							type="checkbox"
							className="sr-only peer"
							checked={isNotification}
							onChange={(e) =>
								setIsNotification(e.target.checked)
							}
						/>
						<div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-ungutext"></div>
					</label>
				)}
			</div>
		</div>
	);
}
