import Header from "../../components/Header";
import {
	useCreateAgentMutation,
	useDeleteAgentMutation,
	useGetAgentsQuery,
	useGetClientsQuery,
	useGetUsersQuery,
	useUpdateAgentMutation,
} from "../../services/API";
import {
	createColumnHelper,
	FilterFn,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	RowSelectionState,
	SortingState,
	useReactTable,
} from "@tanstack/react-table";
import { Agent, Client } from "../../services/types";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import moment, { invalid } from "moment";
import { Dialog, Transition } from "@headlessui/react";
import "tw-elements";
import Swal from "sweetalert2";
import { rankItem } from "@tanstack/match-sorter-utils";
import Button from "../../components/Button";
import BaseTable, { CustomColumn } from "../../components/BaseTable";
import { omit } from "../../utils/util";

export default function SupportAgentPage() {
	const [agent, setAgent] = useState<Agent | null>(null);
	const {
		data: agents,
		error: agentsError,
		isLoading: agentsIsLoading,
	} = useGetAgentsQuery();
	const {
		data: users,
		error: usersError,
		isLoading: usersIsLoading,
	} = useGetUsersQuery();
	const {
		data: clients,
		error: clientsError,
		isLoading: clientsIsLoading,
	} = useGetClientsQuery();
	const [
		createAgent,
		{ error: createAgentError, isLoading: createAgentIsLoading },
	] = useCreateAgentMutation();
	const [
		updateAgent,
		{ error: updateAgentError, isLoading: updateAgentIsLoading },
	] = useUpdateAgentMutation();
	const [
		deleteAgent,
		{ error: deleteAgentError, isLoading: deleteAgentIsLoading },
	] = useDeleteAgentMutation();

	const generateLogo = (agent: Agent) => {
		if (agent.client?.logo_url?.length! > 0)
			return process.env.REACT_APP_API_URL + "/files/" + agent.client?.logo_url;
		let abbreviation = "";
		agent.client?.name.split(" ").forEach((word: string) => {
			if (word.indexOf("PT.") > -1) return;
			abbreviation += word[0];
		});
		abbreviation = abbreviation.toUpperCase();
		return (
			"https://ui-avatars.com/api/?name=" +
			abbreviation +
			"&background=472183&color=ffffff&length=" +
			abbreviation.length
		);
	};

	const generatePicture = (agent: Agent) => {
		// let user = users?.find((u) => u.name === agent.user?.name);
		if (agent.user?.profile_picture && agent.user?.profile_picture?.length! > 0)
			return (
				process.env.REACT_APP_API_URL + "/files/" + agent.user.profile_picture
			);
		let abbreviation = "";
		agent.user?.name.split(" ").forEach((word: string) => {
			abbreviation += word[0];
		});
		abbreviation = abbreviation.toUpperCase();
		return (
			"https://ui-avatars.com/api/?name=" +
			abbreviation +
			"&background=472183&color=ffffff&length=" +
			abbreviation.length
		);
	};

	const isValid = (agent: Agent) => {
		if (
			agent.valid_from?.toString().length! > 0 &&
			agent.valid_to?.toString().length! > 0 &&
			moment(agent.valid_to!).format("yyyy-MM-DD hh:mm:ss") >=
				moment(new Date()).format("yyyy-MM-DD hh:mm:ss")
		) {
			return "Yes";
		} else {
			return "No";
		}
	};

	const columnHelper = createColumnHelper<any>();
	const [selectState, setSelectState] = useState<RowSelectionState>({});
	const [selectedRow, setSelectedRow] = useState<number>(-1);
	const customColumns = useMemo(
		() => [
			{
				column: columnHelper.accessor("client.name", {
					header: "Client",
					size: Infinity,
					cell: (props) => {
						return (
							<div className="flex items-center">
								<img
									src={generateLogo(props.row.original)}
									className="w-8 h-8 mr-2"
								/>
								<div>{props.getValue()}</div>
							</div>
						);
					},
				}),
				position: 2,
			},
			{
				column: columnHelper.accessor("user.name", {
					header: "Name",
					size: Infinity,
					cell: (props) => {
						return (
							<div className="flex items-center">
								<img
									src={generatePicture(props.row.original)}
									className="w-8 h-8 mr-2"
								/>
								<div>{props.getValue()}</div>
							</div>
						);
					},
				}),
				position: 3,
			},
			{
				column: columnHelper.accessor("valid_from", {
					header: "Valid From",
					size: Infinity,
					cell: (props) => {
						return (
							<div>
								{props.getValue() !== null
									? moment(props.getValue()).format("yyyy-MM-DD hh:mm:ss")
									: ""}
							</div>
						);
					},
				}),
				position: 5,
			},
			{
				column: columnHelper.accessor("valid_to", {
					header: "Valid To",
					size: Infinity,
					cell: (props) => {
						return (
							<div>
								{props.getValue() !== null
									? moment(props.getValue()).format("yyyy-MM-DD hh:mm:ss")
									: ""}
							</div>
						);
					},
				}),
				position: 6,
			},
			{
				column: columnHelper.accessor("is_valid", {
					header: "Is Valid",
					size: Infinity,
					cell: (props) => {
						if (props.getValue() === true) {
							return (
								<div className="flex items-center">
									<div>Yes</div>
								</div>
							);
						}
						return (
							<div className="flex items-center">
								<div>No</div>
							</div>
						);
					},
				}),
				position: 7,
			},
		],
		[columnHelper]
	);
	// const customColumns: CustomColumn[] = [];

	const [actionType, setActionType] = useState<string>();
	const [isAddOrEditAgent, setIsAddOrEditAgent] = useState<boolean>(false);
	const cancelAddOrEditAgent = useRef<HTMLButtonElement>(null);

	const [isDeletingAgent, setIsDeletingAgent] = useState<boolean>(false);
	const cancelDeletingUser = useRef<HTMLButtonElement>(null);

	const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);

	const [editAgent, setEditAgent] = useState<Agent | null>(null);
	const [userId, setUserId] = useState<number>(-1);
	const [clientId, setClientId] = useState<number>(-1);
	const [validFrom, setValidFrom] = useState<Date>(new Date());
	const [validTo, setValidTo] = useState<Date | null>(null);
	const [isForever, setIsForever] = useState(false);

	const [clientAgents, setClientAgents] = useState<any[]>([]);
	const [userAgents, setUserAgents] = useState<any[]>([]);

	const loadClientAgents = async () => {
		setClientAgents(clients!);
	};

	const loadUserAgents = async () => {
		setUserAgents(users!);
	};

	useEffect(() => {
		if (actionType) {
			loadClientAgents();
			loadUserAgents();
		}
	}, [actionType]);

	const dateUtcToLocal = (date: Date) => {
		if (!date) return null;
		return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
	};

	const editingAgent = async (agentId: number) => {
		let ag = agents?.find((agent) => agent.id === agentId)!;
		setAgent(ag);
		setUserId(ag.user_id);
		setClientId(ag.client_id);
		if (ag.valid_from !== undefined) setValidFrom(ag.valid_from);
		if (ag.valid_to !== undefined) {
			setValidTo(ag.valid_to);
			setIsForever(true);
		}
		setActionType("Edit ");
		setIsAddOrEditAgent(true);
	};

	const deletingAgent = (agentId: number) => {
		if (isDeletingAgent) {
			deleteAgent(agentId)
				.unwrap()
				.then((r) => {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "success",
						title: "Agent has been deleted",
						timer: 3000,
						showConfirmButton: false,
					});
				});
			setIsDeletingAgent(false);
		} else {
			setIsDeletingAgent(true);
		}
	};

	const save = async () => {
		if (!userId || !clientId || !validFrom) {
			return;
		}
		if (agent === null) {
			createAgent({
				id: 0,
				user_id: userId,
				client_id: clientId,
				valid_from: validFrom,
				valid_to: validTo ? validTo : undefined,
			});
		} else {
			let uAgent = omit(agent, ["user", "client"]);
			console.log(uAgent);
			updateAgent({
				...uAgent,
				user_id: userId,
				client_id: clientId,
				valid_from: validFrom,
				valid_to: validTo ? validTo : undefined,
			});
		}
		setIsAddOrEditAgent(false);
		Swal.fire({
			toast: true,
			position: "top-end",
			icon: "success",
			title: "Agent saved successfully",
			showConfirmButton: false,
			timer: 3000,
		});
		clearData();
	};

	const clearData = () => {
		setAgent(null);
		setUserId(0);
		setClientId(0);
		setValidFrom(new Date());
		setValidTo(null);
		setEditAgent(null);
	};

	const handleSavingAgent = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (validFrom !== null) {
			if (agents![selectedRow] !== null) {
				setEditAgent(agents![selectedRow]);
			}
			save();
		}
	};

	if (agentsIsLoading) {
		return (
			<div className="flex justify-center items-center h-[90%]">
				<div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900" />
			</div>
		);
	}
	return (
		<>
			<div className="flex flex-col w-full mx-auto h-full">
				<BaseTable
					data={agents!}
					columns={["support role:user.support_role.name"]}
					customColumns={customColumns}
					onRowSelectChange={setSelectState}
					onRowSelect={setSelectedRow}
					useActions={true}
					useEdit={true}
					useDelete={true}
					addFunction={() => {
						setActionType("Add ");
						clearData();
						setIsAddOrEditAgent(true);
					}}
					editFunction={editingAgent}
					deleteFunction={deleteAgent}
					deleteRow={deletingAgent}
				/>
			</div>
			<Transition.Root show={isAddOrEditAgent} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={cancelAddOrEditAgent}
					onClose={() => {}}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-opacity-75 transition-opacity" />
					</Transition.Child>
					<div className="fixed inset-0 bg-black/50" aria-hidden="true" />

					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							{/* This element is to trick the browser into centering the modal contents. */}
							<span
								className="hidden sm:inline-block sm:align-middle sm:h-screen"
								aria-hidden="true"
							>
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className=" inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
									<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
										<div className="sm:flex sm:items-start">
											<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
												<Dialog.Title
													as="h1"
													className="leading-6 text-textmenu text-black text-center font-bold font-montserrat"
												>
													{actionType} Agent
												</Dialog.Title>
												<Dialog.Title
													as="h3"
													className="text-texttitle leading-4 text-xl font-bold text-black mt-6 font-montserrat"
												>
													Company Info
													<hr className="mt-3 mb-1"></hr>
												</Dialog.Title>
												<form
													onSubmit={(e) => {
														handleSavingAgent(e);
													}}
												>
													<div className="mt-2">
														<div className="flex-col items-center space-y-1">
															<div className="text-textnormal leading-5 font-medium text-gray-900 flex-1">
																Client
															</div>
															<select
																className="bg-bgfield text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 border-none outline-none"
																value={clientId}
																onChange={(e) =>
																	setClientId(parseInt(e.target.value))
																}
															>
																<option value={0} disabled hidden>
																	Select Client
																</option>
																{clientAgents &&
																	clientAgents.map((client) => (
																		<option key={client.id} value={client.id}>
																			{client.name}
																		</option>
																	))}
															</select>

															<div className="text-textnormal leading-5 font-medium text-gray-900 flex-1">
																Agent
															</div>
															<select
																className="bg-bgfield text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 border-none outline-none"
																value={userId}
																onChange={(e) =>
																	setUserId(parseInt(e.target.value))
																}
															>
																<option value={0} disabled hidden>
																	Select User
																</option>
																{userAgents &&
																	userAgents.map((user) => (
																		<option key={user.id} value={user.id}>
																			{user.name}
																		</option>
																	))}
															</select>
															<div className="flex flex-row space-x-12">
																<div className="flex-1">
																	<div className="text-textnormal leading-5 font-medium text-gray-900 flex-1">
																		Valid From*
																	</div>
																	<input
																		type="datetime-local"
																		required
																		value={moment(validFrom).format(
																			"yyyy-MM-DD hh:mm:ss"
																		)}
																		className="bg-bgfield text-ungutext text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 focus:blue-500 focus:border-blue-500 border-none outline-none"
																		onChange={(e) =>
																			setValidFrom(new Date(e.target.value))
																		}
																	/>
																</div>
																<div className="flex-1 flex flex-col">
																	<div className="text-textnormal leading-5 font-medium text-gray-900 flex-1">
																		Valid To
																	</div>
																	<input
																		type="datetime-local"
																		value={
																			isForever
																				? moment(null).format(
																						"yyyy-MM-DD hh:mm:ss"
																				  )
																				: moment(validTo).format(
																						"yyyy-MM-DD hh:mm:ss"
																				  )
																		}
																		className="bg-bgfield text-ungutext text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 focus:blue-500 focus:border-blue-500 border-none outline-none"
																		onChange={(e) =>
																			setValidTo(new Date(e.target.value))
																		}
																	/>
																	<div className="flex flex-row space-x-2 pt-3">
																		<label className="text-textnormal leading-5 font-medium text-gray-900 flex-initial w-3/6">
																			Valid Forever
																		</label>
																		<div className="flex-1">
																			<label className="relative inline-flex items-center mb-5 cursor-pointer">
																				<input
																					type="checkbox"
																					className="sr-only peer"
																					checked={isForever}
																					onChange={(e) =>
																						setIsForever(e.target.checked)
																					}
																				/>
																				<div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-ungutext"></div>
																			</label>
																		</div>
																	</div>
																</div>
															</div>
															<div className="flex justify-center space-x-6 pt-3">
																<Button
																	type="button"
																	className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-lg text-textnormal px-5 py-2.5"
																	onClick={() => {
																		setIsAddOrEditAgent(false);
																	}}
																	ref={cancelAddOrEditAgent}
																>
																	Cancel
																</Button>
																<Button
																	type="submit"
																	className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-lg text-textnormal px-5 py-2.5"
																>
																	Save
																</Button>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			{/* <Transition.Root appear show={isAddOrEditAgent} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={cancelAddOrEditAgent}
					onClose={() => {}}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl px-4 pt-5 pb-4 sm:p-6 sm:pb-4 sm:flex sm:items-start bg-white text-left align-middle shadow-xl transition-all">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
										<Dialog.Title
											as="h1"
											className="leading-6 text-textmenu text-black text-center font-bold font-montserrat"
										>
											{actionType} Agent
										</Dialog.Title>

										<form
											onSubmit={(e) => {
												handleSavingAgent(e);
											}}
										>
											<div className="mt-2">
												<div className="flex-col items-center space-y-1">
													<div className="text-textnormal leading-5 font-medium text-gray-900 flex-1">
														Client
													</div>
													<select
														className="bg-bgfield text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 border-none outline-none"
														value={clientId}
														onChange={(e) =>
															setClientId(parseInt(e.target.value))
														}
													>
														<option value={0} disabled hidden>
															Select Client
														</option>
														{clientAgents &&
															clientAgents.map((client) => (
																<option key={client.id} value={client.id}>
																	{client.name}
																</option>
															))}
													</select>

													<div className="text-textnormal leading-5 font-medium text-gray-900 flex-1">
														Agent
													</div>
													<select
														className="bg-bgfield text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 border-none outline-none"
														value={userId}
														onChange={(e) =>
															setUserId(parseInt(e.target.value))
														}
													>
														<option value={0} disabled hidden>
															Select User
														</option>
														{userAgents &&
															userAgents.map((user) => (
																<option key={user.id} value={user.id}>
																	{user.name}
																</option>
															))}
													</select>
													<div className="flex flex-row space-x-12">
														<div className="flex-1">
															<div className="text-textnormal leading-5 font-medium text-gray-900 flex-1">
																Valid From*
															</div>
															<input
																type="datetime-local"
																required
																value={moment(validFrom).format(
																	"yyyy-MM-DD hh:mm:ss"
																)}
																className="bg-bgfield text-ungutext text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 focus:blue-500 focus:border-blue-500 border-none outline-none"
																onChange={(e) =>
																	setValidFrom(new Date(e.target.value))
																}
															/>
														</div>
														<div className="flex-1">
															<div className="text-textnormal leading-5 font-medium text-gray-900 flex-1">
																Valid To
															</div>
															<input
																type="datetime-local"
																value={moment(validTo).format(
																	"yyyy-MM-DD hh:mm:ss"
																)}
																className="bg-bgfield text-ungutext text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 focus:blue-500 focus:border-blue-500 border-none outline-none"
																onChange={(e) =>
																	setValidTo(new Date(e.target.value))
																}
															/>
														</div>
													</div>
													<div className="flex justify-center space-x-6 pt-3">
														<Button
															type="button"
															className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-lg text-sm px-5 py-2.5"
															onClick={() => {
																setIsAddOrEditAgent(false);
															}}
															ref={cancelAddOrEditAgent}
														>
															Cancel
														</Button>
														<Button
															type="submit"
															className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-lg text-sm px-5 py-2.5"
														>
															Save
														</Button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root> */}
			<Transition.Root appear show={isDeletingAgent} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-50"
					initialFocus={cancelDeletingUser}
					onClose={() => setIsDeletingAgent(false)}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl px-4 pt-5 pb-4 sm:p-6 sm:pb-4 sm:flex sm:items-start sm:max-w-auto bg-white text-left align-middle shadow-xl transition-all">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
										<div className="flex justify-center">
											<svg
												width="123"
												height="123"
												viewBox="0 0 123 123"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M66.625 66.625H56.375V35.875H66.625M66.625 87.125H56.375V76.875H66.625M61.5 10.25C54.7698 10.25 48.1054 11.5756 41.8875 14.1512C35.6695 16.7267 30.0198 20.5018 25.2608 25.2608C15.6495 34.872 10.25 47.9077 10.25 61.5C10.25 75.0923 15.6495 88.128 25.2608 97.7392C30.0198 102.498 35.6695 106.273 41.8875 108.849C48.1054 111.424 54.7698 112.75 61.5 112.75C75.0923 112.75 88.128 107.35 97.7392 97.7392C107.35 88.128 112.75 75.0923 112.75 61.5C112.75 54.7698 111.424 48.1054 108.849 41.8875C106.273 35.6695 102.498 30.0198 97.7392 25.2608C92.9802 20.5018 87.3305 16.7267 81.1125 14.1512C74.8946 11.5756 68.2302 10.25 61.5 10.25Z"
													fill="#472183"
												/>
											</svg>
										</div>

										<Dialog.Title
											as="h1"
											className="leading-6 text-5xl text-black text-center font-bold font-montserrat mt-4"
										>
											DELETE
										</Dialog.Title>

										<Dialog.Title
											as="h3"
											className="leading-6 text-xl text-gray-900 text-center font-montserrat mt-4"
										>
											Do you really want to delete this Agent ?
										</Dialog.Title>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												deletingAgent(agents![selectedRow].id!);
											}}
										>
											<div className="flex justify-center space-x-6 pt-3">
												<Button
													type="button"
													className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-3xl text-sm px-8 py-2.5 shadow"
													onClick={() => setIsDeletingAgent(false)}
													ref={cancelDeletingUser}
												>
													Cancel
												</Button>
												<Button
													type="submit"
													className="text-white bg-ungutext border border-ungutext font-medium rounded-3xl text-sm px-8 py-2.5 shadow-2xl"
												>
													Delete
												</Button>
											</div>
										</form>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
