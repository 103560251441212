import * as PusherPushNotifications from "@pusher/push-notifications-web";
import React from "react";
export function omit<T>(obj: T, keys: string[]): T {
	const result: any = {};
	for (const key in obj) {
		if (!keys.includes(key)) {
			result[key] = obj[key];
		}
	}
	return result;
}

export const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
	url: process.env.REACT_APP_API_URL + "/pusher/beams-auth",
	credentials: "include",
});

export const useMessageNotif = () => {
	const [path, setPath] = React.useState(window.messageNotification);
	const listenToPopstate = () => {
		const winPath = window.messageNotification;
		setPath(winPath);
	};
	React.useEffect(() => {
		window.addEventListener("messageNotif", listenToPopstate);
		return () => {
			window.removeEventListener("messageNotif", listenToPopstate);
		};
	}, []);
	return path;
};
