import {IUser} from "../model/User";
import Api from "../axios/Api";
import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import {useAppDispatch, useAppSelector} from "../hooks";
import {selectAuth, setIsLoading, setUser} from "../slices/auth";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import {User} from "../services/types";
import { beamsTokenProvider } from "../utils/util";
export interface IUseAuthProvider{
    user : User | null;
    login: (username:string, password:string) => Promise<User|null>
    isLoading: boolean;
    logout: ()=>Promise<boolean>
}

const useAuthProvider = ():IUseAuthProvider =>{
    const authSelector = useAppSelector(state => state.auth)
    const authDispatch = useAppDispatch()
    
    const check = function (){
        Api.getClient().get("/api/check").then((response)=>{
            authDispatch(setUser(response.data))
            authDispatch(setIsLoading(false))
        }).catch(()=>{
            authDispatch(setUser(null))
            authDispatch(setIsLoading(false))
        })
    }
    useEffect(()=>{
        check()
        if (!window.authChecker){
            window.authChecker = setInterval(()=>{
                if(authSelector.user){
                    check()
                }
            }, 5000)
        }
    },[]);
    const login = async (username:string, password:string) : Promise<IUser|null> => {
        try {
            let response = await Api.getClient().post("/api/login",{username,password})
            if (response.data){
                authDispatch(setUser(response.data))
                window.pusherBeams?.setUserId(`${response.data.user.id}`, beamsTokenProvider);
                return response.data
            }
        }catch (e:any) {
            await Swal.fire({
                title: "Error",
                text: e.response.data.error,
                icon: "error",
                confirmButtonText: "Ok"
            })
        }
        setIsLoading(false)
        return null
    }

    const logout = async () : Promise<boolean> => {
        try {
            let response = await Api.getClient().get("/api/logout")
            if (response.data.status === 204){
                authDispatch(setUser(null))
                window.pusherBeams?.stop().catch(console.error);
                return true
            }
        }catch (e:any) {
            await Swal.fire({
                title: "Error",
                text: e.response.data.error,
                icon: "error",
                confirmButtonText: "Ok"
            })
        }
        setIsLoading(false)
        return false
    }
    return {user:authSelector.user, isLoading: authSelector.isLoading ,login,logout}
}
export default useAuthProvider