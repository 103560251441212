import { useEffect, useMemo, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { StatisticChart } from '../services/types';
import { deleteDoc, doc, onSnapshot, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { db } from '../services/firebase';
import moment from 'moment';
import { PlayArrow, RestartAlt, Stop } from '@mui/icons-material';
import Swal from 'sweetalert2';


export default function Statistic(props:{ip: string, path: string}) {
    const [data, setData] = useState<StatisticChart[]|{}[]>([{},{},{},{},{},{},{},{},{},{},{},{}]);

    useEffect(() => {
        const unsub = onSnapshot(doc(db, "stat-listener", props.ip.replaceAll(".", "-")), (document) => {
            if(document.exists()) {
                if (!document.data()?.listen) {
                    updateDoc(doc(db, "stat-listener", props.ip.replaceAll(".", "-")), {
                        listen: true,
                        timeout: moment().add(5, 'minutes').toDate()
                    });
                }
            }else{
                console.log("test123")
                setDoc(doc(db, "stat-listener", props.ip.replaceAll(".", "-")), {
                    listen: true,
                    timeout: moment().add(5, 'minutes').toDate()
                });
            }
        });
        
        return () => unsub();
    }, [props.ip]);

    useEffect(() => {
        const unsub = onSnapshot(doc(db, "stat", props.ip.replaceAll(".", "-")), (doc) => {
            if(doc.exists()) {
                let newData = doc.data() as StatisticChart;
                newData.updated_at = moment(newData.updated_at)
                newData.cpu_total = 100;
                setData((prev) => [...prev.slice(1), newData]);
            }
        });
        return () => unsub();
    }, [props.ip]);

    useEffect(() => {
        const unsub = onSnapshot(doc(db, "action", props.ip.replaceAll(".", "-")), (document) => {
            if(document.exists()) {
                let newData = document.data();
                if (newData.status == "running") {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Processing...',
                        toast: true,
                        timer: 1000,
                        timerProgressBar: true
                    })
                }
                if (newData.status == "success") {
                    Swal.fire({
                        icon: 'success',
                        text: `${newData.action} ${newData.type} success`,
                        toast: true,
                        timer: 1000,
                        timerProgressBar: true
                    })
                }
                if (newData.status == "error") {
                    Swal.fire({
                        icon: 'error',
                        title: `${newData.action} ${newData.type} error`,
                        text: newData.output,
                    })
                }
                deleteDoc(doc(db, "action", props.ip.replaceAll(".", "-")));
            }
        });
        return () => unsub();
    }, [props.ip]);

    useEffect(() => {
        console.log(data);
    }, [data]);

    const doAction = useMemo(() => {
        return async (action: string, type : string) => {
            await setDoc(doc(db, "action", props.ip.replaceAll(".", "-")), {
                action: action,
                type: type,
                path: props.path
            });
        }
    }, [props.path]);

    return (
        <>
            <div className="h-60 text-xs flex flex-col">
                <div className="mt-4">CPU%</div>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart maxBarSize={12}
                        data={data}
                    >
                        <XAxis dataKey="updated_at" hide={true} />
                        <YAxis />
                        <Tooltip/>
                        <Legend />
                        <Line type="monotone" dataKey="cpu_usage" stroke="blue" isAnimationActive={false} dot={false} />
                        <Line type="monotone" dataKey="cpu_total" stroke="red" isAnimationActive={false} dot={false}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className="h-60 text-xs flex flex-col">
                <div className="mt-4">RAM(GB)</div>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={data}
                    >
                        <XAxis dataKey="updated_at" hide={true} />
                        <YAxis />
                        <Tooltip/>
                        <Legend />
                        <Line type="monotone" dataKey="memory_used" stroke="blue" isAnimationActive={false} dot={false} />
                        <Line type="monotone" dataKey="memory_total" stroke="red" isAnimationActive={false} dot={false}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className="h-60 text-xs flex flex-col">
                <div className="mt-4">DISK(GB)</div>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={data}
                    >
                        <XAxis dataKey="updated_at" hide={true} />
                        <YAxis />
                        <Tooltip/>
                        <Legend />
                        <Line type="monotone" dataKey="disk_used" stroke="blue" isAnimationActive={false} dot={false} />
                        <Line type="monotone" dataKey="disk_total" stroke="red" isAnimationActive={false} dot={false}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className='flex flex-row justify-between items-center'>
                <span>PostgreSQL</span>
                <div className='flex flex-row'>
                    <button
                    onClick={async () => await doAction("start", "postgresql")}
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'><PlayArrow/></button>
                    <button 
                    onClick={async () => await doAction("stop", "postgresql")}
                    className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'><Stop/></button>
                    <button
                    onClick={async () => await doAction("restart", "postgresql")}
                    className='bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded'><RestartAlt/></button>
                </div>  
            </div>
            <div className='flex flex-row justify-between items-center'>
                <span>IDempiere</span>
                <div className='flex flex-row'>
                    <button
                    onClick={async () => await doAction("start", "idempiere",)}
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'><PlayArrow/></button>
                    <button 
                    onClick={async () => await doAction("stop", "idempiere")}
                    className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'><Stop/></button>
                    <button 
                    onClick={async () => await doAction("restart", "idempiere")}
                    className='bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded'><RestartAlt/></button>
                </div>  
            </div>
        </>
    );
}