import React, { Fragment, useRef, useState, useEffect } from "react";
import TicketBox from "../../components/TicketBox";
import IssueCard from "../../components/IssueCard";
import { Dialog, Transition } from "@headlessui/react";
import {
	useGetIssuesQuery,
	useLazyGetIssueQuery,
	useUpdateIssueMutation,
	useUploadFileMutation,
} from "../../services/API";
import { Client, Issue, Ticket, User } from "../../services/types";
import moment, { now } from "moment";
import { omit } from "../../utils/util";
import Swal from "sweetalert2";
import useAuth from "../../auth/useAuth";

export default function IssueLogPage() {
	const [openIssue, setOpenIssue] = useState(false);
	const cancelOpenIssue = useRef<HTMLButtonElement>(null);
	const [isAttached, setIsAttached] = useState(true);
	const [isResolve, setIsResolve] = useState(false);
	const [actionType, setActionType] = useState("");
	const [isReSubmit, setIsReSubmit] = useState(false);
	const [tNote, setTNote] = useState<string>("");
	const [tIssueLog, setTIssueLog] = useState<string>("");
	const auth = useAuth();
	const {
		data: issues,
		error: issuesError,
		isLoading: issuesLoading,
	} = useGetIssuesQuery(auth?.user?.id!);
	const [
		loadIssue,
		{ data: issue, error: issueError, isLoading: issueLoading, isFetching: issueFetching },
	] = useLazyGetIssueQuery();

	const [
		updateIssue,
		{ error: updateIssueError, isLoading: updateIssueIsLoading },
	] = useUpdateIssueMutation();
	const [filteredIssues, setFilteredIssues] = useState<Issue[]>([]);
	const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
	const [selectedPriorities, setSelectedPriorities] = useState<string[]>([]);
	const [onSelectedIssue, setOnSelectedIssue] = useState<number>(-1);
	const [draftState, setDraftState] = useState(false);
	const [draftIssue, setDraftIssue] = useState(-1)
	const issueHolderRef = useRef<HTMLDivElement>(null);

	const [issueAttachment, setIssueAttachment] = useState<File | null>(null);
	const [issueAttachmentName, setIssueAttachmentName] = useState<string>("")
	const [issueTechAttachment, setIssueTechAttachment] = useState<File | null>(null);
	const [issueTechAttachmentName, setIssueTechAttachmentName] = useState<string>("")
	const [uploadFile, {}] = useUploadFileMutation();


	const handleResolve = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (issue) {
			if (tIssueLog.trim().length > 0){
				let filename = null;
				let extension = "";

				if (issueTechAttachment!) {
				filename = await uploadFile(issueTechAttachment).unwrap();
				extension = filename.split(".").pop()!;
				}

				setIsResolve(true);
				setOpenIssue(false);
				let i = {
					...issue,
					id: issue!.id,
					status: "pending",
					resolve_at: new Date(),
					note: tNote,
					log: tIssueLog,
					attachment_2: issueTechAttachment? filename : undefined,
				};
				i = omit(i, ["assigned_to", "channel", "ticket"]);
				await updateIssue(i);
				await Swal.fire({
					toast: true,
					position: "top-end",
					icon: "success",
					title: "Issue has been updated",
					timer: 3000,
					showConfirmButton: false,
				});
				clear();
				setIsReSubmit(false);
			}
		}
	};

	const handleSaveState = async () => {
		if (openIssue){
			setDraftIssue(issue?.id!)
			setOpenIssue(false)
			setIsReSubmit(false)
		}	
		if (issue) {
			if (tIssueLog.trim().length > 0){
				let filename = null;
				let extension = "";

				if (issueTechAttachment!) {
				filename = await uploadFile(issueTechAttachment).unwrap();
				extension = filename.split(".").pop()!;
				}

				setIsResolve(true);
				setOpenIssue(false);
				let i = {
					...issue,
					id: issue!.id,
					resolve_at: new Date(),
					note: tNote,
					log: tIssueLog,
					attachment_2: issueTechAttachment? filename : undefined,
				};
				i = omit(i, ["assigned_to", "channel", "ticket"]);
				await updateIssue(i);
				await Swal.fire({
					toast: true,
					position: "top-end",
					icon: "success",
					title: "Issue has been updated",
					timer: 3000,
					showConfirmButton: false,
				});
				clear();
				setIsReSubmit(false);
			}
		}
	};


	const handleReSubmit = () => {
		setIsResolve(false);
		setIsReSubmit(true);
		console.log(isReSubmit);
	};

	const clear = () => {
		// setIsResolve(false);
		// setActionType("");
		// setTNote("");
		// setTIssueLog("");
		// setDraftState(false)
		// setDraftIssue(-1)
	};

	const DownloadAttachment = (attachment: string) => {
		let link = document.createElement("a");
		link.href = process.env.REACT_APP_API_URL + "/files/" + attachment +"?download=true";
		link.rel = "noreferrer";
		link.click();
	};

	const generateLogoUser = (user: User) => {
		if (user.profile_picture?.length! > 0)
			return process.env.REACT_APP_API_URL + "/files/" + user.profile_picture;
		let abbreviation = "";
		user.name.split(" ").forEach((word: string) => {
			abbreviation += word[0];
		});
		abbreviation = abbreviation.toUpperCase();
		return (
			"https://ui-avatars.com/api/?name=" +
			abbreviation +
			"&background=472183&color=ffffff&length=" +
			abbreviation.length
		);
	};

	const generateLogo = (client: Client) => {
		if (client.logo_url?.length! > 0)
			return process.env.REACT_APP_API_URL + "/files/" + client.logo_url;
		let abbreviation = "";
		client.name.split(" ").forEach((word: string) => {
			if (word.indexOf("PT.") > -1) return;
			abbreviation += word[0];
		});
		abbreviation = abbreviation.toUpperCase();
		return (
			"https://ui-avatars.com/api/?name=" +
			abbreviation +
			"&background=472183&color=ffffff&length=" +
			abbreviation.length
		);
	};

	const getIssues = (status: string) => {
		if (filteredIssues) {
			return filteredIssues.filter((issue) => issue.status === status)
		}
		return [];
	};

	// useEffect(() => {
	// 	if (actionType === "open") {
	// 		console.log(actionType);
	// 		clear();
	// 	} else {
	// 		setIsResolve(true);
	// 	}
	// }, [actionType]);

	useEffect(() => {
		let fIssues: Issue[] = [];
		if (issues) {
			fIssues = issues;
			if (selectedLevels.length > 0) {
				fIssues = fIssues.filter((issue) =>
					selectedLevels.includes(issue.ticket?.level!)
				);
			}
			if (selectedPriorities.length > 0) {
				fIssues = fIssues.filter((issue) =>
					selectedPriorities.includes(issue.ticket?.priority!)
				);
			}
			// if(searchTicket.length > 0) {
			//     fTickets = fTickets.filter(ticket => ticket.title.toLowerCase().includes(searchTicket.toLowerCase()))
			// }
			setFilteredIssues(fIssues);
		}
		console.log(fIssues);
	}, [
		issues,
		selectedLevels,
		selectedPriorities,
		// , searchTicket
	]);

	const handleGetData = () => {
		setOpenIssue(true);
		setActionType("process");
		clear();
	};

	const onAttachmentChange = (e:any) => {
		if (e.target.files[0] && e.target.files[0].size <= 10e6) {
			console.log(e.target.files[0])
			setIssueAttachment(e.target.files[0]);
			setIssueAttachmentName(e.target.files[0].name);
			e.target.value = null;
		} else {
			e.target.value = null;
		}
	}

	const onTechnicalAttachmentChange = (e:any) => {
		if (e.target.files[0] && e.target.files[0].size <= 10e6) {
			console.log(e.target.files[0])
			setIssueTechAttachment(e.target.files[0]);
			setIssueTechAttachmentName(e.target.files[0].name);
			e.target.value = null;
		} else {
			e.target.value = null;
		}
	}

	// useEffect(() => {
	// 	if (draftState && openIssue){
	// 		setDraftIssue(issue?.id!)
	// 		setOpenIssue(false)
	// 		setIsReSubmit(false)
	// 	}
	// }, [draftState])

	useEffect(() => {
		if (issue){
			if (draftState && draftIssue === issue.id){
			} else {
				setTNote(issue?.note? issue?.note : "");
				setTIssueLog(issue?.log? issue?.log : "");
				setIssueAttachment(issue?.attachment? issue?.attachment : "")
				setIssueTechAttachmentName(issue?.attachment_2? issue?.attachment_2 : "")
			}
		}
	}, [issue])

	return (
		<>
			<div className="w-full flex flex-row max-h-full overflow-y-auto overflow-x-auto">
				<div
					className={
						(getIssues("open").length > 3 ? "h-auto" : "h-max") +
						" flex-1 bg-white my-[2%] mx-[0.2%] flex flex-col space-y-[2%] rounded-lg px-[1%] py-[2%] overflow-y-auto"
					}
				>
					<div className="flex-initial text-[18px] text-gray-500 font-medium">
						Open Issue
					</div>
					<IssueCard
						useOpen={() => {
							setActionType("open");
							setOpenIssue(true);
						}}
						loadIssue={loadIssue}
						issues={getIssues("open")}
						selectedIssue={issue}
						isOpen={true}
						onSetLog={setTIssueLog}
						onSetNote={setTNote}
					></IssueCard>
				</div>
				<div
					className={
						(getIssues("process").length > 3 ? "h-auto" : "h-max") +
						" flex-1 bg-white my-[2%] mx-[0.2%] flex flex-col space-y-[2%] rounded-lg px-[1%] py-[2%] overflow-y-auto"
					}
				>
					<div className="text-[18px] text-gray-500 font-medium flex-1">
						In Progress Issue
					</div>
					<IssueCard
						useOpen={() => {
							setActionType("process");
							setOpenIssue(true);
						}}
						loadIssue={loadIssue}
						issues={getIssues("process")}
						selectedIssue={issue}
						isOpen={false}
					></IssueCard>
				</div>
				<div
					className={
						(getIssues("pending").length > 3 ? "h-auto" : "h-max") +
						" flex-1 bg-white my-[2%] mx-[0.2%] flex flex-col space-y-[2%] rounded-lg px-[1%] py-[2%] overflow-y-auto"
					}
				>
					<div className="text-[18px] text-gray-500 font-medium flex-1">
						Pending
					</div>
					<IssueCard
						useOpen={() => {
							setActionType("pending");
							setOpenIssue(true);
						}}
						loadIssue={loadIssue}
						issues={getIssues("pending")}
						selectedIssue={issue}
						isOpen={false}
					></IssueCard>
				</div>
				<div
					className={
						(getIssues("hold").length > 3 ? "h-auto" : "h-max") +
						" flex-1 bg-white my-[2%] mx-[0.2%] flex flex-col space-y-[2%] rounded-lg px-[1%] py-[2%] overflow-y-auto"
					}
				>
					<div className="text-[18px] text-gray-500 font-medium flex-1">
						Hold
					</div>
					<IssueCard
						useOpen={() => {
							setActionType("hold");
							setOpenIssue(true);
						}}
						loadIssue={loadIssue}
						issues={getIssues("hold")}
						selectedIssue={issue}
						isOpen={false}
					></IssueCard>
				</div>
				<div
					className={
						(getIssues("closed").length > 3 ? "h-auto" : "h-max") +
						" flex-1 bg-white my-[2%] mx-[0.2%] flex flex-col space-y-[2%] rounded-lg px-[1%] py-[2%] overflow-y-auto"
					}
				>
					<div className="text-[18px] text-gray-500 font-medium flex-1">
						Closed Issue
					</div>
					<IssueCard
						useOpen={() => {
							setActionType("closed");
							setOpenIssue(true);
						}}
						loadIssue={loadIssue}
						issues={getIssues("closed")}
						selectedIssue={issue}
						isOpen={false}
						onIssueSelect={setOnSelectedIssue}
					></IssueCard>
				</div>
			</div>
			<Transition.Root appear show={openIssue} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={cancelOpenIssue}
					onClose={() => {
						setOpenIssue(false)
					}}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-[80%] transform overflow-hidden rounded-2xl sm:flex sm:flex-col sm:items-start bg-white text-left align-middle shadow-xl transition-all">
									{issueFetching && (
										<div
										ref={issueHolderRef}
										className="w-[25%] items-center justify-center rounded flex flex-col">
												<div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900" />
										</div>
									)}
									{issue && !issueFetching && (
										<form className="w-full" onSubmit={(e) => handleResolve(e)}>
											{issue.priority_level === 5 && (
												<div ref={issueHolderRef} className="bg-[#570606] flex-initial h-14 w-full rounded-t-xl capitalize"></div>
											)}
											{issue.priority_level === 4 && (
												<div ref={issueHolderRef} className="bg-[#B40606] flex-initial h-14 w-full rounded-t-xl capitalize"></div>
											)}
											{issue.priority_level === 3 && (
												<div ref={issueHolderRef} className="bg-[#E2B203] flex-initial h-14 w-full rounded-t-xl capitalize"></div>
											)}
											{issue.priority_level === 2 && (
												<div ref={issueHolderRef} className="bg-[#475706] flex-initial h-14 w-full rounded-t-xl capitalize"></div>
											)}
											{issue.priority_level === 1 && (
												<div ref={issueHolderRef} className="bg-[#94C748] flex-initial h-14 w-full rounded-t-xl capitalize"></div>
											)}

											{/* Test */}
											{issue.priority_level === 0 && (
												<div ref={issueHolderRef} className="bg-gray-500 flex-initial h-14 w-full rounded-t-xl capitalize"></div>
											)}
											{/*  */}

											<div ref={issueHolderRef} className="mt-3 text-center sm:mt-0 sm:text-left w-full px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-row">
												<div className="flex-1 flex flex-col space-y-2">
													<div className="flex-1 flex-row flex items-center space-x-2">
														<svg
															width="20"
															height="20"
															viewBox="0 0 28 30"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
															className="flex-initial min-h-max min-w-max"
														>
															<path
																d="M6.5 18V15H21.5V18H6.5ZM6.5 24V21H17V24H6.5ZM3.5 30C2.675 30 1.9685 29.706 1.3805 29.118C0.792502 28.53 0.499003 27.824 0.500003 27V6C0.500003 5.175 0.794003 4.4685 1.382 3.8805C1.97 3.2925 2.676 2.999 3.5 3H5V0H8V3H20V0H23V3H24.5C25.325 3 26.0315 3.294 26.6195 3.882C27.2075 4.47 27.501 5.176 27.5 6V27C27.5 27.825 27.206 28.5315 26.618 29.1195C26.03 29.7075 25.324 30.001 24.5 30H3.5ZM3.5 27H24.5V12H3.5V27Z"
																fill="#548CFF"
															/>
														</svg>
														<div className="flex-1 text-textmenu font-medium">
															{issue.ticket?.title!}
														</div>
													</div>
													<div className="flex-1 flex-col flex">
														<div className="flex-initial flex-row flex items-center space-x-2">
															<img
																className="flex-initial h-[30px] w-[30px]"
																src={generateLogo(issue.ticket?.client!)}
															/>
															<div className="flex-initial text-textnormal font-medium text-black pr-4">
																{issue.ticket?.client.name!}
															</div>
															<div className="flex-initial text-textnormal font-medium text-ungutext pr-4 capitalize">
																{issue.ticket?.level!} #{issue.ticket?.id!}
															</div>
															{issue.ticket?.category! &&
															issue.ticket?.category! !== "" ? (
																<button
																	type="button"
																	className="flex-initial border border-[#04B700] rounded-lg text-[14px] py-0.5 px-[1%] cursor-default capitalize"
																>
																	{issue.ticket.category}
																</button>
															) : null}
														</div>
														<div className="flex-initial flex flex-row space-x-4 ml-[38px] text-[14px]">
															<div className="flex-initial">
																{moment(issue.ticket?.created_at!).format(
																	"HH:mm"
																)}
															</div>
															{issue.status === "open" && (
																<div className="flex-initial font-medium text-green-500">
																	Open
																</div>
															)}
															{issue.status === "process" && (
																<div className="flex-initial font-medium text-amber-500">
																	In Progress
																</div>
															)}
															{issue.status === "closed" && (
																<div className="flex-initial font-medium text-gray-700">
																	Closed
																</div>
															)}
														</div>
													</div>
													<div className="flex-1 flex-row flex items-center space-x-2">
														<svg
															width="20"
															height="20"
															viewBox="0 0 28 28"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M25.25 0.5H2.75C2.15326 0.5 1.58097 0.737053 1.15901 1.15901C0.737053 1.58097 0.5 2.15326 0.5 2.75V25.25C0.5 25.8467 0.737053 26.419 1.15901 26.841C1.58097 27.2629 2.15326 27.5 2.75 27.5H18.0345C18.33 27.5005 18.6227 27.4424 18.8957 27.3292C19.1687 27.2161 19.4165 27.0499 19.625 26.8405L26.8405 19.625C27.0499 19.4165 27.2161 19.1687 27.3292 18.8957C27.4424 18.6227 27.5005 18.33 27.5 18.0345V2.75C27.5 2.15326 27.2629 1.58097 26.841 1.15901C26.419 0.737053 25.8467 0.5 25.25 0.5ZM9.5 8.375H18.5C18.7984 8.375 19.0845 8.49353 19.2955 8.7045C19.5065 8.91548 19.625 9.20163 19.625 9.5C19.625 9.79837 19.5065 10.0845 19.2955 10.2955C19.0845 10.5065 18.7984 10.625 18.5 10.625H9.5C9.20163 10.625 8.91548 10.5065 8.7045 10.2955C8.49353 10.0845 8.375 9.79837 8.375 9.5C8.375 9.20163 8.49353 8.91548 8.7045 8.7045C8.91548 8.49353 9.20163 8.375 9.5 8.375ZM14 19.625H9.5C9.20163 19.625 8.91548 19.5065 8.7045 19.2955C8.49353 19.0845 8.375 18.7984 8.375 18.5C8.375 18.2016 8.49353 17.9155 8.7045 17.7045C8.91548 17.4935 9.20163 17.375 9.5 17.375H14C14.2984 17.375 14.5845 17.4935 14.7955 17.7045C15.0065 17.9155 15.125 18.2016 15.125 18.5C15.125 18.7984 15.0065 19.0845 14.7955 19.2955C14.5845 19.5065 14.2984 19.625 14 19.625ZM9.5 15.125C9.20163 15.125 8.91548 15.0065 8.7045 14.7955C8.49353 14.5845 8.375 14.2984 8.375 14C8.375 13.7016 8.49353 13.4155 8.7045 13.2045C8.91548 12.9935 9.20163 12.875 9.5 12.875H18.5C18.7984 12.875 19.0845 12.9935 19.2955 13.2045C19.5065 13.4155 19.625 13.7016 19.625 14C19.625 14.2984 19.5065 14.5845 19.2955 14.7955C19.0845 15.0065 18.7984 15.125 18.5 15.125H9.5ZM18.5 24.7845V18.5H24.7859L18.5 24.7845Z"
																fill="#548CFF"
															/>
														</svg>

														<div className="flex-1 text-texttitle font-medium">
															Request
														</div>
													</div>
													<div className="flex-initial border bg-bgfield border-ungutext mx-7 px-4 py-2 rounded-xl font-medium max-h-52 overflow-y-auto h-52 break-all whitespace-pre-line">
														{issue.request}
													</div>
													{issue.attachment ? (
														<button
															type="button"
															className="flex-1 mx-7 px-4 py-2 rounded-xl font-medium bg-ungutext text-white text-textnormal items-center flex flex-row justify-center space-x-3"
															onClick={() =>
																DownloadAttachment(issue.attachment)
															}
														>
															<svg
																width="24"
																height="24"
																viewBox="0 0 31 31"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M14.4751 12.8024L13.1271 11.5264C12.8276 11.259 12.4716 11.1312 12.0592 11.1428C11.6468 11.1545 11.3038 11.2945 11.0302 11.5628C10.7557 11.8302 10.6184 12.1705 10.6184 12.5837C10.6184 12.9969 10.7557 13.3371 11.0302 13.6045L14.9245 17.3962C15.1991 17.6635 15.5485 17.7972 15.9729 17.7972C16.3973 17.7972 16.7468 17.6635 17.0214 17.3962L20.953 13.568C21.2276 13.3007 21.3589 12.9662 21.347 12.5647C21.335 12.1632 21.1912 11.8292 20.9156 11.5628C20.641 11.2955 20.2915 11.1555 19.8671 11.1428C19.4428 11.1302 19.0933 11.2702 18.8187 11.5628L17.4707 12.8024V8.1722C17.4707 7.75901 17.3269 7.41873 17.0393 7.15137C16.7518 6.88401 16.3963 6.75033 15.9729 6.75033C15.5485 6.75033 15.1926 6.89033 14.905 7.17033C14.6174 7.45033 14.4741 7.79644 14.4751 8.20866V12.8024ZM9.9818 22.792H22.0015C22.4259 22.792 22.7753 22.652 23.0499 22.372C23.3245 22.092 23.4618 21.7459 23.4618 21.3337C23.4618 20.9205 23.318 20.5739 23.0305 20.2939C22.7429 20.0139 22.3874 19.8744 21.964 19.8753H9.94435C9.51998 19.8753 9.1705 20.0153 8.89591 20.2953C8.62131 20.5753 8.48402 20.9214 8.48402 21.3337C8.48402 21.7469 8.6278 22.0934 8.91538 22.3734C9.20295 22.6534 9.55843 22.793 9.9818 22.792ZM15.9729 30.0837C13.901 30.0837 11.9539 29.7006 10.1316 28.9345C8.30928 28.1684 6.72413 27.1296 5.37612 25.818C4.02812 24.5055 2.9612 22.9621 2.17537 21.1878C1.38953 19.4135 0.996116 17.5177 0.995117 15.5003C0.995117 13.483 1.38853 11.5871 2.17537 9.81283C2.9622 8.03852 4.02912 6.49512 5.37612 5.18262C6.72413 3.87012 8.30928 2.8313 10.1316 2.06616C11.9539 1.30102 13.901 0.917964 15.9729 0.916992C18.0448 0.916992 19.992 1.30005 21.8143 2.06616C23.6366 2.83227 25.2217 3.87109 26.5697 5.18262C27.9177 6.49512 28.9851 8.03852 29.772 9.81283C30.5588 11.5871 30.9517 13.483 30.9507 15.5003C30.9507 17.5177 30.5573 19.4135 29.7705 21.1878C28.9836 22.9621 27.9167 24.5055 26.5697 25.818C25.2217 27.1305 23.6366 28.1698 21.8143 28.936C19.992 29.7021 18.0448 30.0846 15.9729 30.0837Z"
																	fill="#548CFF"
																/>
															</svg>
															<span>Download Agent Attachment</span>
														</button>
													) : null}
													<div className="flex-1 flex-row flex items-center space-x-2">
														<svg
															width="20"
															height="20"
															viewBox="0 0 30 30"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fillRule="evenodd"
																clipRule="evenodd"
																d="M15 6H22.5C22.8978 6 23.2794 6.15804 23.5607 6.43934C23.842 6.72064 24 7.10218 24 7.5C24 7.89782 23.842 8.27935 23.5607 8.56066C23.2794 8.84196 22.8978 9 22.5 9H14.4885C14.0965 10.1066 13.4916 11.1258 12.708 12H22.5C22.8978 12 23.2794 12.158 23.5607 12.4393C23.842 12.7206 24 13.1022 24 13.5C24 13.8978 23.842 14.2794 23.5607 14.5607C23.2794 14.842 22.8978 15 22.5 15H7.5C7.3542 15.0001 7.20917 14.9789 7.0695 14.937C5.80034 15.0909 4.51294 14.9716 3.29365 14.5872C2.07436 14.2027 0.951367 13.562 0 12.708V25.5C0 26.6935 0.474106 27.8381 1.31802 28.682C2.16193 29.5259 3.30653 30 4.5 30H18V22.5C18 21.3065 18.4741 20.1619 19.318 19.318C20.1619 18.4741 21.3065 18 22.5 18H30V4.5C30 3.30653 29.5259 2.16193 28.682 1.31802C27.8381 0.474106 26.6935 0 25.5 0H12.708C14.1866 1.64839 15.0031 3.7856 15 6ZM21 29.8245C21.7329 29.614 22.4003 29.2207 22.9395 28.6815L28.6815 22.9395C29.2207 22.4003 29.614 21.7329 29.8245 21H22.5C22.1022 21 21.7206 21.158 21.4393 21.4393C21.158 21.7206 21 22.1022 21 22.5V29.8245ZM6 19.5C6 19.1022 6.15804 18.7206 6.43934 18.4393C6.72064 18.158 7.10218 18 7.5 18H13.5C13.8978 18 14.2794 18.158 14.5607 18.4393C14.842 18.7206 15 19.1022 15 19.5C15 19.8978 14.842 20.2794 14.5607 20.5607C14.2794 20.842 13.8978 21 13.5 21H7.5C7.10218 21 6.72064 20.842 6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5Z"
																fill="#548CFF"
															/>
														</svg>

														<div className="flex-1 text-texttitle font-medium">
															Technical Note
														</div>
													</div>
													<textarea
														className={
															(issue.status === "closed" ? "cursor-text" : "") +
															" flex-initial border bg-bgfield border-ungutext mx-7 px-4 py-2 rounded-xl font-medium resize-none overflow-auto scrollbar-thin "
														}
														disabled={issue.status !== "closed" && issue.status !== "pending" ? false : true}
														value={tNote}
														rows={4}
														placeholder={issue.status !== "closed" && issue.status !== "pending" ? "Type your note here ..." : undefined}
														onChange={(e) => setTNote(e.target.value)}
													></textarea>
													{(issue.status === "open" ||
														issue.status === "process") &&
													!isReSubmit ? (
														<label className="bg-white border border-ungutext mx-7 px-4 py-2 rounded-md flex flex-row items-center justify-center space-x-4 mt-4 cursor-pointer hover:bg-ungutext hover:text-white text-ellipsis overflow-hidden p-2">
															<svg
																width="28"
																height="16"
																viewBox="0 0 36 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
																className="mx-2 min-h-max min-w-max"
															>
																<path
																	d="M35.0229 8C35.0879 9.93864 34.0874 11.8168 32.2406 13.2228C30.3939 14.6287 27.8517 15.4476 25.1714 15.5L7.59422 15.5C5.65659 15.464 3.81763 14.8742 2.47984 13.8598C1.14205 12.8454 0.414417 11.489 0.4562 10.0875C0.409775 8.68386 1.13534 7.32424 2.47362 6.30713C3.8119 5.29002 5.65353 4.69852 7.59422 4.6625L25.1887 4.6625C26.3615 4.71448 27.4627 5.08808 28.2677 5.7071C29.0728 6.32612 29.521 7.14393 29.521 7.99375C29.521 8.84357 29.0728 9.66138 28.2677 10.2804C27.4627 10.8994 26.3615 11.273 25.1887 11.325H8.9596C8.50122 11.325 8.06161 11.1933 7.73748 10.9589C7.41336 10.7245 7.23127 10.4065 7.23127 10.075C7.23127 9.74348 7.41336 9.42554 7.73748 9.19112C8.06161 8.9567 8.50122 8.825 8.9596 8.825H25.1887C25.4692 8.80007 25.7275 8.70179 25.9148 8.54867C26.102 8.39556 26.2054 8.19818 26.2054 7.99375C26.2054 7.78932 26.102 7.59194 25.9148 7.43883C25.7275 7.28571 25.4692 7.18743 25.1887 7.1625L7.59422 7.1625C6.57175 7.20136 5.61173 7.53029 4.92251 8.07791C4.2333 8.62552 3.8705 9.34763 3.91287 10.0875C3.87516 10.8253 4.24004 11.5442 4.92878 12.0891C5.61751 12.6339 6.57486 12.9611 7.59422 13L25.1714 13C26.935 12.948 28.5983 12.3925 29.7969 11.4554C30.9955 10.5182 31.6317 9.27573 31.5662 8C31.6317 6.72427 30.9955 5.48177 29.7969 4.54461C28.5983 3.60746 26.935 3.05204 25.1714 3L7.59422 3C7.13583 3 6.69623 2.86831 6.3721 2.63388C6.04797 2.39946 5.86588 2.08152 5.86588 1.75C5.86588 1.41848 6.04797 1.10054 6.3721 0.866116C6.69623 0.631695 7.13583 0.5 7.59422 0.5L25.1714 0.5C27.8517 0.552378 30.3939 1.37131 32.2406 2.77724C34.0874 4.18317 35.0879 6.06136 35.0229 8Z"
																	fill="#3795BD"
																/>
															</svg>
															<div className="text-ellipsis overflow-hidden break-all">
															{issueTechAttachmentName
																? issueTechAttachmentName
																: "Attach your files max. 10MB"}
															</div>
															<input
																type="file"
																name=""
																id=""
																hidden
																className=""
																onChange={(e) => onTechnicalAttachmentChange(e)}
															/>
														</label>
													) : issue.attachment_2 !== undefined && issue.attachment_2 !== null && issue.attachment_2 !== "" && (
														<button
															type="button"
															className="flex-1 mx-7 px-4 py-2 rounded-xl font-medium bg-ungutext text-white text-textnormal items-center flex flex-row justify-center space-x-3"
															onClick={() =>
																DownloadAttachment(issue.attachment_2)
															}
														>
															<svg
																width="24"
																height="24"
																viewBox="0 0 31 31"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M14.4751 12.8024L13.1271 11.5264C12.8276 11.259 12.4716 11.1312 12.0592 11.1428C11.6468 11.1545 11.3038 11.2945 11.0302 11.5628C10.7557 11.8302 10.6184 12.1705 10.6184 12.5837C10.6184 12.9969 10.7557 13.3371 11.0302 13.6045L14.9245 17.3962C15.1991 17.6635 15.5485 17.7972 15.9729 17.7972C16.3973 17.7972 16.7468 17.6635 17.0214 17.3962L20.953 13.568C21.2276 13.3007 21.3589 12.9662 21.347 12.5647C21.335 12.1632 21.1912 11.8292 20.9156 11.5628C20.641 11.2955 20.2915 11.1555 19.8671 11.1428C19.4428 11.1302 19.0933 11.2702 18.8187 11.5628L17.4707 12.8024V8.1722C17.4707 7.75901 17.3269 7.41873 17.0393 7.15137C16.7518 6.88401 16.3963 6.75033 15.9729 6.75033C15.5485 6.75033 15.1926 6.89033 14.905 7.17033C14.6174 7.45033 14.4741 7.79644 14.4751 8.20866V12.8024ZM9.9818 22.792H22.0015C22.4259 22.792 22.7753 22.652 23.0499 22.372C23.3245 22.092 23.4618 21.7459 23.4618 21.3337C23.4618 20.9205 23.318 20.5739 23.0305 20.2939C22.7429 20.0139 22.3874 19.8744 21.964 19.8753H9.94435C9.51998 19.8753 9.1705 20.0153 8.89591 20.2953C8.62131 20.5753 8.48402 20.9214 8.48402 21.3337C8.48402 21.7469 8.6278 22.0934 8.91538 22.3734C9.20295 22.6534 9.55843 22.793 9.9818 22.792ZM15.9729 30.0837C13.901 30.0837 11.9539 29.7006 10.1316 28.9345C8.30928 28.1684 6.72413 27.1296 5.37612 25.818C4.02812 24.5055 2.9612 22.9621 2.17537 21.1878C1.38953 19.4135 0.996116 17.5177 0.995117 15.5003C0.995117 13.483 1.38853 11.5871 2.17537 9.81283C2.9622 8.03852 4.02912 6.49512 5.37612 5.18262C6.72413 3.87012 8.30928 2.8313 10.1316 2.06616C11.9539 1.30102 13.901 0.917964 15.9729 0.916992C18.0448 0.916992 19.992 1.30005 21.8143 2.06616C23.6366 2.83227 25.2217 3.87109 26.5697 5.18262C27.9177 6.49512 28.9851 8.03852 29.772 9.81283C30.5588 11.5871 30.9517 13.483 30.9507 15.5003C30.9507 17.5177 30.5573 19.4135 29.7705 21.1878C28.9836 22.9621 27.9167 24.5055 26.5697 25.818C25.2217 27.1305 23.6366 28.1698 21.8143 28.936C19.992 29.7021 18.0448 30.0846 15.9729 30.0837Z"
																	fill="#548CFF"
																/>
															</svg>
															<span>Download Technical Attachment</span>
														</button>
													)}
													<div className="flex-1 flex-row flex items-center space-x-2">
														<svg
															width="20"
															height="20"
															viewBox="0 0 28 28"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M14 12.5C17.75 12.5 20.9375 11.9125 23.5625 10.7375C26.1875 9.5625 27.5 8.15 27.5 6.5C27.5 4.85 26.1875 3.4375 23.5625 2.2625C20.9375 1.0875 17.75 0.5 14 0.5C10.25 0.5 7.0625 1.0875 4.4375 2.2625C1.8125 3.4375 0.5 4.85 0.5 6.5C0.5 8.15 1.8125 9.5625 4.4375 10.7375C7.0625 11.9125 10.25 12.5 14 12.5ZM14 16.25C15.025 16.25 16.3065 16.1435 17.8445 15.9305C19.3825 15.7175 20.8635 15.374 22.2875 14.9C23.7125 14.425 24.9375 13.8065 25.9625 13.0445C26.9875 12.2825 27.5 11.351 27.5 10.25V14C27.5 15.1 26.9875 16.0315 25.9625 16.7945C24.9375 17.5575 23.7125 18.176 22.2875 18.65C20.8625 19.125 19.3815 19.469 17.8445 19.682C16.3075 19.895 15.026 20.001 14 20C12.975 20 11.6935 19.8935 10.1555 19.6805C8.6175 19.4675 7.137 19.124 5.714 18.65C4.289 18.175 3.064 17.5565 2.039 16.7945C1.014 16.0325 0.501 15.101 0.5 14V10.25C0.5 11.35 1.0125 12.2815 2.0375 13.0445C3.0625 13.8075 4.2875 14.426 5.7125 14.9C7.1375 15.375 8.619 15.719 10.157 15.932C11.695 16.145 12.976 16.251 14 16.25ZM14 23.75C15.025 23.75 16.3065 23.6435 17.8445 23.4305C19.3825 23.2175 20.8635 22.874 22.2875 22.4C23.7125 21.925 24.9375 21.306 25.9625 20.543C26.9875 19.78 27.5 18.849 27.5 17.75V21.5C27.5 22.6 26.9875 23.5315 25.9625 24.2945C24.9375 25.0575 23.7125 25.676 22.2875 26.15C20.8625 26.625 19.3815 26.969 17.8445 27.182C16.3075 27.395 15.026 27.501 14 27.5C12.975 27.5 11.6935 27.3935 10.1555 27.1805C8.6175 26.9675 7.137 26.624 5.714 26.15C4.289 25.675 3.064 25.056 2.039 24.293C1.014 23.53 0.501 22.599 0.5 21.5V17.75C0.5 18.85 1.0125 19.7815 2.0375 20.5445C3.0625 21.3075 4.2875 21.926 5.7125 22.4C7.1375 22.875 8.619 23.219 10.157 23.432C11.695 23.645 12.976 23.751 14 23.75Z"
																fill="#548CFF"
															/>
														</svg>

														<div className="flex-initial text-texttitle font-medium">
															Issue Log*
														</div>

														{issue.status === "pending" ? (
															<div className="group flex flex-row justify-center items-center">
																<svg
																	width="20"
																	height="20"
																	viewBox="0 0 19 19"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="cursor-pointer"
																	onClick={() => handleReSubmit()}
																>
																	<path
																		d="M16.3 6.925L12.05 2.725L13.45 1.325C13.8333 0.941667 14.3043 0.75 14.863 0.75C15.4217 0.75 15.8923 0.941667 16.275 1.325L17.675 2.725C18.0583 3.10833 18.2583 3.571 18.275 4.113C18.2917 4.655 18.1083 5.11733 17.725 5.5L16.3 6.925ZM14.85 8.4L4.25 19H0V14.75L10.6 4.15L14.85 8.4Z"
																		fill="#548CFF"
																	/>
																</svg>
																<span className="absolute scale-0 transition-all rounded bg-ungutext ml-32 items-center p-2 text-xs text-white group-hover:scale-100">
																	Re-Submit
																</span>
															</div>
														) : null}
													</div>
													<textarea
														required
														className="flex-initial border bg-bgfield border-ungutext mx-7 px-4 py-2 rounded-xl font-medium resize-none overflow-auto scrollbar-thin"
														rows={4}
														disabled={
															(issue.status === "closed" || issue.status === "pending") && !isReSubmit
																? true
																: false
														}
														value={tIssueLog}
														placeholder="Type your issue log here ..."
														onChange={(e) => setTIssueLog(e.target.value)}
													></textarea>
													{/* {(issue.status === "closed" ||
														issue.status === "pending") &&
													!isReSubmit ? (
														<button
															type="submit"
															className="flex-1 mx-7 px-4 py-2 rounded-xl font-medium bg-white text-black border border-biru text-textnormal"
															disabled
														>
															Mark As Resolve
														</button>
													) : (
														<button
															type="submit"
															className="flex-1 mx-7 px-4 py-2 rounded-xl font-medium bg-biru text-white text-textnormal"
														>
															Mark As Resolve
														</button>
													)} */}
												</div>
												<div className="flex-initial w-[20%] flex-col">
													<div className="bg-ungu flex flex-initial flex-col p-4 rounded-xl text-white">
														<div className="text-ungutext text-texttitle pt-4 text-center font-[700]">
															DETAIL INFO TICKET
														</div>
														<hr className="border border-white w-full mb-2" />
														<div className="text-texttitle">Client</div>
														<div className="flex flex-col space-y-2 mt-1 text-white font-light text-textnormal">
															<div className="flex space-x-2 items-center">
																<svg
																	width="20"
																	height="20"
																	viewBox="0 0 20 18"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="min-h-max min-w-max"
																>
																	<path
																		d="M0 18V0H10V4H20V18H0ZM2 16H4V14H2V16ZM2 12H4V10H2V12ZM2 8H4V6H2V8ZM2 4H4V2H2V4ZM6 16H8V14H6V16ZM6 12H8V10H6V12ZM6 8H8V6H6V8ZM6 4H8V2H6V4ZM10 16H18V6H10V8H12V10H10V12H12V14H10V16ZM14 10V8H16V10H14ZM14 14V12H16V14H14Z"
																		fill="#3795BD"
																	/>
																</svg>
																<div>{issue.ticket?.client.name!}</div>
															</div>
															<div className="flex space-x-2 items-center">
																<svg
																	width="20"
																	height="20"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="min-h-max min-w-max"
																>
																	<path
																		d="M12 1.5C9.81276 1.50258 7.71584 2.3726 6.16923 3.91922C4.62261 5.46584 3.75259 7.56276 3.75001 9.75C3.74739 11.5374 4.33124 13.2763 5.41201 14.7C5.41201 14.7 5.63701 14.9963 5.67376 15.039L12 22.5L18.3293 15.0353C18.3623 14.9955 18.588 14.7 18.588 14.7L18.5888 14.6978C19.669 13.2747 20.2526 11.5366 20.25 9.75C20.2474 7.56276 19.3774 5.46584 17.8308 3.91922C16.2842 2.3726 14.1873 1.50258 12 1.5ZM12.75 13.5H11.25V12H12.75V13.5ZM12.75 10.5H11.25V9H12.75V10.5ZM15.75 13.5H14.25V7.5H9.75001V13.5H8.25001V7.5C8.25041 7.1023 8.40857 6.721 8.68979 6.43978C8.97101 6.15856 9.35231 6.0004 9.75001 6H14.25C14.6477 6.0004 15.029 6.15856 15.3102 6.43978C15.5914 6.721 15.7496 7.1023 15.75 7.5V13.5Z"
																		fill="#3795BD"
																	/>
																</svg>
																<div>{issue.ticket?.client.address!}</div>
															</div>
															<div className="flex space-x-2 items-center">
																<svg
																	width="20"
																	height="20"
																	viewBox="0 0 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="min-h-max min-w-max"
																>
																	<path
																		d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM18.918 8H15.703C15.5535 6.8748 15.3651 5.75513 15.138 4.643C16.7259 5.32693 18.0513 6.50403 18.918 8ZM13.014 4.072C13.082 4.424 13.401 6.11 13.659 8H10.341C10.599 6.11 10.918 4.424 10.986 4.072C11.319 4.029 11.656 4 12 4C12.344 4 12.681 4.029 13.014 4.072ZM14 12C14 12.598 13.957 13.286 13.891 14H10.109C10.043 13.286 10 12.598 10 12C10 11.402 10.043 10.714 10.109 10H13.891C13.957 10.714 14 11.402 14 12ZM8.862 4.643C8.63491 5.75513 8.44646 6.8748 8.297 8H5.082C5.94871 6.50403 7.27413 5.32693 8.862 4.643ZM4.263 10H8.084C8.033 10.668 8 11.344 8 12C8 12.656 8.033 13.332 8.085 14H4.263C4.097 13.359 4 12.692 4 12C4 11.308 4.098 10.641 4.263 10ZM5.082 16H8.297C8.485 17.424 8.717 18.65 8.862 19.357C7.27413 18.6731 5.94871 17.496 5.082 16ZM10.986 19.928C10.7373 18.6244 10.5222 17.3147 10.341 16H13.659C13.4773 17.3146 13.2623 18.6243 13.014 19.928C12.681 19.971 12.344 20 12 20C11.656 20 11.319 19.971 10.986 19.928ZM15.138 19.357C15.283 18.65 15.515 17.424 15.703 16H18.918C18.0513 17.496 16.7259 18.6731 15.138 19.357ZM19.737 14H15.916C15.967 13.332 16 12.656 16 12C16 11.344 15.967 10.668 15.915 10H19.736C19.902 10.641 20 11.308 20 12C20 12.692 19.903 13.359 19.737 14Z"
																		fill="#3795BD"
																	/>
																</svg>
																<a
																	href={
																		"https://" +
																		issue.ticket?.client.domain! +
																		"." +
																		process.env.REACT_APP_DOMAIN
																	}
																	target="_blank"
																	rel="noreferrer"
																>
																	{"https://" +
																		issue.ticket?.client.domain! +
																		"." +
																		process.env.REACT_APP_DOMAIN}
																</a>
															</div>
														</div>
														<div className="text-texttitle mt-3">
															User Client
														</div>
														<div className="flex flex-col space-y-2 mt-1 text-white font-light text-textnormal">
															<div className="flex space-x-2 items-center">
																<img
																	className="flex-initial h-[24px] w-[24px] rounded-full"
																	src={generateLogoUser(issue.ticket?.user!)}
																/>
																<div>
																	{issue.ticket?.user.name!} (
																	{issue.ticket?.user.username!})
																</div>
															</div>
														</div>
														<div className="text-texttitle mt-3">Ticket</div>
														<div className="flex flex-col space-y-2 mt-1 text-white font-light text-textnormal">
															<div className="flex flex-row space-x-2 items-center">
																<svg
																	width="20"
																	height="20"
																	viewBox="2 2 24 24"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="min-h-max min-w-max"
																>
																	<path
																		d="M15 26.25V23.5938L21.625 16.9688L24.2812 19.625L17.6562 26.25H15ZM3.75 20V17.5H12.5V20H3.75ZM25.1562 18.75L22.5 16.0938L23.4062 15.1875C23.6354 14.9583 23.9271 14.8438 24.2812 14.8438C24.6354 14.8438 24.9271 14.9583 25.1562 15.1875L26.0625 16.0938C26.2917 16.3229 26.4062 16.6146 26.4062 16.9688C26.4062 17.3229 26.2917 17.6146 26.0625 17.8438L25.1562 18.75ZM3.75 15V12.5H17.5V15H3.75ZM3.75 10V7.5H17.5V10H3.75Z"
																		fill="#3795BD"
																	/>
																</svg>
																<div>{issue.ticket?.title!}</div>
															</div>
															<div className="flex space-x-2 items-center">
																<svg
																	width="20"
																	height="20"
																	viewBox="0 0 18 18"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="min-h-max min-w-max"
																>
																	<path
																		d="M1 8H7C7.26522 8 7.51957 7.89464 7.70711 7.70711C7.89464 7.51957 8 7.26522 8 7V1C8 0.734784 7.89464 0.48043 7.70711 0.292893C7.51957 0.105357 7.26522 0 7 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V7C0 7.26522 0.105357 7.51957 0.292893 7.70711C0.48043 7.89464 0.734784 8 1 8ZM11 8H17C17.2652 8 17.5196 7.89464 17.7071 7.70711C17.8946 7.51957 18 7.26522 18 7V1C18 0.734784 17.8946 0.48043 17.7071 0.292893C17.5196 0.105357 17.2652 0 17 0H11C10.7348 0 10.4804 0.105357 10.2929 0.292893C10.1054 0.48043 10 0.734784 10 1V7C10 7.26522 10.1054 7.51957 10.2929 7.70711C10.4804 7.89464 10.7348 8 11 8ZM1 18H7C7.26522 18 7.51957 17.8946 7.70711 17.7071C7.89464 17.5196 8 17.2652 8 17V11C8 10.7348 7.89464 10.4804 7.70711 10.2929C7.51957 10.1054 7.26522 10 7 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11V17C0 17.2652 0.105357 17.5196 0.292893 17.7071C0.48043 17.8946 0.734784 18 1 18ZM14 18C16.206 18 18 16.206 18 14C18 11.794 16.206 10 14 10C11.794 10 10 11.794 10 14C10 16.206 11.794 18 14 18Z"
																		fill="#3795BD"
																	/>
																</svg>
																<div className="capitalize">
																	{issue.ticket?.level!}
																</div>
															</div>
															<div className="flex space-x-2 items-center">
																<svg
																	width="20"
																	height="20"
																	viewBox="0 0 20 20"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="min-h-max min-w-max"
																>
																	<path
																		d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM10.5 5H9V11L14.25 14.15L15 12.92L10.5 10.25V5Z"
																		fill="#3795BD"
																	/>
																</svg>
																<div>
																	{moment(issue.ticket?.created_at!).format(
																		"dddd, DD-MM-YYYY HH:mm"
																	)}
																</div>
															</div>
														</div>
														<div className="text-texttitle mt-3">
															Assign to Technical
														</div>
														<div className="flex flex-col space-y-2 mt-1 text-white font-light text-textnormal">
															<div className="flex space-x-2 items-center">
																<svg
																	width="20"
																	height="20"
																	viewBox="0 0 20 20"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																	className="min-h-max min-w-max"
																>
																	<path
																		d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM10.5 5H9V11L14.25 14.15L15 12.92L10.5 10.25V5Z"
																		fill="#3795BD"
																	/>
																</svg>
																<div>
																	{moment(issue.created_at).format(
																		"dddd, DD-MM-YYYY HH:mm"
																	)}
																</div>
															</div>
														</div>
														<div className="flex flex-row mt-2 text-textnormal space-x-2">
															<div className="w-1/3">Handled By</div>
															<div>:</div>
															<div>{issue.assigned_to?.name!}</div>
														</div>
														<div className="flex flex-row mt-0.5 text-textnormal space-x-2">
															<div className="w-1/3">Agent</div>
															<div>:</div>
															<div>
																{issue.ticket?.response_by! &&
																	issue.ticket?.response_by.name!}
															</div>
														</div>
													</div>
													<div className="flex flex-intial flex-col mt-4 space-y-2">
													{issue.status !== "closed" && (
														<button
															type="button"
															className="flex-1 px-4 py-2 rounded-xl font-medium bg-biru text-white text-textnormal"
															onClick={() => {handleSaveState()}}
														>
															Save
														</button>
													)}
													{(issue.status === "closed" ||
														issue.status === "pending") &&
													!isReSubmit ? (
														<button
															type="submit"
															className="flex-1 px-4 py-2 rounded-xl font-medium bg-white text-black border border-biru text-textnormal"
															disabled
														>
															Mark As Resolve
														</button>
													) : (
														<button
															type="submit"
															className="flex-1 px-4 py-2 rounded-xl font-medium bg-biru text-white text-textnormal"
														>
															Mark As Resolve
														</button>
													)}
													</div>
												</div>
											</div>
										</form>
									)}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
