import useAuth from "../auth/useAuth";
import { useNavigate } from "react-router";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useAppSelector } from "../hooks";
import Button from "../components/Button";

export default function Login() {
	const navigate = useNavigate();
	const [auth, setAuth] = useState(useAuth());
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [pwVisible, setPwVisible] = useState(false);

	const authData = useAppSelector((state) => state.auth);

	useEffect(() => {
		if (authData.user) {
			navigate("/");
		}
		return () => {};
	}, [authData.user]);
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);
		auth?.login(username, password).then((user) => {
			if (user) {
				navigate("/");
			}
			setIsLoading(false);
		});
	};

	return authData.user == null ? (
		<div className="flex flex-col min-h-screen max-h-screen overflow-hidden w-full">
			<div className="flex flex-1">
				<img
					src="/login-bg.png"
					className="w-3/5 object-cover overflow-hidden"
				/>
				<div className="w-2/5 bg-white flex flex-col items-center px-20 2xl:px-32 py-5 2xl:py-20">
					<img src="/logo.png" className="w-[150px] h-[150px] mt-4" />
					<div className="flex justify-center font-extrabold text-[40px] 2xl:text-[64px] space-x-1">
						<div className="text-[#A1A1A1]">MII</div>
						<div className="text-[#A1A1A1]">-</div>
						<div className="text-[#472183]"> PORTAL</div>
					</div>
					<div className="text-black font-bold text-[14px] 2xl:text-[20px] -mt-2">
						MIDSUIT INNOVATION AND INTEGRATION PORTAL
					</div>

					<form onSubmit={handleSubmit} className="flex flex-col w-full mt-2">
						<div className="flex w-full items-center mt-12 relative">
							<input
								className="border-2 flex-1 border-gray-300 px-4 py-2 2xl:p-4 rounded-xl focus:border-gray-300 focus:outline-0 focus:outline-transparent focus:ring-0 text-[14px] 2xl:text-texttitle"
								type="text"
								placeholder="Username"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
							/>
							<svg
								viewBox="0 0 28 29"
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								className="w-6 h-6 -ml-12 text-black/50 absolute right-4"
							>
								<path d="M14 14.5007C17.8675 14.5007 21 11.4427 21 7.66732C21 3.8919 17.8675 0.833984 14 0.833984C10.1325 0.833984 7 3.8919 7 7.66732C7 11.4427 10.1325 14.5007 14 14.5007ZM14 17.9173C9.3275 17.9173 0 20.2065 0 24.7507V28.1673H28V24.7507C28 20.2065 18.6725 17.9173 14 17.9173Z" />
							</svg>
						</div>
						<div className="flex w-full items-center mt-4 relative">
							<input
								className="border-2 flex-1 border-gray-300 px-4 py-2 2xl:p-4 rounded-xl focus:border-gray-300 focus:outline-0 focus:outline-transparent focus:ring-0 text-[14px] 2xl:text-texttitle"
								placeholder="Password"
								value={password}
								type={pwVisible ? "text" : "password"}
								onChange={(e) => setPassword(e.target.value)}
							/>
							{pwVisible ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									onClick={() => setPwVisible(false)}
									className="w-6 h-6 text-black/50 -ml-12 absolute right-4 cursor-pointer"
								>
									<path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
									<path
										fillRule="evenodd"
										d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
										clipRule="evenodd"
									/>
								</svg>
							) : (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									onClick={() => setPwVisible(true)}
									className="w-6 h-6 text-black/50 -ml-12 absolute right-4 cursor-pointer"
								>
									<path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
									<path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
									<path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
								</svg>
							)}
						</div>
						<Button
							className="mx-auto mt-4 bg-[#472183] text-white font-bold text-[18px] 2xl:text-[25px] py-1 px-16 rounded-md focus:outline-0 focus:outline-transparent focus:ring-0"
							disabled={isLoading}
						>
							Login
						</Button>
					</form>
				</div>
			</div>
		</div>
	) : null;
}
