import React, { useEffect } from "react";
import { useUploadFileMutation } from "../services/API";

interface UploadImageProps {
	setPath: (path: string) => void;
	path: string;
	setChange: (change: boolean) => void;
}
export default function UploadImage(props: UploadImageProps) {
	const [image, setImage] = React.useState<string | null>(null);
	const [imageName, setImageName] = React.useState<string | null>(null);
	const [isImageUpload, setIsImageUpload] = React.useState<boolean>(false);
	const [uploadFile, { data: imagePath, isLoading: loadingUpload }] =
		useUploadFileMutation();
	const [imageFile, setImageFile] = React.useState<File | null>(null);
	const onImageChange = (event: any) => {
		if (
			event.target.files &&
			event.target.files[0] &&
			event.target.files[0].size <= 2e6
		) {
			if (isImageUpload) {
			}
			setImageName(event.target.files[0].name);
			setImage(URL.createObjectURL(event.target.files[0]));
			setImageFile(event.target.files[0]);
			props.setChange(true);
		} else {
			console.log("Something went wrong");
		}
	};

	const onDelete = () => {
		setImage("");
		setImageName("");
		setIsImageUpload(false);
	};

	useEffect(() => {
		if (imagePath) {
			props.setPath(imagePath);
		}
	}, [imagePath]);
	console.log(props.path);

	return (
		<div className="flex flex-row space-x-3">
			<div className="h-20 w-20">
				{image ? (
					<img className="w-20 h-20 flex-none" alt="" src={image} />
				) : (
					<img className="w-20 h-20 flex-none" alt="" src={props.path} />
				)}
			</div>
			<div className="flex flex-col flex-1">
				<div className="flex flex-row space-x-2">
					{image ? (
						<p className="flex-1 text-black bg-white text-md">{imageName}</p>
					) : (
						<input
							type="file"
							accept=".jpg, .png"
							onChange={(e) => onImageChange(e)}
							className="flex-1 text-black bg-white focus:ring-blue-300 rounded-lg text-sm py-1"
						/>
					)}
				</div>
				<p className="text-gray text-sm mt-2 mb-2 flex-auto">
					JPG or PNG. Max Size of 2MB
				</p>
				{isImageUpload ? (
					<div className="flex flex-row space-x-2">
						<input
							id="inputFile"
							type="file"
							accept=".jpg, .png"
							onChange={(e) => onImageChange(e)}
							className="flex-initial text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-1 py-1"
							hidden
						/>
						<label
							htmlFor="inputFile"
							className="flex-initial text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-1 py-1"
						>
							Upload New Picture
						</label>
						<button
							type="button"
							onClick={() => onDelete()}
							className="flex-initial text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-1 py-1"
						>
							Delete
						</button>
					</div>
				) : (
					<button
						type="button"
						className="flex-1 text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-1 py-1"
						onClick={() => {
							if (imageFile) uploadFile(imageFile);
							setIsImageUpload(true);
						}}
					>
						Upload
					</button>
				)}
			</div>
		</div>
	);
}
