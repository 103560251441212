import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { authSlice } from "./slices/auth";
import { loadState } from "./storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import clientdetail, { clientDetailSlice } from "./slices/clientdetail";
import { API } from "./services/API";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
} from "redux-persist/es/constants";
import { rtkQueryErrorLogger } from "./services/ErrorHandler";
import { globalSlice } from "./slices/state";
const persistConfig = {
	key: "root",
	storage,
};

const authPersist = persistReducer(persistConfig, authSlice.reducer);
const statePersist = persistReducer(persistConfig, globalSlice.reducer);
const clientDetailPersist = persistReducer(
	persistConfig,
	clientDetailSlice.reducer
);

const store = configureStore({
	reducer: {
		[API.reducerPath]: API.reducer,
		auth: authPersist,
		global: statePersist,
		clientdetail: clientDetailSlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		})
			.concat(API.middleware)
			.concat(rtkQueryErrorLogger),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
export const persistor = persistStore(store);
