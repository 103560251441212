import { createSlice } from "@reduxjs/toolkit";
import { Message } from "../services/types";
import { RootState } from "../store";

interface GlobalState {
	routeName: string;
}

const initialState: GlobalState = {
	routeName: "",
};

export const globalSlice = createSlice({
	name: "global",
	initialState,
	reducers: {
		setRouteName: (state, action) => {
			state.routeName = action.payload;
		},
	},
});

export const { setRouteName } = globalSlice.actions;
export default globalSlice.reducer;
