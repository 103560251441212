import React, {SVGProps} from "react";
import {getRouteByPath} from "../routes/RouteMenu";
import {setRouteName} from "../slices/state";
import {useAppDispatch} from "../hooks";
import {useNavigate} from "react-router";

interface MenuButtonProps {
    route: string;
    routeName?: string;
    children?: React.ReactNode;
}
export function LinkButton({route, routeName, children}: MenuButtonProps){
    const globalDispatch = useAppDispatch()
    const navigate = useNavigate()

    const goto = (path: string) => {
        let name = routeName ?? getRouteByPath(path)?.title
        globalDispatch(setRouteName(name))
        navigate(path)
    }

    return (
        <div onClick={()=>goto(route)}>
            {children}
        </div>
    )
}