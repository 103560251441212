import React, { useState } from "react";
import { Client, Message, User } from "../services/types";
import useAuth from "../auth/useAuth";
import moment from "moment";
import { useUpdateMessageMutation } from "../services/API";
import { omit } from "../utils/util";

interface IBubbleChatProps {
	message: Message;
	onMessageSelected?: any;
	setImgLoad: any;
	setImgOpen?: any;
	setImgUrl?: any;
}

export default function BubbleChat(props: IBubbleChatProps) {

	const [
		updateMessage,
		{ error: updateMessageError, isLoading: updateMessageLoading },
	] = useUpdateMessageMutation();

	const [openOption, setOpenOption] = useState(false);
	const [showOption, setShowOption] = useState(false);

	const onDownloadDocument = (download?: string) => {
		const link = document.createElement("a");
		if (download != null) {
			link.href =
				process.env.REACT_APP_API_URL +
				"/files/" +
				props.message.file +
				download;
		} else {
			link.href =
				process.env.REACT_APP_API_URL + "/files/" + props.message.file;
		}
		link.rel = "noreferrer";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const auth = useAuth();
	const generateLogo = (client: Client) => {
		if (client.logo_url?.length! > 0)
			return process.env.REACT_APP_API_URL + "/files/" + client.logo_url;
		let abbreviation = "";
		client.name.split(" ").forEach((word: string) => {
			if (word.indexOf("PT.") > -1) return;
			abbreviation += word[0];
		});
		abbreviation = abbreviation.toUpperCase();
		return (
			"https://ui-avatars.com/api/?name=" +
			abbreviation +
			"&background=472183&color=ffffff&length=" +
			abbreviation.length
		);
	};

	const generateLogoUser = (user: User) => {
		if (user.profile_picture?.length! > 0)
			return process.env.REACT_APP_API_URL + "/files/" + user.profile_picture;
		let abbreviation = "";
		user.name.split(" ").forEach((word: string) => {
			abbreviation += word[0];
		});
		abbreviation = abbreviation.toUpperCase();
		return (
			"https://ui-avatars.com/api/?name=" +
			abbreviation +
			"&background=472183&color=ffffff&length=" +
			abbreviation.length
		);
	};

	const deleteMessage = async (message:Message) => {
		if (message){
			let m = omit(message, ["channel", "user"])
			console.log(m)
			await updateMessage({
				...m,
				text: "Deleted Message",
				file: "",
				image: ""
			}).catch((error) => {
				console.log(error)
			})
		}
	}


	if (
		props.message !== null &&
		(props.message.image === null || props.message.image === "") &&
		(props.message.file === null || props.message.file === "")
	) {
		{
			if (props.message.user?.role_id! == 1) {
				return (
					<div className="flex flex-col ml-8 mr-auto min-w-[30%] max-w-[60%]">
						<div className="flex text-black items-center mb-1">
							<img
								src={generateLogoUser(props.message?.user!)}
								className="w-[30px] h-[30px] rounded-full"
							/>
							<div className="ml-4">{props.message?.user?.name}</div>
							<div className="ml-auto mr-4">
								{moment(props.message?.created_at).format("HH:mm")}
							</div>
						</div>
						<div className="p-4 bg-[#0F0420] text-white rounded-b-3xl rounded-tr-3xl break-all whitespace-pre-line">
							{props.message.text}
						</div>
					</div>
				);
			} else {
				return (
					<div className="flex flex-col mr-8 ml-auto min-w-[30%] max-w-[60%]"
						onClick={() => {
							if (props.message.text !== "Deleted Message"){
								setOpenOption(true)
							}
						}}
						onMouseEnter={() => { 
							if (props.message.text !== "Deleted Message"){
							setOpenOption(true)
						}}}
						onMouseLeave={() => setOpenOption(false)}
					>
						<div className="flex text-black items-center mb-1">
							<div className="mr-auto ml-4">
								{moment(props.message?.created_at).format("HH:mm")}
							</div>
							<div className="mr-4">{props.message?.user?.name}</div>
							<img
								src={generateLogoUser(props.message?.user!)}
								className="w-[30px] h-[30px] rounded-full"
							/>
						</div>
						<div className={(props.message.text === "Deleted Message" ? "text-gray-50 italic" : "text-white" ) 
						+ " p-4 bg-[#472183] rounded-b-3xl rounded-tl-3xl break-all whitespace-pre-line relative"}>
							{props.message.text}

							{openOption && (
								<div
									className="absolute right-1 top-1"
									onClick={() => setShowOption(true)}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M19.5 8.25l-7.5 7.5-7.5-7.5"
										/>
									</svg>
								</div>
							)}
							{showOption && (
								<div
									className="absolute flex flex-col rounded w-[140px] text-[12px] text-black bg-white font-medium space-y-1 right-0 z-50 top-[24px] drop-shadow-lg"
									onMouseLeave={() => setShowOption(false)}
									onClick={() => deleteMessage(props.message)}
								>
									<div className="hover:bg-bgfield hover:border-l-4 border- pl-4 cursor-pointer">
										Delete Message
									</div>
								</div>
							)}
						</div>
					</div>
				);
			}
		}
	}
	if (
		props.message !== null &&
		props.message.image !== null &&
		(props.message.file === null || props.message.file === "")
	) {
		{
			if (props.message.user_id !== auth?.user?.id) {
				return (
					<div
						className="flex flex-col ml-8 mr-auto min-w-[30%] max-w-[60%]"
						onClick={() => {
							props.setImgOpen(true);
							props.setImgUrl(
								process.env.REACT_APP_API_URL + "/files/" + props.message.image
							);
						}}
					>
						<div className="flex text-black items-center mb-1">
							<img
								src={generateLogoUser(props.message?.user!)}
								className="w-[30px] h-[30px] rounded-full"
							/>
							<div className="ml-4">{props.message?.user?.name}</div>
							<div className="ml-auto mr-4">
								{moment(props.message?.created_at).format("HH:mm")}
							</div>
						</div>
						<div
							className={
								"py-2 px-4 bg-[#0F0420] text-white  rounded-tr-3xl " +
								(props.message.text
									? "flex flex-col rounded-b-xl"
									: " rounded-b-3xl")
							}
						>
							<img
								onLoad={() => {
									props.setImgLoad((s: any) => [
										...s,
										{ url: props.message.image, state: true },
									]);
								}}
								className="rounded-b-xl rounded-tr-xl"
								src={
									process.env.REACT_APP_API_URL +
									"/files/" +
									props.message.image
								}
								alt=""
							/>
							{props.message.text && (
								<div className=" text-white break-all whitespace-pre-line">
									{props.message.text}
								</div>
							)}
						</div>
					</div>
				);
			} else {
				return (
					<div
						className="flex flex-col mr-8 ml-auto min-w-[30%] max-w-[60%]"
						onMouseEnter={() => {setOpenOption(true)}}
						onMouseLeave={() => {setOpenOption(false)}}
					>
						<div className="flex text-black items-center mb-1">
							<div className="mr-auto ml-4">
								{moment(props.message?.created_at).format("HH:mm")}
							</div>
							<div className="mr-4">{props.message?.user?.name}</div>
							<img
								src={generateLogoUser(props.message?.user!)}
								className="w-[30px] h-[30px] rounded-full"
							/>
						</div>
						<div
							className={
								"py-2 px-4 relative bg-[#472183] text-white rounded-tl-3xl " +
								(props.message.text
									? "flex flex-col rounded-b-xl"
									: " rounded-b-3xl")
							}
							onMouseEnter={() => {setOpenOption(true)}}
						>
							{openOption && (
									<div
									className="absolute right-1 top-1"
									onClick={() => setShowOption(true)}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M19.5 8.25l-7.5 7.5-7.5-7.5"
										/>
									</svg>
								</div>
							)}
							{showOption && (
								<div
									className="absolute flex flex-col rounded w-[140px] text-[12px] text-black bg-white font-medium space-y-1 right-0 z-50 top-[24px] drop-shadow-lg"
									onMouseLeave={() => setShowOption(false)}
									onClick={() => deleteMessage(props.message)}
								>
									<div className="hover:bg-bgfield hover:border-l-4 border- pl-4 cursor-pointer">
										Delete Message
									</div>
								</div>
							)}
							<img
								onClick={() => {
									props.setImgOpen(true);
									props.setImgUrl(
										process.env.REACT_APP_API_URL + "/files/" + props.message.image
									);
								}}
								onLoad={() => {
									props.setImgLoad((s: any) => [
										...s,
										{ url: props.message.image, state: true },
									]);
								}}
								className="rounded-b-xl rounded-tl-xl"
								src={
									process.env.REACT_APP_API_URL +
									"/files/" +
									props.message.image
								}
								alt=""
							/>
							{props.message.text && (
								<div className="text-white break-all whitespace-pre-line">
									{props.message.text}
								</div>
							)}
						</div>
					</div>
				);
			}
		}
	}
	if (
		props.message !== null &&
		(props.message.image === null || props.message.image === "") &&
		props.message.file !== null
	) {
		{
			if (props.message.user_id !== auth?.user?.id) {
				return (
					<div className="flex flex-col ml-8 mr-auto min-w-[30%] max-w-[60%]">
						<div className="flex text-black items-center mb-1">
							<img
								src={generateLogoUser(props.message?.user!)}
								className="w-[30px] h-[30px] rounded-full"
							/>
							<div className="ml-4">{props.message?.user?.name}</div>
							<div className="ml-auto mr-4">
								{moment(props.message?.created_at).format("HH:mm")}
							</div>
						</div>
						<div
							onClick={() => onDownloadDocument("?download=true")}
							className={
								"px-4 flex flex-col bg-[#0F0420] text-white rounded-tr-3xl cursor-pointer " +
								(props.message.text
									? "rounded-b-xl py-2"
									: "rounded-b-3xl py-4")
							}
						>
							<div className="flex bg-[#0F0420] text-white rounded-b-3xl rounded-tr-3xl items-center space-x-4">
								<svg
									width="40"
									height="48"
									viewBox="0 0 40 48"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="min-w-max min-h-max"
								>
									<path
										d="M5 0C2.25 0 0.0250006 2.25 0.0250006 5L0 45C0 47.75 2.225 50 4.975 50H35C37.75 50 40 47.75 40 45V15L25 0H5ZM22.5 17.5V3.75L36.25 17.5H22.5Z"
										fill="#9747FF"
									/>
								</svg>
								<div className="flex-1 flex flex-col break-all">
									<div>{props.message.file.replace("_"," ").split("-")[1]}</div>
								</div>
							</div>
							{props.message.text && (
								<div className="text-white break-all whitespace-pre-line">
									{props.message.text}
								</div>
							)}
						</div>
					</div>
				);
			} else {
				return (
					<div className="flex flex-col mr-8 ml-auto min-w-[30%] max-w-[60%]"
					onMouseEnter={() => {setOpenOption(true)}}
					onMouseLeave={() => {setOpenOption(false)}}>
						<div className="flex text-black items-center mb-1">
							<div className="mr-auto ml-4">
								{moment(props.message?.created_at).format("HH:mm")}
							</div>
							<div className="mr-4">{props.message?.user?.name}</div>
							<img
								src={generateLogoUser(props.message?.user!)}
								className="w-[30px] h-[30px] rounded-full"
							/>
						</div>
						<div
							className={
								"px-4 flex flex-col bg-[#472183] relative text-white rounded-tl-3xl cursor-pointer " +
								(props.message.text
									? "rounded-b-xl py-2"
									: "rounded-b-3xl py-4")
							}
							onMouseEnter={() => {setOpenOption(true)}}
						>
							{openOption && (
									<div
									className="absolute right-1 top-1"
									onClick={() => setShowOption(true)}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke-width="1.5"
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											d="M19.5 8.25l-7.5 7.5-7.5-7.5"
										/>
									</svg>
								</div>
							)}
							{showOption && (
								<div
									className="absolute flex flex-col rounded w-[140px] text-[12px] text-black bg-white font-medium space-y-1 right-0 z-50 top-[24px] drop-shadow-lg"
									onMouseLeave={() => setShowOption(false)}
									onClick={() => deleteMessage(props.message)}
								>
									<div className="hover:bg-bgfield hover:border-l-4 border- pl-4 cursor-pointer">
										Delete Message
									</div>
								</div>
							)}
							<div className="flex bg-[#472183] text-white rounded-b-3xl rounded-tl-3xl items-center space-x-4"
							onClick={() => onDownloadDocument("?download=true")}>
								<svg
									width="40"
									height="48"
									viewBox="0 0 40 48"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="min-w-max min-h-max"
								>
									<path
										d="M5 0C2.25 0 0.0250006 2.25 0.0250006 5L0 45C0 47.75 2.225 50 4.975 50H35C37.75 50 40 47.75 40 45V15L25 0H5ZM22.5 17.5V3.75L36.25 17.5H22.5Z"
										fill="#9747FF"
									/>
								</svg>
								<div className="flex-1 flex flex-col break-all">
									<div>{props.message.file.replace("_"," ").split("-")[1]}</div>
								</div>
							</div>
							{props.message.text && (
								<div className="text-white break-all whitespace-pre-line">
									{props.message.text}
								</div>
							)}
						</div>
					</div>
				);
			}
		}
	}

	return (
		<>
			{props.message !== null &&
				props.message.image === null &&
				props.message.file === null &&
				(props.message.user_id !== auth?.user?.id ? (
					<div className="flex flex-col ml-8 mr-auto min-w-[30%] max-w-[60%]">
						<div className="flex text-black items-center mb-1">
							<img
								src={generateLogoUser(props.message?.user!)}
								className="w-[30px] h-[30px] rounded-full"
							/>
							<div className="ml-4">{props.message?.user?.name}</div>
							<div className="ml-auto mr-4">
								{moment(props.message?.created_at).format("HH:mm")}
							</div>
						</div>
						<div className="p-4 bg-[#0F0420] text-white rounded-b-3xl rounded-tr-3xl break-all">
							{props.message.text}
						</div>
					</div>
				) : (
					<div className="flex flex-col mr-8 ml-auto min-w-[30%] max-w-[60%]">
						<div className="flex text-black items-center mb-1">
							<div className="mr-auto ml-4">
								{moment(props.message?.created_at).format("HH:mm")}
							</div>
							<div className="mr-4">{props.message?.user?.name}</div>
							<img
								src={generateLogoUser(props.message?.user!)}
								className="w-[30px] h-[30px] rounded-full"
							/>
						</div>
						<div className="p-4 bg-[#472183] text-white rounded-b-3xl rounded-tl-3xl break-all">
							{props.message.text}
						</div>
					</div>
				))}
			{props.message !== null &&
			props.message.image !== "" &&
			(props.message.file === "" || props.message.file === null) ? (
				props.message.user_id !== auth?.user?.id ? (
					<div className="flex flex-col ml-8 mr-auto min-w-[30%] max-w-[60%]">
						<div className="flex text-black items-center mb-1">
							<img
								src={generateLogoUser(props.message?.user!)}
								className="w-[30px] h-[30px] rounded-full"
							/>
							<div className="ml-4">{props.message?.user?.name}</div>
							<div className="ml-auto mr-4">
								{moment(props.message?.created_at).format("HH:mm")}
							</div>
						</div>
						<div className="p-4 bg-[#0F0420] text-white rounded-b-3xl rounded-tr-3xl break-all">
							{props.message.image}
						</div>
					</div>
				) : (
					<div className="flex flex-col mr-8 ml-auto min-w-[30%] max-w-[60%]">
						<div className="flex text-black items-center mb-1">
							<div className="mr-auto ml-4">
								{moment(props.message?.created_at).format("HH:mm")}
							</div>
							<div className="mr-4">{props.message?.user?.name}</div>
							<img
								src={generateLogoUser(props.message?.user!)}
								className="w-[30px] h-[30px] rounded-full"
							/>
						</div>
						<div className="p-4 bg-[#472183] text-white rounded-b-3xl rounded-tl-3xl break-all">
							{props.message.image}
						</div>
					</div>
				)
			) : null}
			{/* {props.message !== null &&
			props.message.image !== null &&
			props.message.user_id !== auth?.user?.id ? (
				<div className="flex flex-col ml-8 mr-auto min-w-[30%] max-w-[60%]">
					<div className="flex text-black items-center mb-1">
						<img
							src={generateLogoUser(props.message?.user!)}
							className="w-[30px] h-[30px] rounded-full"
						/>
						<div className="ml-4">{props.message?.user?.name}</div>
						<div className="ml-auto mr-4">
							{moment(props.message?.created_at).format("HH:mm")}
						</div>
					</div>
					<div className="p-4 bg-[#0F0420] text-white rounded-b-3xl rounded-tr-3xl break-all">
						{props.message.text}
					</div>
				</div>
			) : (
				<div className="flex flex-col mr-8 ml-auto min-w-[30%] max-w-[60%]">
					<div className="flex text-black items-center mb-1">
						<div className="mr-auto ml-4">
							{moment(props.message?.created_at).format("HH:mm")}
						</div>
						<div className="mr-4">{props.message?.user?.name}</div>
						<img
							src={generateLogoUser(props.message?.user!)}
							className="w-[30px] h-[30px] rounded-full"
						/>
					</div>
					<div className="p-4 bg-[#472183] text-white rounded-b-3xl rounded-tl-3xl break-all">
						{props.message.text}
					</div>
				</div>
			)} */}
		</>
	);
}
