import React, { useEffect, useState } from "react";
import { Client, Ticket, Channel, Message } from "../services/types";
import moment from "moment";
import {
	API,
	useLazyGetTicketQuery,
	useUpdateTicketMutation,
} from "../services/API";
import { omit, useMessageNotif } from "../utils/util";
import { useLocation, useNavigate } from "react-router";
import { getRouteByPath } from "../routes/RouteMenu";
import { setRouteName } from "../slices/state";
import { useAppDispatch } from "../hooks";
import auth from "../slices/auth";
import useAuth from "../auth/useAuth";
import { useDispatch } from "react-redux";

interface ITicketBoxProps {
	expand: boolean;
	holder: HTMLDivElement | null;
	newTicketDiv: HTMLDivElement | null;
	inProcessTicketDiv: HTMLDivElement | null;
	pendingTicketDiv: HTMLDivElement | null;
	holdTicketDiv: HTMLDivElement | null;
	closedTicketDiv: HTMLDivElement | null;
	expanded: number;
	selectedTicket?: Ticket;
	tickets: Ticket[];
	loadTicket: (id: number) => any;
	loadMessages: (channel: Channel) => any;
	onTicketSelected?: any;
	isTicketSelected?: any;
	clicked?: any;
	onClicked?: any;
}

export default function TicketBox(props: ITicketBoxProps) {
	const auth = useAuth();
	const [
		updateTicket,
		{ data: updateTicketData, isLoading: updateTicketLoading },
	] = useUpdateTicketMutation();

	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [counter, setCounter] = useState();

	const goto = (path: any) => {
		if (location.pathname === "/support/tickets") {
			location.hash = path;
			console.log(location);
		}
		navigate(`${location.pathname}#${location.hash}`);
	};

	const generateLogo = (client: Client) => {
		if (client.logo_url?.length! > 0)
			return process.env.REACT_APP_API_URL + "/files/" + client.logo_url;
		let abbreviation = "";
		client.name.split(" ").forEach((word: string) => {
			if (word.indexOf("PT.") > -1) return;
			abbreviation += word[0];
		});
		abbreviation = abbreviation.toUpperCase();
		return (
			"https://ui-avatars.com/api/?name=" +
			abbreviation +
			"&background=472183&color=ffffff&length=" +
			abbreviation.length
		);
	};

	useEffect(() => {
		if (`#${props.clicked}` === location.hash) {
			dispatch(API.util.invalidateTags([{ type: "Tickets", id: "LIST" }]));
			console.log("testDispatch");
		}
	}, [props.selectedTicket]);

	useEffect(() => {
		if (location.hash !== null) {
			let ticket = props.tickets.find(
				(ticket) => `#${ticket.id}` === location.hash
			);
			if (ticket) {
				props.onTicketSelected(true);
				props.onClicked(ticket.id);
				props.loadMessages(ticket.channel).unwrap();
				props.loadTicket(ticket.id).unwrap();
				dispatch(API.util.invalidateTags([{ type: "Tickets", id: "LIST" }]));
			}
		}
	}, [location]);

	return (
		<div className={(props.expand ? "block " : "hidden ") + "w-full"}>
			<div
				className={
					"w-full flex flex-col transition-all duration-500 ease-in-out space-y-1 pr-2"
				}
			>
				{props.tickets.map((ticket, index) => (
					<div
						className={
							(props.clicked === ticket.id ? "bg-[#3795BD]" : "bg-white ") +
							" p-2 rounded-2xl" +
							" flex flex-col cursor-pointer"
						}
						onClick={async () => {
							await goto(ticket.id!);
						}}
						key={index}
					>
						<>
							<div className={"flex relative"} key={index}>
								{ticket.messages.filter(
									(message) =>
										message.user_id !== auth?.user?.id! && !message.is_read
								).length! >= 1 && (
									<div
										className={
											(props.clicked === ticket.id ? "hidden" : "") +
											" absolute rounded-full bg-red-600 text-white flex h-6 w-6 text-[12px] justify-center items-center"
										}
									>
										{
											ticket.messages.filter(
												(message) =>
													message.user_id !== auth?.user?.id! &&
													!message.is_read
											).length!
										}
									</div>
								)}
								<img
									src={generateLogo(ticket.client!)}
									className="w-auto h-auto ml-2 my-auto"
								/>
								<div className="flex flex-col flex-1 min-w-0 ml-2 mr-4">
									<div className="flex items-center">
										<div className="flex-1 text-[14px] 2xl:text-[18px] font-semibold">
											{ticket.user != undefined ? ticket.user.name : ""}
										</div>
										<div className="ml-auto text-[10px] 2xl:text-[14px] font-semibold text-[#472183] capitalize">
											{ticket.level} #{ticket.id}
										</div>
									</div>
									<p className="text-[14px] whitespace-nowrap text-ellipsis overflow-hidden">
										{ticket.title}
									</p>
									<div className="flex items-center justify-between py-1">
										<div className="text-[13px] font-semibold">
											{moment(ticket.created_at).fromNow()}
										</div>
									</div>
								</div>
							</div>
							<div className="flex flex-row justify-end text-[10px] 2xl:text-[14px] font-[600] px-2 space-x-2">
								{ticket.is_confirmed && (
									<div className="px-2 bg-[#E2B203] text-white capitalize rounded">
										Confirmed
									</div>
								)}
								{ticket.category === "question" && (
									<div className="px-2 bg-[#04B700] text-white capitalize rounded">
										{ticket.category}
									</div>
								)}
								{ticket.category === "incident" && (
									<div className="px-2 bg-[#B40606] text-white capitalize rounded">
										{ticket.category}
									</div>
								)}
								{ticket.category === "problem" && (
									<div className="px-2 bg-[#94C748] text-white capitalize rounded">
										{ticket.category}
									</div>
								)}
								{ticket.category === "feature_request" && (
									<div className="px-2 bg-white border border-black text-white capitalize rounded">
										{ticket.category}
									</div>
								)}
								{ticket.priority === "critical" && (
									<div className="px-2 bg-[#570606] text-white capitalize rounded">
										{ticket.priority}
									</div>
								)}
								{ticket.priority === "high" && (
									<div className="px-2 bg-[#B40606] text-white capitalize rounded">
										{ticket.priority}
									</div>
								)}
								{ticket.priority === "medium" && (
									<div className="px-2 bg-[#E2B203] text-white capitalize rounded">
										{ticket.priority}
									</div>
								)}
								{ticket.priority === "low" && (
									<div className="px-2 bg-[#475706] text-white capitalize rounded">
										{ticket.priority}
									</div>
								)}
								{ticket.priority === "planning" && (
									<div className="px-2 bg-[#94C748] text-white capitalize rounded">
										{ticket.priority}
									</div>
								)}
							</div>
						</>
					</div>
				))}
			</div>
		</div>
	);
}
