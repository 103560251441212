import { useState, useRef, Fragment, useEffect } from "react";
import BaseTable, { CustomColumn } from "../../components/BaseTable";
import {
	useCreateKnowledgeBaseMutation,
	useDeleteAgentMutation,
	useDeleteKnowledgeBaseMutation,
	useGetKnowledgeBasesQuery,
	useUpdateAgentMutation,
	useUpdateKnowledgeBaseMutation,
} from "../../services/API";
import { RowSelectionState, createColumnHelper } from "@tanstack/table-core";
import { KnowledgeBase, Ticket } from "../../services/types";
import Swal from "sweetalert2";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../components/Button";
import { omit } from "../../utils/util";
import { useLocation, useNavigate } from "react-router";

export default function KnowledgeBasePage() {
	const {
		data: knowledgebases,
		error: knowledgebasesError,
		isLoading: knowledgebasesIsLoading,
	} = useGetKnowledgeBasesQuery();
	const [
		createKnowledgeBase,
		{
			error: createKnowledgeBaseError,
			isLoading: createKnowledgeBaseIsLoading,
		},
	] = useCreateKnowledgeBaseMutation();
	const [
		updateKnowledgeBase,
		{
			error: updateKnowledgeBaseError,
			isLoading: updateKnowledgeBaseIsLoading,
		},
	] = useUpdateKnowledgeBaseMutation();
	const [
		deleteKnowledgeBase,
		{
			error: deleteKnowledgeBaseError,
			isLoading: deleteKnowledgeBaseIsLoading,
		},
	] = useDeleteKnowledgeBaseMutation();

	const [knowledgebase, setKnowledgebase] = useState<KnowledgeBase | null>(
		null
	);

	const [selectState, setSelectState] = useState<RowSelectionState>({});
	const [selectedRow, setSelectedRow] = useState<number>(-1);

	const [topic, setTopic] = useState("");
	const [solution, setSolution] = useState("");
	const [isAddingKB, setIsAddingKB] = useState(false);
	const cancelAddingKB = useRef(null);
	const [isDeletingKnowledgeBase, setIsDeletingKnowledgeBase] = useState(false);
	const [issueType, setIssueType] = useState("");
	const columnHelper = createColumnHelper<any>();
	const [isDeleteKB, setIsDeleteKB] = useState(false);
	const cancelDeleteKB = useRef(null);
	const [updateKB, setUpdateKB] = useState(false);
	const cancelUpdateRef = useRef(null);
	const [isViewKB, setIsViewKB] = useState(false);
	const cancelViewKB = useRef(null);
	const [ticketDetails, setTicketDetails] = useState<Ticket[]>();

	const [selectedIssueType, setSelectedIssueType] = useState<string[]>([]);
	const [filteredKBase, setFilteredKBase] = useState<KnowledgeBase[]>([]);

	const customColumns: CustomColumn[] = [
		{
			column: columnHelper.accessor("issue_type", {
				header: "Issue Type",
				size: Infinity,
				cell: (props) => {
					return (
						<div className="flex items-center">
							<div className="capitalize">{props.getValue()}</div>
						</div>
					);
				},
			}),
			position: 2,
		},
	];

	const handleAddKnowledgeBase = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		event.preventDefault();
		if (knowledgebase) {
			let k = {
				...knowledgebase,
				topic: topic,
				issue_type: issueType,
				solution: solution,
			};
			k = omit(k, ["tickets"]);
			await updateKnowledgeBase(k).unwrap();
			await Swal.fire({
				toast: true,
				position: "top-end",
				icon: "success",
				title: "Knowledge Base is Updated",
				showConfirmButton: false,
				timer: 1500,
			});
		} else {
			await createKnowledgeBase({
				issue_type: issueType,
				topic: topic,
				solution: solution,
			}).unwrap();
			await Swal.fire({
				toast: true,
				position: "top-end",
				icon: "success",
				title: "Knowledge Base is Added",
				showConfirmButton: false,
				timer: 1500,
			});
		}
		setIsAddingKB(false);
		setIssueType("");
		setTopic("");
		setSolution("");
	};

	const editingKnowledgeBase = async (kbId: number) => {
		let kb = knowledgebases?.find((kb) => kb.id === kbId)!;
		if (kb) {
			setKnowledgebase(kb);
			setIssueType(kb?.issue_type!);
			setTopic(kb.topic);
			setSolution(kb?.solution!);
			setIsAddingKB(true);
		}
	};

	const handleViewIssueDetails = async (kbId: number) => {
		let kb = knowledgebases?.find((kb) => kb.id === kbId)!;
		if (!kb) return;
		if (!kb.tickets) return;
		setIsViewKB(true);
		setTicketDetails(kb.tickets);
		console.log(ticketDetails)		
		setIssueType(kb?.issue_type!);
	};

	const deletingKnowledgeBase = (kbId: number) => {
		if (isDeletingKnowledgeBase) {
			deleteKnowledgeBase(kbId)
				.unwrap()
				.then((r) => {
					Swal.fire({
						toast: true,
						position: "top-end",
						icon: "success",
						title: "Agent has been deleted",
						timer: 3000,
						showConfirmButton: false,
					});
				});
			setIsDeletingKnowledgeBase(false);
		} else {
			setIsDeletingKnowledgeBase(true);
		}
	};

	const navigate = useNavigate();
	const location = useLocation();

	const handleViewTicket = (id: number) => {
		const route = "tickets#" + id;
		window.open(route, "_blank");
		location.hash = id.toString();
		console.log(location);
		navigate(`${location.pathname}#${location.hash}`);
	};

	useEffect(() => {
		let fKBase: KnowledgeBase[] = [];
		if (knowledgebases) {
			fKBase = knowledgebases;
			if (selectedIssueType.length >= 1) {
				fKBase = fKBase.filter((KB) =>
					selectedIssueType.includes(KB?.issue_type!)
				);
			}
		}
		console.log(selectedIssueType);
		setFilteredKBase(fKBase);
		console.log(fKBase);
	}, [knowledgebases, selectedIssueType]);

	if (knowledgebasesIsLoading) {
		return (
			<div className="flex justify-center items-center h-[90%]">
				<div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900" />
			</div>
		);
	}

	return (
		<>
			<div className="flex flex-col w-full mx-auto h-full">
				<BaseTable
					data={filteredKBase!}
					columns={["Topic:topic", "Solution:solution"]}
					customColumns={customColumns}
					onRowSelectChange={setSelectState}
					onRowSelect={setSelectedRow}
					addFunction={() => {
						setIssueType("");
						setTopic("");
						setSolution("");
						setIsAddingKB(true);
						setKnowledgebase(null);
					}}
					useActions={true}
					useEdit={true}
					// useDelete={true}
					editFunction={editingKnowledgeBase}
					deleteFunction={deleteKnowledgeBase}
					deleteRow={deletingKnowledgeBase}
					useButton={true}
					// useFilterKBase={setSelectedIssueType}
					// KBase={selectedIssueType}
					// useButtonKBase={true}
					useObsecure={true}
					obSecureFunction={handleViewIssueDetails}
				/>
			</div>
			<Transition.Root appear show={isAddingKB} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-50"
					initialFocus={cancelAddingKB}
					onClose={() => setIsAddingKB(false)}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl px-4 pt-5 pb-4 sm:p-6 sm:pb-4 sm:flex sm:items-start sm:max-w-auto bg-white text-left align-middle shadow-xl transition-all">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
										<Dialog.Title
											as="h1"
											className="leading-6 text-textmenu text-black text-center font-bold font-montserrat"
										>
											Problem Solving
										</Dialog.Title>
										<form
											onSubmit={(e) => {
												handleAddKnowledgeBase(e);
											}}
										>
											<div className="mt-2">
												<div className="flex-col items-center space-y-2">
													<div className="flex-1">
														<div>Select Type Issue</div>
														<select
															disabled={knowledgebase !== null ? true : false}
															value={issueType}
															onChange={(e) => setIssueType(e.target.value)}
															required
															className="mt-1 bg-bgfield border-none outline-none text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2"
														>
															<option value="" hidden>
																Select Option
															</option>
															<option value="server">Server</option>
															<option value="data">Data</option>
															<option value="laporan">Laporan</option>
															<option value="user role">User & Role</option>
															<option value="printout">Printout</option>
															<option value="process">Process</option>
														</select>
													</div>
													<div className="flex-1">
														<div>Topic</div>
													</div>
													<div className="flex-1">
														<textarea
															value={topic}
															onChange={(e) => setTopic(e.target.value)}
															rows={4}
															className="mt-1 bg-bgfield border-none outline-none text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 resize-none"
														></textarea>
													</div>
													<div className="flex-1">
														<div>Solution</div>
													</div>

													<div className="flex-1">
														<textarea
															value={solution}
															onChange={(e) => setSolution(e.target.value)}
															rows={4}
															className="mt-1 bg-bgfield border-none outline-none text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 resize-none"
														></textarea>
													</div>

													<div className="flex justify-center space-x-6 pt-3">
														<Button
															type="button"
															ref={cancelAddingKB}
															onClick={() => setIsAddingKB(false)}
															className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-lg text-textnormal px-5 py-2.5 outline-none"
														>
															Cancel
														</Button>
														<Button
															type="submit"
															className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-lg text-textnormal px-5 py-2.5 outline-none"
														>
															Save
														</Button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
			<Transition.Root appear show={isDeletingKnowledgeBase} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={cancelDeleteKB}
					onClose={() => {}}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl px-4 pt-5 pb-4 sm:p-6 sm:pb-4 sm:flex sm:items-start sm:max-w-auto bg-white text-left align-middle shadow-xl transition-all">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
										<div className="flex justify-center">
											<svg
												width="123"
												height="123"
												viewBox="0 0 123 123"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M66.625 66.625H56.375V35.875H66.625M66.625 87.125H56.375V76.875H66.625M61.5 10.25C54.7698 10.25 48.1054 11.5756 41.8875 14.1512C35.6695 16.7267 30.0198 20.5018 25.2608 25.2608C15.6495 34.872 10.25 47.9077 10.25 61.5C10.25 75.0923 15.6495 88.128 25.2608 97.7392C30.0198 102.498 35.6695 106.273 41.8875 108.849C48.1054 111.424 54.7698 112.75 61.5 112.75C75.0923 112.75 88.128 107.35 97.7392 97.7392C107.35 88.128 112.75 75.0923 112.75 61.5C112.75 54.7698 111.424 48.1054 108.849 41.8875C106.273 35.6695 102.498 30.0198 97.7392 25.2608C92.9802 20.5018 87.3305 16.7267 81.1125 14.1512C74.8946 11.5756 68.2302 10.25 61.5 10.25Z"
													fill="#472183"
												/>
											</svg>
										</div>

										<Dialog.Title
											as="h1"
											className="leading-6 text-5xl text-black text-center font-bold font-montserrat mt-4"
										>
											DELETE
										</Dialog.Title>

										<Dialog.Title
											as="h3"
											className="leading-6 text-xl text-gray-900 text-center font-montserrat mt-4"
										>
											Do you really want to delete this data ?
										</Dialog.Title>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												deletingKnowledgeBase(knowledgebases![selectedRow].id!);
											}}
										>
											<div className="flex justify-center space-x-6 pt-3">
												<Button
													type="button"
													className="text-black hover:text-white bg-white hover:bg-ungutext border border-ungutext font-medium rounded-3xl text-sm px-8 py-2.5 shadow"
													onClick={() => setIsDeletingKnowledgeBase(false)}
													ref={cancelDeleteKB}
												>
													Cancel
												</Button>
												<Button
													type="submit"
													className="text-white bg-ungutext border border-ungutext font-medium rounded-3xl text-sm px-8 py-2.5 shadow-2xl"
												>
													Delete
												</Button>
											</div>
										</form>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
			<Transition.Root appear show={isViewKB} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-50"
					initialFocus={cancelViewKB}
					onClose={() => setIsViewKB(false)}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl px-4 pt-5 pb-4 sm:p-6 sm:pb-4 sm:flex sm:items-start sm:max-w-auto bg-white text-left align-middle shadow-xl transition-all">
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
										<Dialog.Title
											as="h1"
											className="leading-6 text-textmenu text-black text-center font-bold font-montserrat"
										>
											Issue Detail
										</Dialog.Title>
										<form
											onSubmit={(e) => {
												handleAddKnowledgeBase(e);
											}}
										>
											<div className="mt-2">
												<div className="flex-col items-center space-y-2">
													<div className="flex-1">
														<div>Issue Type</div>
														<div className="mt-1 bg-bgfield border-none outline-none text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 capitalize">
															{issueType}
														</div>

														{ticketDetails?.length! > 0 && (
															<div className="my-4">Tickets Detail</div>
														)}
														{ticketDetails &&
															ticketDetails.map((ticket) => (
																<div
																	className="flex flex-col w-full cursor-pointer rounded-md p-2 my-2 bg-white hover:bg-[#3795BD] hover:text-white"
																	onClick={() => {
																		handleViewTicket(ticket.id);
																	}}
																>
																	<div className="capitalize">{`${ticket.level} #${ticket.id} -> ${ticket.title}`}</div>
																</div>
															))}
														{/* <select
															value={issueType}
															onChange={(e) => setIssueType(e.target.value)}
															required
															className="mt-1 bg-bgfield border-none outline-none text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2"
														>
															<option value="" hidden>
																Select Option
															</option>
															<option value="server">Server</option>
															<option value="data">Data</option>
															<option value="laporan">Laporan</option>
															<option value="user_role">User & Role</option>
															<option value="printout">Printout</option>
															<option value="process">Process</option>
														</select> */}
													</div>
													{/* <div className="flex-1">
														<div>Topic</div>
													</div>
													<div className="flex-1">
														<textarea
															value={topic}
															onChange={(e) => setTopic(e.target.value)}
															rows={4}
															className="mt-1 bg-bgfield border-none outline-none text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 resize-none"
														></textarea>
													</div>
													<div className="flex-1">
														<div>Solution</div>
													</div>

													<div className="flex-1">
														<textarea
															value={solution}
															onChange={(e) => setSolution(e.target.value)}
															rows={4}
															className="mt-1 bg-bgfield border-none outline-none text-gray-900 text-textnormal rounded-md block w-full pt-1 pb-1 pl-2 pr-2 resize-none"
														></textarea>
													</div> */}
												</div>
											</div>
										</form>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
