import { createColumnHelper } from "@tanstack/react-table";
import useAuth from "../../auth/useAuth";
import BaseTable from "../../components/BaseTable";
import { useGetIssuesQuery, useUpdateIssueMutation } from "../../services/API";
import { Client, Issue } from "../../services/types";
import { useMemo } from "react";
import { omit } from "../../utils/util";

export default function AllIssue() {
    const auth = useAuth();
    const {
		data: issues,
		error: issuesError,
		isLoading: issuesLoading,
	} = useGetIssuesQuery(auth?.user?.id!);
    const [updateIssue, { isLoading: updateIssueLoading }] = useUpdateIssueMutation();
    const generateLogo = (client: Client) => {
		if (client.logo_url?.length! > 0)
			return process.env.REACT_APP_API_URL + "/files/" + client.logo_url;
		let abbreviation = "";
		client.name.split(" ").forEach((word: string) => {
			if (word.indexOf("PT.") > -1) return;
			abbreviation += word[0];
		});
		abbreviation = abbreviation.toUpperCase();
		return (
			"https://ui-avatars.com/api/?name=" +
			abbreviation +
			"&background=472183&color=ffffff&length=" +
			abbreviation.length
		);
	};
    const columnHelper = createColumnHelper<Issue>();
    const customColumns = useMemo(
        ()=>[
            {
				column: columnHelper.accessor("ticket.client.name", {
					header: "Client",
					size: Infinity,
					cell: (props) => {
						return (
							<div className="flex items-center">
								<img
									src={generateLogo(props.row.original.ticket?.client!)}
									className="w-8 h-8 mr-2"
								/>
								<div>{props.getValue()}</div>
							</div>
						);
					},
				}),
				position: 2,
			},
            {
				column: columnHelper.accessor("request", {
					header: "Title",
					size: 200,
					cell: (props) => {
						let title = props.getValue();
						if (title.length > 30) {
							title = title.substring(0, 30) + "...";
						}
						return (
							<div>{title}</div>
						);
					},
				}),
				position: 3,
			},
            {
                column: columnHelper.accessor("priority_level", {
                    header: "Priority",
                    size: 100,
                    cell: (props) => {
                        let color = "bg-green-500";
                        switch (props.getValue()) {
                            //5 level
                            case 1:
                                color = "bg-green-500";
                                break;
                            case 2:
                                color = "bg-yellow-400";
                                break;
                            case 3:
                                color = "bg-yellow-600";
                                break;
                            case 4:
                                color = "bg-red-400";
                                break;
                            case 5:
                                color = "bg-red-600";
                                break;
                            default:
                                color = "bg-green-500";
                        }
                        return (
                            <div className="flex items-center">
                                <div className={`rounded-xl p-2 text-black font-bold uppercase ${color}`}>{props.row.original.ticket?.priority}</div>
                            </div>
                        )
                    }
                }),
                position: 6
            },
            {
                column: columnHelper.accessor("status", {
                    header: "Status",
                    size: 200,
                    cell: (props) => {
                        return (
                            <select className="w-full text-black" defaultValue={props.getValue()} onChange={async(e)=>{
                                let i = {
                                    ...props.row.original,
                                    id: props.row.original.id,
                                    status: e.target.value,
                                };
                                i = omit(i, ["assigned_to", "channel", "ticket"]);
                                await updateIssue(i);
                            }}>
                                <option value="open">Open</option>
                                <option value="process">Process</option>
                                <option value="hold">Hold</option>
                                <option value="closed">Close</option>
                            </select>
                        )
                    }
                }),
                position: 7
            }
        ]
        ,[columnHelper])

    return (
        <>
            <div className="flex flex-col w-full mx-auto h-full">
                {issuesLoading ? (
                    <div className="items-center justify-center rounded flex h-full">
						<div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900" />
					</div>
                ):(
                    <>
                       <BaseTable
                        data={issues!}
                        columns={["Technical:100:assigned_to.name","Functional:100:ticket.response_by.name"]}
                        customColumns={customColumns}
                        useButton={false}
						useSearch={true}
                       >
                       </BaseTable>
                    </>
                )}
            </div>
        </>
    )
}