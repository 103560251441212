import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../store";

interface ClientdetailState {
    client: any
    applications: any[]
    application: any
    erp: any
    erpConfig: any
    users: any[]
}

const initialState: ClientdetailState = {
    applications: [],
    application: null,
    client: null,
    erp: null,
    erpConfig: null,
    users: [],
}

export const clientDetailSlice = createSlice({
    "name": "clientdetail",
    initialState,
    reducers: {
        setClient: (state, action) => {
            state.client = action.payload
        },
        setErp: (state, action) => {
            state.erp = action.payload
        },
        setErpConfig: (state, action) => {
            state.erpConfig = action.payload
        },
        addApplication: (state, action) => {
            state.applications.push(action.payload)
        },
        removeApplication: (state, action) => {
            state.applications = state.applications.filter((app) => app.id !== action.payload)
        },
        setApplications: (state, action) => {
            state.applications = action.payload
        },
        setApplication:(state,action)=>{
            state.application = action.payload
        },
        addUser: (state, action) => {
            if (state.users.findIndex((user) => user.id === action.payload.id) === -1) {
                state.users.push(action.payload)
            }
            state.users = state.users.sort((a, b) => a.name.localeCompare(b.name))
        },
        removeUser: (state, action) => {
            state.users = state.users.filter((user) => user.id !== action.payload)
            state.users = state.users.sort((a, b) => a.name.localeCompare(b.name))
        },
        setUsers: (state, action) => {
            state.users = action.payload
            state.users = state.users.sort((a, b) => a.name.localeCompare(b.name))
        },
        setUserCredentials: (state, action) => {
            let idx = state.users.findIndex((user) => user.id === action.payload.id)
            if (idx !== -1) {
                state.users[idx].credentials = action.payload.credentials
            }
        }
    }
})

export default clientDetailSlice.reducer
export const {
    setClient,
    setErp,
    setErpConfig,
    setApplications,
    setApplication,
    setUserCredentials
} = clientDetailSlice.actions
export const selectClientDetail = (state: RootState) => state.clientdetail