import axios, { AxiosInstance } from "axios";

class Api {
	private static BASE_URL = "https://integra."+process.env.REACT_APP_DOMAIN;
	public static getClient(): AxiosInstance {
		return axios.create({
			baseURL: this.BASE_URL,
			withCredentials: true,
		});
	}
}
export default Api;
