import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyBc7hVh3-96L5le2wyiPYmu-8KP0-S3z4c",
    authDomain: "midsuit.firebaseapp.com",
    projectId: "midsuit",
    storageBucket: "midsuit.appspot.com",
    messagingSenderId: "550748865912",
    appId: "1:550748865912:web:fae56af7e27681d0b9748d"
  };

// console.log('*** Environment ***', process.env.REACT_APP_ENV)
// console.log('*** Firebase Config ***', firebaseConfig)

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
export const db = getFirestore(firebaseApp);

export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
          scope: '/firebase-push-notification-scope',
        });
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker()
    .then((serviceWorkerRegistration) =>
      getToken(messaging, { vapidKey: "BCR_3I1oeDBgWB_Bl_0RCE2OGH1SdqWzRsrhh9ufeAMladGu40qq3A1KsipNlKJUfK19Zy04ogtfgRBZouU34S8", serviceWorkerRegistration }));