import React, { useEffect } from "react";
import "./App.css";
import AuthProvider from "./auth/AuthProvider";
import { BrowserRouter } from "react-router-dom";
import RouteMenu from "./routes/RouteMenu";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import Pusher from "pusher-js";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { useGetTicketsQuery } from "./services/API";
import useAuth from "./auth/useAuth";
function App() {
	useEffect(() => {
		if (!window.pusher) {
			//@ts-ignore
			Pusher.Runtime.createXHR = function () {
				var xhr = new XMLHttpRequest();
				xhr.withCredentials = true;
				return xhr;
			};
			window.pusher = new Pusher("blackops123", {
				httpHost: "ws." + process.env.REACT_APP_DOMAIN,
				wsHost: "ws." + process.env.REACT_APP_DOMAIN,
				wssPort: 443,
				disableStats: true,
				enabledTransports: ["ws", "wss"],
				forceTLS: true,
				cluster: "",
				authEndpoint: process.env.REACT_APP_API_URL + "/pusher/auth",
				authTransport: "ajax",
			});
		}
		if (!window.messageNotification) {
			window.messageNotification = [];
		}
	}, []);

	useEffect(() => {});

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<AuthProvider>
					<BrowserRouter>
						<div className="flex flex-col min-h-screen bg-[#CDCDCD] h-full">
							<RouteMenu />
						</div>
					</BrowserRouter>
				</AuthProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
