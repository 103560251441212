import { Route, RouteProps, Routes } from "react-router";
import React, { SVGProps } from "react";
import PrivateRoute from "./PrivateRoute";
import Login from "../pages/Login";
import ClientPage from "../pages/blackops/client/Index";
import Dashboard from "../pages/blackops/Dashboard";
import UserPage from "../pages/blackops/User";
import ClientUserPage from "../pages/blackops/client/User";

import TicketPage from "../pages/support/Ticket";
import SupportAgentPage from "../pages/support/Agent";
import { useAppDispatch, useAppSelector } from "../hooks";
import Menu from "../components/Menu";
import store from "../store";
import { setRouteName } from "../slices/state";
import Application from "../pages/blackops/client/Application";
import IssueLogPage from "../pages/support/IssueLog";
import Setting from "../pages/Setting";
import KnowledgeBasePage from "../pages/support/KnowledgeBase";
import AllTicketPage from "../pages/support/AllTicket";
import AllIssue from "../pages/support/AllIssue";
import AddonPage from "../pages/blackops/Addon";
interface RouteInterface {
	title: string;
	path: string;
	role?: number;
	component: React.ComponentType<RouteProps>;
}

//list of routes, note that title can be overridden using global state or  <LinkButton routeName="Custom Title">
export const routes: RouteInterface[] = [
	{ title: "Login", path: "/login", component: Login },
	{
		title: "Blackops > Dashboard",
		path: "/blackops/dashboard",
		role: 1,
		component: Dashboard,
	},
	{
		title: "Blackops > Clients",
		path: "/blackops/clients",
		role: 1,
		component: ClientPage,
	},
	{
		title: "Blackops > Clients > Applications",
		path: "/blackops/clients/:clientID/applications",
		role: 1,
		component: Application,
	},
	{
		title: "Blackops > Clients > Users",
		path: "/blackops/clients/:clientID/users",
		role: 1,
		component: ClientUserPage,
	},
	{
		title: "Blackops > Addons",
		path: "/blackops/addons",
		role: 3,
		component: AddonPage,
	},
	{
		title: "Blackops > Users",
		path: "/blackops/users",
		role: 1,
		component: UserPage,
	},
	{
		title: "Support > Tickets",
		path: "/support/tickets",
		role: 1,
		component: TicketPage,
	},
	{
		title: "Support > All Tickets",
		path: "/support/all-tickets",
		role: 2,
		component: AllTicketPage,
	},
	{
		title: "Support > IssuesLog",
		path: "/support/issueslog",
		role: 1,
		component: IssueLogPage,
	},
	{
		title: "Support > All Issue",
		path: "/support/all-issues",
		role: 2,
		component: AllIssue,
	},
	{
		title: "Support > Agent",
		path: "/support/agents",
		role: 1,
		component: SupportAgentPage,
	},
	{
		title: "Support > Knowledge Base",
		path: "/support/knowledge-base",
		role: 1,
		component: KnowledgeBasePage,
	},
	{ title: "Settings", path: "/settings", role: 1, component: Setting },
	{
		title: "Not Found",
		path: "*",
		component: () => (
			<Menu>
				<h1>404 Not Found</h1>
			</Menu>
		),
	},
];

export function getRouteByPath(path: string): RouteInterface | undefined {
	return routes.find((route) => route.path === path);
}

function RedirectToDashboard() {
	window.location.href = "/blackops/dashboard";
	return <></>;
}

export default function RouteMenu() {
	return (
		<Routes>
			<Route path="/" element={<RedirectToDashboard />} />
			{routes.map((route, index) => {
				return (
					<Route
						key={index}
						path={route.path}
						element={
							route.role === undefined ? (
								<route.component />
							) : (
								<PrivateRoute role={route.role}>
									<route.component />
								</PrivateRoute>
							)
						}
					/>
				);
			})}
		</Routes>
	);
}
