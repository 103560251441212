import React, { SVGProps } from "react";
import { getRouteByPath } from "../routes/RouteMenu";
import { setRouteName } from "../slices/state";
import { useAppDispatch } from "../hooks";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

interface MenuButtonProps {
	title: string;
	icon: React.ComponentType<SVGProps<SVGSVGElement>>;
	route: string;
}
export function MenuButton({ title, icon: Icon, route }: MenuButtonProps) {
	const globalDispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const goto = (path: string) => {
		let pathName = getRouteByPath(path)?.title;
		globalDispatch(setRouteName(pathName));
		navigate(path);
	};

	return (
		<div
			onClick={() => goto(route)}
			className={
				`flex items-center space-x-2 cursor-pointer px-12 py-1 ` +
				(location.pathname === route
					? " bg-[#472183]/25 border-l-4 border-[#472183]"
					: "")
			}
		>
			<div
				className={
					location.pathname === route ? "text-[#472183]" : "text-white"
				}
			>
				<Icon />
			</div>
			<div
				className={
					`text-[20px] ` +
					(location.pathname === route ? "font-medium" : "font-light")
				}
			>
				{title}
			</div>
		</div>
	);
}
