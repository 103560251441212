import React, { useEffect } from "react";
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	tooltip?: string;
	ref?: React.RefObject<HTMLButtonElement>;
}
export default function Button(props: ButtonProps) {
	const { tooltip, className: cn, ...rest } = props;
	const [className, setClassName] = React.useState<string | undefined>(cn);
	// useEffect(() => {
	// 	if (!cn?.includes("group")) setClassName(className + " group relative");
	// }, [cn]);

	const ref = React.useRef<HTMLButtonElement>(props.ref?.current ?? null);

	return (
		<div className="relative flex group justify-center">
			<button className={className} {...rest} ref={ref}>
				{props.children}
			</button>
			{props.tooltip && (
				<span
				className="absolute scale-0 transition-all rounded bg-ungutext mt-10 p-2 text-xs items-center text-white group-hover:scale-100 whitespace-nowrap">
					{props.tooltip}
				</span>
			)}
		</div>
		
	);
}
